// libraries
import React from "react";

// custom components
import consoleLog from "../../../../../functions/consoleLog";
import { trialCount, unitAmountConversions } from "../../functions/general";
import { stripeSubscriptionProcess } from "../../../../../API/productsAPI";
import { planAvailedData } from "../../../../../Configs/planData";

const PriceOptions = (props) => {

    // props
    const {
        product,
        email,
        subscriptionData,
        isActivePricingPlan,
        setIsActivePricingPlan,
        userData,
        subscriptionStatus,
        buttonLabel,
    } = props;

    // subscriptionData extracted values
    const subsProductID = subscriptionData?.status?.item_id;
    const subsPlatform = subscriptionData?.status?.fromPlatform;
    const subsMessage = subscriptionData?.message;
    const subsStripeNew = subscriptionData?.status?.isNewStripe;

    // product extracted data
    const currentProductID = product?.productData?.id;
    const currentProductName = product?.productData?.name;
    const currenyProductDescription = product?.productData?.description;
    const currentProductTrialDays = product?.productData?.metadata?.trial_days;
    const currentProductInterval = product?.productPrice?.recurring?.interval;
    const currentProductUnitAmount = product?.productPrice?.unit_amount;
    const currentProductCredits = product?.productData?.metadata?.credits;

    // userData extracted value variables
    const userDataTrialCount = userData?.UserInfo?.trial_count;
    const userDataTrialOffset = userData?.UserInfo?.trial_offset;

    // react components
    const [isLoading, setIsLoading] = React.useState(false);
    const [actionKind, setActionKind] = React.useState(null);

    // console log activity
    // consoleLog("subscriptionData", subscriptionData);

    // set current action
    React.useEffect(() => {
        if (subsPlatform === "stripe_platform" && (subsMessage === "subscribed" || subsMessage === "trial") && subsStripeNew) {
            setActionKind("update");
        }
    }, []);

    // subscription plan process
    const handleSubscribeToPlan = async (data) => {

        // set is loading and disable true
        setIsLoading(true);
        setIsActivePricingPlan(false);

        // required data
        const requiredData = {
            productPriceID: data?.productPrice?.id,
            productDataID: data?.productData?.id,
            userEmail: email,
            actionKind: actionKind,
        };

        // call "stripeSubscriptionProcess" function to process the subscription 
        const response = await stripeSubscriptionProcess(requiredData);

        // check if session exist from response
        if (response?.data?.message?.url) {
            window.location = response?.data?.message?.url;
        }

        // console log activity
        // consoleLog("response", response?.data);
        // consoleLog("data", data);
    };

    return (
        <>
            <h3 className="mb-4 text-2xl font-semibold">{currentProductName}</h3>

            <div className="font-normal sm:text-lg text-gray-400">

                {currentProductTrialDays && userDataTrialCount < userDataTrialOffset && (
                    <div className="absolute transform -rotate-[35deg] -translate-x-[40px] -translate-y-[60px] text-base bg-red-500 px-3 py-1 w-auto text-center border rounded-md">
                        <span className="font-bold text-white">{trialCount(currentProductTrialDays)}</span>
                    </div>
                )}


                <p>{currenyProductDescription}</p>
            </div>

            <div className="flex justify-center items-baseline my-8">
                <span className="text-3xl font-semibold">$</span>
                <span className="mr-2 text-5xl font-extrabold">{unitAmountConversions(currentProductUnitAmount)}</span>
                <span className="text-gray-400 font-semibold">/{currentProductInterval}</span>
            </div>

            <ul role="list" className="mb-8 space-y-4 text-left">
                <li className="flex items-center space-x-3">
                    <i className="fa-solid fa-check flex-shrink-0 text-green-400" />
                    <span>{(Number(currentProductCredits)).toLocaleString(0, 2)} credits per month</span>
                </li>
                {planAvailedData?.map((item, idx) => {
                    return (
                        <li key={idx} className="flex items-center space-x-3">
                            <i className="fa-solid fa-check flex-shrink-0 text-green-400" />
                            <span className="font-semibold">{item?.data}</span>
                        </li>
                    );
                })}

                {/* <li className="flex items-center space-x-3">
                    <i className="fa-solid fa-check flex-shrink-0 text-green-400" />
                    <span className="font-semibold">Can Purchase Credits</span>
                </li>
                <li className="flex items-center space-x-3">
                    <i className="fa-solid fa-check flex-shrink-0 text-green-400" />
                    <span className="font-semibold">AI Chat</span>
                </li>
                <li className="flex items-center space-x-3">
                    <i className="fa-solid fa-check flex-shrink-0 text-green-400" />
                    <span>Video Generator</span>
                </li>
                <li className="flex items-center space-x-3">
                    <i className="fa-solid fa-check flex-shrink-0 text-green-400" />
                    <span>Video Transcriber</span>
                </li> */}
            </ul>

            {subsProductID === currentProductID && subsMessage !== "trial" && subscriptionStatus !== "canceled" ? (
                <div className="shadow border rounded-lg border-gray-500 font-medium text-lg px-5 py-3 text-center dark:text-white text-gray-700">
                    Current Active Plan
                </div>
            ) : (
                <button
                    onClick={() => handleSubscribeToPlan(product)}
                    disabled={!isActivePricingPlan}
                    className={`${!isActivePricingPlan ? "bg-gray-600 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-700"} focus:ring-4 font-medium rounded-lg text-lg px-5 py-4 text-center text-white focus:ring-blue-900 mt-auto flex items-center justify-center space-x-2`}
                >
                    <i className={`${!isLoading && "invisible"} fa-solid fa-circle-notch fa-spin`} />
                    <span className="flex-1 text-center">{subsProductID === currentProductID && subsMessage === "trial" ? "Subscribe Plan" : actionKind === "update" ? buttonLabel : "Get started"}</span>
                </button>
            )}

        </>
    );
};

export default PriceOptions;