// libraries
import React, { useContext, useEffect, useState } from "react";
import { Avatar, Button, Input, Select, SelectItem, Spinner, Textarea, Tooltip, User } from "@nextui-org/react";

// custom components
import consoleLog from "../../../functions/consoleLog";
import { cachedToolsConfiguration, getCachedToolsConfiguration } from "../../../functions/general";
import { searchGuildMember, sendDMToAll } from "../../../API/server";
import AdminContext from "../../../components/Admin/AdminContext";
import { Link } from "react-router-dom";
import { ToastEffect } from "../../../functions/ToastEffect";

const SendDMs = () => {

    // context method
    const {

        // others
        userData,

        // admin discord 
        botDMStatus,

    } = useContext(AdminContext);

    // variables
    const maxRows = 25;
    const minRows = 15;

    // variables
    const adminEmail = userData?.UserInfo?.email;

    // customized react useState
    const [message, setMessage] = useState(
        getCachedToolsConfiguration("dm", [])?.config
    );
    const [memberCount, setMemberCount] = useState(
        Number(getCachedToolsConfiguration("dmCount", [])?.config) || 0
    );
    const [discordUserID, setDiscordUserID] = useState(
        getCachedToolsConfiguration("dmDicordID", [])?.config || ""
    );
    const [selectedOption, setSelectedOption] = useState(
        getCachedToolsConfiguration("dmSelectedOption", [])?.config || "all"
    );

    // react useState
    const [rows, setRows] = useState(maxRows);
    const [isSubmittingDMs, setIsSubmittingDMs] = useState(false);
    const [searchedData, setSearchedData] = useState([]);

    // console log activity
    // consoleLog("discordUserID", discordUserID);
    // consoleLog("memberCount", memberCount);
    // consoleLog("dmDicordID", getCachedToolsConfiguration("dmDicordID", []));

    const animals = [
        { key: "all", label: "All" },
        { key: "one", label: "One" },
        { key: "limit", label: "Limit" },
    ];

    // listened to "botDMStatus"
    useEffect(() => {

        if (botDMStatus?.status === "done") {
            // disable the loading
            setIsSubmittingDMs(false);
        }

    }, [botDMStatus]);

    // handle selected option
    const handleSelectedOption = (e) => {

        // extracted value
        const value = e.target.value;

        // conditional setting
        if (value) {
            setSelectedOption(value); // set the selected value
        } else {
            setSelectedOption("all"); // set the default value
        }

        // cached config to localstorage
        cachedToolsConfiguration("dmSelectedOption", value);
    };

    // handle on change
    const handleChange = (event) => {

        // extracted value
        const { value } = event.target;

        // set value
        setMessage(value);

        // cached config to localstorage
        cachedToolsConfiguration("dm", value);

        const lineBreaks = (value.match(/\n/g) || []).length + maxRows;
        setRows(Math.min(lineBreaks, maxRows));
    };

    // handle changes for memberCounts
    const handleMemberCounts = (e) => {

        // extracted value
        const countValue = e.target.value;

        // set value
        setMemberCount(Number(countValue));

        // cached config to localstorage
        cachedToolsConfiguration("dmCount", countValue);
    };

    // handle user dicord id
    const handleUserDiscordID = (e) => {

        // extracted value
        const valueID = e.target.value;

        // set value
        setDiscordUserID(valueID);

        // cached config to localstorage
        cachedToolsConfiguration("dmDicordID", valueID);
    };

    // handle on key down
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // Prevent the default newline behavior
            // call the function
            sendDMToAllFunction();
        }
    };

    // send message sub function
    const sendDMToAllFunction = () => {
        // Check if the message contains non-whitespace characters
        if (message.trim().length > 0) {
            sendDMToAllMembers();
        }
    };

    // handle send direct message to all members
    const sendDMToAllMembers = async () => {

        // check if email exist
        if (!adminEmail) {
            ToastEffect("error", "This is not possible. #adminEmail");
            return;
        }

        // check for member count
        if (selectedOption === "limit" && Number(memberCount) === 0) {
            ToastEffect("error", "Member limit count is required.");
            return;
        }

        // check if discord id exist
        if (selectedOption === "one" && discordUserID === "") {
            ToastEffect("error", "Discord user id is required.");
            return;
        }

        // conditional "sentTo" value
        const sentToValue = selectedOption === "limit" ? Number(memberCount) : selectedOption;

        // required data
        const requiredData = {
            sender: adminEmail,
            message: message,
            sentTo: sentToValue,
            userId: discordUserID,
        };

        // trigger loading mark
        setIsSubmittingDMs(true);

        // send request
        await sendDMToAll(requiredData)
            .then((response) => {
                // console log activity
                consoleLog("sendDMToAll", response?.data);
            });

        // disable loading mark
        setIsSubmittingDMs(false);

        // console log activity
        consoleLog("requiredData", requiredData);
    };

    // handle search guild member
    useEffect(() => {
        const executeFunction = async () => {

            // prepare required data
            const requiredData = {
                searchKey: discordUserID
            };

            // search guild member
            await searchGuildMember(requiredData)
                .then((response) => {

                    // set fetched data
                    setSearchedData(response?.data?.message);

                    // console log activity
                    // consoleLog("searchGuildMember", response?.data);
                });
        };
        executeFunction();
    }, [discordUserID]);

    // handle selected member
    const handleSelectedMember = (data) => {

        // set selected member
        setDiscordUserID(data?.userId);

        // cached config to localstorage
        cachedToolsConfiguration("dmDicordID", data?.userId);

        // console log activity
        consoleLog("data", data);
    };

    return (
        <div className="antialiased">
            <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
                <h2 className="text-xl my-5 mx-4 font-semibold text-gray-900 dark:text-white sm:text-2xl">Send Broadcast DM</h2>

                {/* <div className="mt-6 sm:mt-8 lg:flex lg:gap-8"> */}
                <div className="lg:flex lg:gap-8">
                    <div className="w-full overflow-hidden lg:max-w-xl xl:max-w-2xl">

                        <div className="relative mx-2 w-auto">
                            <Textarea
                                disabled={isSubmittingDMs ? true : false}
                                size="lg"
                                radius="sm"
                                label="Direct Message"
                                variant="bordered"
                                placeholder="Enter your message"
                                disableAnimation
                                disableAutosize
                                classNames={{
                                    base: "h-full",
                                    // input: "resize-y min-h-[40px]",
                                }}
                                value={message}
                                onValueChange={setMessage}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                rows={rows}
                                style={{ minHeight: '40px' }}
                            />
                            <div className="absolute bottom-2 right-1">
                                <Tooltip showArrow={true} color="primary" content="Send DM">
                                    {/* <span onClick={sendDMToAllFunction} className="cursor-pointer text-2xl mr-2 text-gray-500 dark:text-gray-500 hover:text-gray-400">
                                        <i className="fa-solid fa-paper-plane" />
                                    </span> */}

                                    <Button
                                        isIconOnly
                                        size="sm"
                                        variant="light"
                                        startContent={
                                            !isSubmittingDMs && (
                                                <span className="text-2xl text-gray-500 dark:text-gray-500 hover:text-gray-400">
                                                    <i className="fa-solid fa-paper-plane" />
                                                </span>
                                            )
                                        }
                                        onClick={sendDMToAllFunction}
                                        isLoading={isSubmittingDMs ? true : false}
                                    />
                                </Tooltip>
                            </div>
                        </div>

                    </div>

                    <div className="mt-6 grow sm:mt-8 lg:mt-0">
                        <div className="space-y-6 rounded-lg border border-gray-200 bg-white p-6 shadow-sm dark:border-gray-700 dark:bg-gray-800">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Process Status</h3>

                            <ol className="relative ms-3 border-s border-gray-200 dark:border-gray-700">
                                <li className="mb-10 ms-6">
                                    <div className="absolute -start-3 flex h-6 w-6 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white dark:bg-gray-700 dark:ring-gray-800">

                                        {isSubmittingDMs && botDMStatus?.status ? (
                                            <Spinner size="sm" color="warning" />
                                        ) : (
                                            <span className="text-gray-500 dark:text-gray-400">
                                                <i className="fa-solid fa-circle-check" />
                                            </span>
                                        )}
                                    </div>
                                    <h4 className="mb-0.5 text-base font-semibold text-gray-900 dark:text-white">{botDMStatus?.status || "DM Process"}</h4>
                                    <p className="text-sm font-normal text-gray-500 dark:text-gray-400">{botDMStatus?.message || "Displayes the process status"}</p>
                                </li>
                            </ol>

                            <form className="flex flex-col gap-4">

                                <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
                                    <Select
                                        color="primary"
                                        label="Set DM option"
                                        variant="bordered"
                                        // className="max-w-xs"
                                        selectedKeys={[selectedOption]}
                                        onChange={handleSelectedOption}
                                    >
                                        {animals.map((animal) => (
                                            <SelectItem key={animal.key}>
                                                {animal.label}
                                            </SelectItem>
                                        ))}
                                    </Select>

                                    {selectedOption === "all" || selectedOption === "one" ? (
                                        <Tooltip
                                            content={
                                                <div className="px-1 py-2 w-[12rem]">
                                                    <div className="text-small font-bold">Guild Members</div>
                                                    {searchedData?.slice(0, 5)?.map((data, idx) => {
                                                        return (
                                                            <div
                                                                key={idx}
                                                                className="flex justify-start items-center text-tiny mt-2 p-2 hover:bg-gray-700 rounded-lg cursor-pointer"
                                                                onClick={() => handleSelectedMember(data)}
                                                            >
                                                                <Avatar
                                                                    src={data?.displayAvatarURL}
                                                                    className="w-6 h-6 text-tiny"
                                                                />
                                                                <span className="ml-1">{data?.displayName}</span>
                                                            </div>
                                                        );
                                                    })}

                                                </div>
                                            }
                                            placement="bottom"
                                            isOpen={searchedData?.length && discordUserID !== "" ? true : false}
                                            color="default"
                                        >
                                            <Input
                                                variant="bordered"
                                                isRequired
                                                color="secondary"
                                                label="Set discord user ID"
                                                placeholder="Enter discord user ID"
                                                type="text"
                                                isDisabled={selectedOption === "all" ? true : false}
                                                onChange={handleUserDiscordID}
                                                value={discordUserID}
                                            />
                                        </Tooltip>
                                    ) : selectedOption === "limit" && (
                                        <Input
                                            variant="bordered"
                                            isRequired
                                            color="warning"
                                            label="Limit value"
                                            placeholder="Enter limit value"
                                            type="number"
                                            isDisabled={selectedOption === "all" ? true : false}
                                            onChange={handleMemberCounts}
                                            value={memberCount}
                                        />
                                    )}
                                </div>

                                {/* <Input isRequired label="Email" placeholder="Enter your email" type="email" />
                                <Input
                                    isRequired
                                    label="Password"
                                    placeholder="Enter your password"
                                    type="password"
                                /> */}
                                {/* <div className="flex gap-2 justify-end">
                                    <Button fullWidth color="primary">
                                        Login
                                    </Button>
                                </div> */}
                            </form>

                            {/* <div className="gap-4 sm:flex sm:items-center">
                                <button type="button" className="w-full rounded-lg  border border-gray-200 bg-white px-5  py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700">Cancel the order</button>

                                <a href="#" className="mt-4 flex w-full items-center justify-center rounded-lg bg-primary-700  px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300  dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 sm:mt-0">Order details</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SendDMs;;;