// libraries
import React, { useState } from "react";
import { Input, Tooltip } from "@nextui-org/react";

// custom components
import consoleLog from "../../../../../../functions/consoleLog";
import { updateArtisanCaptionData } from "../../../../../../API/server";
import { updateVideoCaption } from "../../functions/artisan";

const Caption = (props) => {

    // props
    const {
        selectedCaptionData,
        artisanSession,
        setArtisanSession,
    } = props;

    // extracted data from 
    const sessionID = artisanSession?._id;

    // react useState
    const [captionText, setCaptionText] = useState(selectedCaptionData?.selectedData?.word || selectedCaptionData?.selectedData?.text);
    const [captionStartTime, setCaptionStartTime] = useState(selectedCaptionData?.selectedData?.start);
    const [captionEndTime, setCaptionEndTime] = useState(selectedCaptionData?.selectedData?.end);

    // console log activity
    // consoleLog("artisanSession", artisanSession);

    // handle on change caption data
    const handleOnChangeCaptionText = async (e) => {

        // extracted caption text value
        const captionText = e.target.value;

        // set the new caption text
        setCaptionText(captionText);

        // required data
        const requiredData = {
            sessionID: sessionID,
            newValue: captionText,
            referenceID: selectedCaptionData?.referenceID,
            kind: selectedCaptionData?.selectedData?.text ? "caption_text_update" : "caption_word_update",
        };

        // handle update caption text
        await updateArtisanCaptionData(requiredData)
            .then((response) => {

                // console log activity
                consoleLog("updateCaptionText", response?.data);

                // update local data when success
                const updatedData = updateVideoCaption(
                    artisanSession, // array data
                    selectedCaptionData?.referenceID, // selected idx
                    captionText, // new data
                    null, // action kind (null for caption text or word)
                );

                // set the new updated data for local data only
                setArtisanSession(updatedData);

                // console log activity
                // consoleLog("updatedData", updatedData);
            });

        // console log activity
        // consoleLog("captionText", captionText);

    };

    // handle on change caption start time
    const handleOnChangeCaptionStart = async (e) => {

        // extracted caption start time
        const newCaptionStart = parseFloat(e.target.value);

        // set the new caption start time
        setCaptionStartTime(newCaptionStart);

        // required data
        const requiredData = {
            sessionID: sessionID,
            newValue: newCaptionStart,
            referenceID: selectedCaptionData?.referenceID,
            kind: "caption_start_update",
        };

        // handle update caption text
        await updateArtisanCaptionData(requiredData)
            .then((response) => {

                // console log activity
                consoleLog("updateCaptionStart", response?.data);

                // update local data when success
                const updatedData = updateVideoCaption(
                    artisanSession, // array data
                    selectedCaptionData?.referenceID, // selected idx
                    newCaptionStart, // new data
                    "start", // action kind (null for caption text or word)
                );

                // set the new updated data for local data only
                setArtisanSession(updatedData);

                // console log activity
                consoleLog("updatedData", updatedData);
            });

        // console log activity
        // consoleLog("captionStart", newCaptionStart);
    };

    // handle on change caption end time
    const handleOnChangeCaptionEnd = async (e) => {

        // extracted caption end time
        const newCaptionEnd = parseFloat(e.target.value);

        // set the new caption end time
        setCaptionEndTime(newCaptionEnd);

        // required data
        const requiredData = {
            sessionID: sessionID,
            newValue: newCaptionEnd,
            referenceID: selectedCaptionData?.referenceID,
            kind: "caption_end_update",
        };

        // handle update caption text
        await updateArtisanCaptionData(requiredData)
            .then((response) => {

                // console log activity
                consoleLog("updateCaptionEnd", response?.data);

                // update local data when success
                const updatedData = updateVideoCaption(
                    artisanSession, // array data
                    selectedCaptionData?.referenceID, // selected idx
                    newCaptionEnd, // new data
                    "end", // action kind (null for caption text or word)
                );

                // set the new updated data for local data only
                setArtisanSession(updatedData);
            });

        // console log activity
        // consoleLog("newCaptionEnd", newCaptionEnd);
    };

    return (
        <div className="w-[80%] lg:w-[30%]">

            <Tooltip showArrow={true} color="primary" content="Caption Text">
                <Input
                    type="text"
                    color="primary"
                    variant="faded"
                    // label="Email"
                    // labelPlacement="outside"
                    placeholder="Caption text"
                    startContent={
                        <span className="text-2xl text-default-400 pointer-events-none flex-shrink-0">
                            <i className="fa-solid fa-closed-captioning" />
                        </span>
                    }
                    className="mb-2"
                    value={captionText}
                    onChange={handleOnChangeCaptionText}
                />
            </Tooltip>

            <div className="flex justify-center items-center space-x-1">

                {/* <Tooltip showArrow={true} color="primary" content="Video Timing">
                    <Input
                        // label="Price"
                        // labelPlacement="outside"
                        isReadOnly
                        placeholder="0.00"
                        startContent={
                            <div className="pointer-events-none flex items-center">
                                <span className="text-default-400 text-md">
                                    <i className="fa-solid fa-clock" />
                                </span>
                            </div>
                        }
                        type="text"
                        className="w-[70%]"
                        value={(item?.total_seconds)?.toFixed(2)}
                    />
                </Tooltip> */}

                <Tooltip showArrow={true} color="primary" content="Caption Start">
                    <Input
                        // label="Price"
                        // labelPlacement="outside"
                        variant="faded"
                        color="primary"
                        placeholder={0}
                        startContent={
                            <div className="pointer-events-none flex items-center">
                                <span className="text-default-400 text-md font-semibold">
                                    Start
                                </span>
                            </div>
                        }
                        type="number"
                        className="w-full"
                        value={(captionStartTime || 0)}
                        onChange={handleOnChangeCaptionStart}
                    />
                </Tooltip>

                <Tooltip showArrow={true} color="primary" content="Caption End">
                    <Input
                        // label="Price"
                        // labelPlacement="outside"
                        variant="faded"
                        color="primary"
                        placeholder={0}
                        startContent={
                            <div className="pointer-events-none flex items-center">
                                <span className="text-default-400 text-md font-semibold">
                                    End
                                </span>
                            </div>
                        }
                        type="number"
                        className="w-full"
                        value={(captionEndTime || 0)}
                        onChange={handleOnChangeCaptionEnd}
                    />
                </Tooltip>

            </div>
        </div>
    );
};

export default Caption;