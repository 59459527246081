// update video sequence video source link
export const updateVideoSource = (artisanSession, newLink, currentNumber, action) => {
    const updatedSession = {
        ...artisanSession,
        video: {
            ...artisanSession.video,
            videoSequence: artisanSession.video.videoSequence.map((video) => {
                if (video.number === (currentNumber || 1)) {
                    if (action === "update") {
                        return {
                            ...video,
                            video_source_prev: video.video_source,
                            video_source: newLink,
                        };
                    } else if (action === "reverse" && video.video_source_prev) {
                        return {
                            ...video,
                            video_source: video.video_source_prev,
                            video_source_prev: null, // Or you can keep the previous source if you need to reverse again later
                        };
                    }
                }
                return video;
            }),
        },
    };
    return updatedSession;
};

// Update caption data
export const updateVideoCaption = (artisanSession, selectedIdx, newData, actionKind) => {
    const updatedSession = {
        ...artisanSession,
        video: {
            ...artisanSession.video,
            videoCaption: artisanSession.video.videoCaption.map((caption, index) => {
                if (index === selectedIdx) {
                    if (actionKind) {
                        return { ...caption, [actionKind]: newData };
                    } else {
                        if ('word' in caption) {
                            return { ...caption, word: newData };
                        } else if ('text' in caption) {
                            return { ...caption, text: newData };
                        }
                    }
                }
                return caption;
            }),
        },
    };
    return updatedSession;
};

// filter the video source data and only gte the HD first link (PEXEL)
export const getVideoSource = (videoData) => {

    // filter data
    const videoSource = videoData?.video_files?.filter((video) => video?.quality === "hd");

    // return the filtered video
    return videoSource[0]?.link;
};

// filter selected sequence and highlight the caption
export const handleHighlightCaption = (stringWords, arrayCaption) => {

    // split words via space
    const wordsInSentence = stringWords.split(' ');

    // Convert words in the sentence to lowercase for case-insensitive comparison
    const wordsInSentenceLowerCase = wordsInSentence.map(word => word.toLowerCase());

    // Update the videoCaption array to include the selected property
    const updatedVideoCaption = arrayCaption.map(item => {
        return {
            ...item,
            selected: wordsInSentenceLowerCase.includes(item.word.toLowerCase())
        };
    });

    return updatedVideoCaption;
};