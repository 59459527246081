// libraries
import React from "react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Input, Button, DropdownTrigger, Dropdown, DropdownMenu, DropdownItem, Pagination, useDisclosure, Spinner, } from "@nextui-org/react";

// custom components
import { INITIAL_VISIBLE_COLUMNS, columns, progressstyle, statusOptions } from "../Helpers/data";
import MediaView from "../../../../../components/Modals/MediaView";
import handleDownload from "../../functions/download";
import { addMarkerToData, removeMarkedData } from "../../../../../functions/general";
import { ToastEffect } from "../../../../../functions/ToastEffect";
import consoleLog from "../../../../../functions/consoleLog";
import { alertBeforeAction } from "../../Components/Transcriber/AlertBeforeAction";
import UserContext from "../../../../../components/UserContext";
import VG_TableCell1 from "./VG_TableCell1";
import VG_TableCell2 from "./VG_TableCell2";
import VG_TableCell3 from "./VG_TableCell3";
import VG_TableCell4 from "./VG_TableCell4";
import VG_TableCell5 from "./VG_TableCell5";
import ReportVideoEditor from "../../Components/Modals/ReportVideoEditor";
import VG_TableTopContent from "./VG_TableTopContent";
import VG_TableBottomContent from "./VG_TableBottomContent";
import ModalAlert from "../../../../../components/Modals/ModalAlert";

const VG_Table = (props) => {

    // props
    const {
        setMediaSoureceData,
        setIsCustomMedia,
        setVideos,
        handleDeleteVideo,
        isDeletingData,
        videos,
        handleRetryProcess,
        createTheVideo,
    } = props;

    // context method
    const {

        // Others
        toolsDataStatus,
        isLoading,

    } = React.useContext(UserContext);

    // nextUI components
    const { isOpen: isOpenMV, onOpen: onOpenMV, onClose: onCloseMV } = useDisclosure(); // MV - (Media View)
    const { isOpen: isOpenRV, onOpen: onOpenRV, onClose: onCloseRV } = useDisclosure(); // RV - (Report Video)
    const { isOpen: isOpenMA, onOpen: onOpenMA, onClose: onCloseMA } = useDisclosure(); // MA - (Modal Alert)

    // react components
    const [filterValue, setFilterValue] = React.useState("");
    const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
    const [visibleColumns, setVisibleColumns] = React.useState(new Set(INITIAL_VISIBLE_COLUMNS));
    const [statusFilter, setStatusFilter] = React.useState("all");
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [sortDescriptor, setSortDescriptor] = React.useState({}); // { column: "title", direction: "ascending", }
    const [page, setPage] = React.useState(1);
    const [isDownloading, setIsDownloading] = React.useState({});
    const [modalData, setModalData] = React.useState([]);
    const [modalIdx, setModalIdx] = React.useState(null);

    const [processAccuracyVideo, setProcessAccuracyVideo] = React.useState([]);
    const [reportedVideoData, setReportedVideoData] = React.useState([]);
    const [toBeDeletedData, setToBeDeletedData] = React.useState([]);

    const hasSearchFilter = Boolean(filterValue);

    // console log activity
    // consoleLog("videos", videos);

    // columns
    const headerColumns = React.useMemo(() => {
        if (visibleColumns === "all") return columns;

        return columns.filter((column) => Array.from(visibleColumns).includes(column.uid));
    }, [visibleColumns]);

    // capitalize function
    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    // filtering function
    const filteredItems = React.useMemo(() => {
        let filteredData = [...videos];

        if (hasSearchFilter) {
            filteredData = filteredData?.filter((data) =>
                data.title?.toLowerCase()?.includes(filterValue?.toLowerCase()) || // filter by title
                data.task_id?.toLowerCase()?.includes(filterValue?.toLowerCase()), // filter by task_id
            );
        }
        if (statusFilter !== "all" && Array.from(statusFilter).length !== statusOptions.length) {
            filteredData = filteredData.filter((data) =>
                Array.from(statusFilter).includes(data.status),
            );
        }

        return filteredData;
    }, [videos, filterValue, statusFilter]);

    // sorting function
    const items = React.useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);

    // sorting function
    const sortedItems = React.useMemo(() => {
        return [...items].sort((a, b) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, items]);

    // console log activity
    // consoleLog("filteredItems", filteredItems);
    // consoleLog("sortedItems", sortedItems);
    // consoleLog("items", items);
    // consoleLog("sortDescriptor", sortDescriptor);

    // handle play video
    const handlePlayVideo = (data, idx) => {
        setModalData(data);
        setModalIdx(idx);
        onOpenMV(); // open modal
    };

    // handle edit video
    const handleCustomMedia = (data) => {

        setIsCustomMedia(true);
        setMediaSoureceData(data);

        // push the page to the URL
        window.history.pushState("Customize", "Media", `?p=custom-media&id=${data?.task_id}`);

        // console log activity
        // consoleLog("Edit", "video");
    };

    // handle download media file
    const handleDownloadMediaFile = async (mediaURL, mediaName, kind, dataID) => {

        // call marker function and insert data
        await addMarkerToData(setIsDownloading, dataID);

        // execute download process
        await handleDownload(mediaURL, mediaName, kind)
            .then(async () => {
                // call marker to remove marked data
                await removeMarkedData(setIsDownloading, dataID);
            }).catch(async (error) => {
                // call marker to remove marked data
                await removeMarkedData(setIsDownloading, dataID);
                ToastEffect("error", "Error downloading media #handleDownloadMediaFile");
                consoleLog("Error downloading media", error);
            });
    };

    // handle proceed creating video with accuracy
    const handleGenerateAccurateVideo = async (data) => {

        // variables
        const accuracyData = data?.accuracy_data;
        const dataID = data?._id;
        const amountStatus = data?.amount_status;

        // execute after the replacement.
        await alertBeforeAction(
            createTheVideo, // function 1
            setProcessAccuracyVideo, // function 2
            "accuracy-generation", // data kind
            { videoData: accuracyData, id: dataID, amount_status: amountStatus, processAccuracyVideo } // other data in object format
        );

        // console log activity
        // consoleLog("data", data);
    };

    // handle report video function
    const handleReportVideo = async (data) => {
        setReportedVideoData(data);
        onOpenRV(); // open modal
    };

    // handle delete function
    const handleDeleteFunction = (data) => {
        setToBeDeletedData(data);
        onOpenMA(); // open modal
    };

    // rendering cell function
    const renderCell = React.useCallback((
        data,
        columnKey,
        idx,
        toolsDataStatus
    ) => {

        // filter data by task_id
        const filtered_generateVideoStatus = toolsDataStatus.find((status) => status?.tools_status?.task_id === data?.task_id);

        const cellValue = data[columnKey]; // to be removed

        switch (columnKey) {
            case "title":
                return (
                    <VG_TableCell1
                        idx={idx}
                        data={data}
                        handlePlayVideo={handlePlayVideo}
                        handleCustomMedia={handleCustomMedia}
                        filtered_generateVideoStatus={filtered_generateVideoStatus}
                    />
                );
            case "image_count":
                return (
                    <VG_TableCell2
                        data={data}
                    />
                );
            case "amount":
                return (
                    <VG_TableCell3
                        data={data}
                    />
                );
            case "status":
                return (
                    <VG_TableCell4
                        data={data}
                        progressstyle={progressstyle}
                        filtered_generateVideoStatus={filtered_generateVideoStatus}
                        processAccuracyVideo={processAccuracyVideo}
                        handleGenerateAccurateVideo={handleGenerateAccurateVideo}
                    />
                );
            case "actions":
                return (
                    <VG_TableCell5 // where the tablemenu is located
                        idx={idx}
                        data={data}
                        modalData={modalData}
                        handlePlayVideo={handlePlayVideo}
                        handleDownloadMediaFile={handleDownloadMediaFile}
                        handleRetryProcess={handleRetryProcess}
                        handleReportVideo={handleReportVideo}
                        handleCustomMedia={handleCustomMedia}
                        handleDeleteFunction={handleDeleteFunction}
                    />
                );
            default:
                return cellValue;
        }
    }, []);

    // pagination function
    const pages = Math.ceil(filteredItems.length / rowsPerPage);

    const onNextPage = React.useCallback(() => {
        if (page < pages) {
            setPage(page + 1);
        }
    }, [page, pages]);

    const onPreviousPage = React.useCallback(() => {
        if (page > 1) {
            setPage(page - 1);
        }
    }, [page]);

    const onRowsPerPageChange = React.useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);

    const onSearchChange = React.useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = React.useCallback(() => {
        setFilterValue("");
        setPage(1);
    }, []);

    // top content function
    const topContent = React.useMemo(() => {
        return (
            <VG_TableTopContent
                Input={Input}
                filterValue={filterValue}
                onClear={onClear}
                onSearchChange={onSearchChange}
                Dropdown={Dropdown}
                DropdownTrigger={DropdownTrigger}
                Button={Button}
                DropdownMenu={DropdownMenu}
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                statusOptions={statusOptions}
                DropdownItem={DropdownItem}
                capitalize={capitalize}
                visibleColumns={visibleColumns}
                setVisibleColumns={setVisibleColumns}
                columns={columns}
                videos={videos}
                onRowsPerPageChange={onRowsPerPageChange}
                rowsPerPage={rowsPerPage}
            />
        );
    }, [
        filterValue,
        statusFilter,
        visibleColumns,
        onRowsPerPageChange,
        videos.length,
        onSearchChange,
        hasSearchFilter,
    ]);

    // bottom content function
    const bottomContent = React.useMemo(() => {
        return (
            <VG_TableBottomContent
                selectedKeys={selectedKeys}
                filteredItems={filteredItems}
                Pagination={Pagination}
                page={page}
                pages={pages}
                setPage={setPage}
                Button={Button}
                onPreviousPage={onPreviousPage}
                onNextPage={onNextPage}
            />
        );
    }, [
        selectedKeys,
        items.length,
        page,
        pages,
        hasSearchFilter
    ]);

    // main content with table
    return (
        <div className="flex w-full flex-col h-screen overflow-auto">
            <Table
                aria-label="Generated videos"
                isHeaderSticky
                bottomContent={bottomContent}
                bottomContentPlacement="outside"
                selectedKeys={selectedKeys}
                selectionMode="single" // multiple
                sortDescriptor={sortDescriptor}
                topContent={topContent}
                topContentPlacement="outside"
                onSelectionChange={setSelectedKeys}
                onSortChange={setSortDescriptor}
                color="secondary"
                className="p-5 h-screen"
            >

                <TableHeader>
                    {headerColumns?.map((column) => {
                        return (
                            <TableColumn
                                key={column.uid}
                                align={column.uid === "actions" ? "center" : "start"}
                                allowsSorting={column.sortable}
                            >
                                {column.name}
                            </TableColumn>
                        );
                    })}
                </TableHeader>

                <TableBody
                    isLoading={isLoading ? true : false}
                    loadingContent={<Spinner color="secondary" size="lg" label="Loading..." />}
                    emptyContent={"No data found"}
                >
                    {sortedItems?.map((item, idx) => {
                        return (
                            <TableRow key={item?._id}>
                                {(columnKey) =>
                                    <TableCell>
                                        {renderCell(
                                            item,
                                            columnKey,
                                            idx,
                                            toolsDataStatus,
                                        )}
                                    </TableCell>}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>

            {/* media view modal components*/}
            <MediaView
                handleDownloadMediaFile={handleDownloadMediaFile}
                isOpen={isOpenMV}
                onClose={onCloseMV}
                isDownloading={isDownloading}
                modalData={modalData}
            />

            {/* report modal components */}
            <ReportVideoEditor
                isOpen={isOpenRV}
                onClose={onCloseRV}
                reportedVideoData={reportedVideoData}
                setVideos={setVideos}
                videos={videos}
            />

            {/* modal popup alert */}
            <ModalAlert
                title="Are you sure you want to delete this data?"
                content="By clicking Remove, this will permanently delete this data. This action cannot be undone."
                isOpen={isOpenMA}
                onClose={onCloseMA}
                handleDeleteVideo={handleDeleteVideo}
                isDeletingData={isDeletingData}
                toBeDeletedData={toBeDeletedData}
            />
        </div>
    );
};

export default VG_Table;
