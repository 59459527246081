import React, { useEffect, useRef, useState } from "react";
import { sendAudiotoAI, sendImagetoAI, sendMessageToAI } from "../../API/chatAPI";
import consoleLog from "../../functions/consoleLog";
import { ToastEffect } from "../../functions/ToastEffect";
import { useNavigate } from "react-router-dom";

const ChatInput = (props) => {

    const {
        setChatPage,
        setIsAllFetched,
        isVisible,
        setCurrentSessionID,
        setAIChat,
        refetchAIChats,
        refetchAIChatSession,
        userData,
        sessionID,
        setAutoScroll,
        sending,
        setSending
    } = props;

    // console.log("userData", userData);

    const navigate = useNavigate();

    // use ref
    const textAreaRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const fileInputRef = useRef(null);

    // useState
    const [value, setValue] = useState("");
    const [recording, setRecording] = useState(false);
    const [imageSrc, setImageSrc] = useState("");

    const clearImage = () => {
        setImageSrc("");
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Reset the file input
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const blob = URL.createObjectURL(file);
            setImageSrc(blob);
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    // temporary session ID
    const tempSession = "temp_101919";

    const resizeTextArea = () => {
        textAreaRef.current.style.height = "auto";
        if (textAreaRef.current.scrollHeight >= 300) {
            textAreaRef.current.style.height = 300 + "px";
        } else {
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
        }
    };

    useEffect(resizeTextArea, [value]);

    const handleKeypress = e => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            if (value.trim().length === 0) {
                setValue("");
                if (imageSrc) {
                    sendImage(e);
                }
            } else {
                // handleSend(e);
                imageSrc ? sendImage(e) : handleSend(e);
            }
        }
    };

    const handleSend = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        // console.log("Send Message");

        if (!value) {
            return;
        }

        const newUser = {
            _id: "", // Make sure this is a unique ID
            email: userData?.userData?.UserInfo?.email,
            session: sessionID,
            sender: "user",
            type: "chat",
            message: value,
            audio: "",
            status: 0,
            created: new Date().toISOString(),
            __v: 0
        };

        if (!userData?.userData?.UserInfo?.email) {
            return;
        }

        setSending(true);
        setValue("");

        // if (userData?.subscriptionData?.message === "noSubscription" || !userData?.subscriptionData?.status) {
        //   // ToastEffect("error", "For subscribers only.");
        //   navigate("/my_plan");
        //   setSending(false);
        //   return;
        // }

        const { message, status } = userData?.subscriptionData || {};
        const { message: userMessage, status: userStatus } = userData?.subscriptionData || {};

        if (message !== "freeAccess" && (userMessage === "noSubscription" || !userStatus)) {
            // ToastEffect("error", "For subscribers only.");
            navigate("/my_plan");
            setSending(false);
            return;
        }

        setAIChat(prevChat => ([...prevChat, newUser]));

        await sendMessageToAI(
            userData?.userData?.UserInfo?.email,
            sessionID,
            "chat",
            value,
            ""
        ).then(async (response) => {
            setCurrentSessionID(response?.data?.session);
            await refetchAIChatSession();
            await refetchAIChats();
            setSending(false);
            setIsAllFetched(false);
            setChatPage(2);
            consoleLog("components > ChatAI > ChatInput.js > handleSend > response", response);
        }).catch((error) => {
            consoleLog("components > ChatAI > ChatInput.js > handleSend > error", error);
        });
    };

    // stop recording function 
    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        setRecording(false);
    };

    // start recording function
    const startRecording = async () => {
        setRecording(true);

        await navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorderRef.current = mediaRecorder;
                const chunks = [];

                mediaRecorder.addEventListener("dataavailable", (event) => {
                    chunks.push(event.data);
                });

                mediaRecorder.addEventListener("stop", async () => {
                    const blob = new Blob(chunks, { type: "audio/mp3" });
                    sendAudio(blob);
                });

                mediaRecorder.start();
            }).catch((error) => {
                setRecording(false);
                consoleLog("components > ChatAI > ChatInput.js > handleSend > error", error.message);
            });
    };

    const handleScrollDown = () => {
        setAutoScroll(old => old + 1);
    };

    const handlePaste = (e) => {
        // Access the clipboard data
        const items = e.clipboardData.items;
        for (const item of items) {
            // Check if the clipboard item is an image
            if (item.type.indexOf('image') === 0) {
                const blob = item.getAsFile();
                const reader = new FileReader();
                reader.onload = (event) => {
                    // Set the image source to the data URL of the image
                    setImageSrc(event.target.result);
                };
                reader.readAsDataURL(blob);
            }
        }
    };

    // handle audio message
    const handleAudio = () => {
        // if (recording) {
        //   setRecording(false);
        //   stopRecording();
        // } else {
        //   setRecording(true);
        //   startRecording();
        // }
    };

    // send image and prompt
    const sendImage = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        // console.log("Send Images");

        if (!userData?.userData?.UserInfo?.email) {
            return;
        }

        // Convert the data URL to a blob
        const res = await fetch(imageSrc);
        const blob = await res.blob();

        const { message, status } = userData?.subscriptionData || {};
        const { message: userMessage, status: userStatus } = userData?.subscriptionData || {};

        if (message !== "freeAccess" && (userMessage === "noSubscription" || !userStatus)) {
            // ToastEffect("error", "For subscribers only.");
            navigate("/my_plan");
            setSending(false);
            return;
        }

        setSending(true);

        const timestamp = new Date().getTime();
        const filename = `image_${timestamp}.png`;

        const formData = new FormData();
        formData.append('email', userData?.userData?.UserInfo?.email);
        formData.append('session', sessionID);
        formData.append('type', "image");
        formData.append('message', value);
        formData.append("image", blob, filename);

        setAIChat((prev) => [...prev, {
            _id: "", // Make sure this is a unique ID
            email: userData?.userData?.UserInfo?.email,
            session: sessionID,
            sender: "user",
            type: "image",
            message: value,
            image: URL.createObjectURL(blob),
            status: 0,
            created: new Date().toISOString(),
            __v: 0
        }]);

        // clear pasted image
        setImageSrc("");
        setValue("");

        await sendImagetoAI(formData)
            .then(async (response) => {
                setCurrentSessionID(response?.data?.session);
                await refetchAIChatSession();
                await refetchAIChats();
                setSending(false);
                setIsAllFetched(false);
                setChatPage(2);
                consoleLog("components > ChatAI > ChatInput.js > sendImage > response", response);
            }).catch((error) => {
                consoleLog("components > ChatAI > ChatInput.js > sendImage > error", error);
            });
    };

    // send audio
    const sendAudio = async (audioBlob) => {

        if (!userData?.userData?.UserInfo?.email) {
            return;
        }

        // if (userData?.subscriptionData?.message !== "freeAccess") {
        //   if (userData?.subscriptionData?.message === "noSubscription" || !userData?.subscriptionData?.status) {
        //     // ToastEffect("error", "For subscribers only.");
        //     navigate("/my_plan");
        //     setSending(false);
        //     return;
        //   }
        // }

        const { message, status } = userData?.subscriptionData || {};
        const { message: userMessage, status: userStatus } = userData?.subscriptionData || {};

        if (message !== "freeAccess" && (userMessage === "noSubscription" || !userStatus)) {
            // ToastEffect("error", "For subscribers only.");
            navigate("/my_plan");
            setSending(false);
            return;
        }


        setSending(true);

        const timestamp = new Date().getTime();
        const filename = `recording_${timestamp}.mp3`;

        const formData = new FormData();
        formData.append('email', userData?.userData?.UserInfo?.email);
        formData.append('session', sessionID);
        formData.append('type', "audio");
        formData.append('message', "");
        formData.append("audio", audioBlob, filename);

        setAIChat((prev) => [...prev, {
            _id: "", // Make sure this is a unique ID
            email: userData?.userData?.UserInfo?.email,
            session: sessionID,
            sender: "user",
            type: "audio",
            message: "Translating Audio...",
            audio: URL.createObjectURL(audioBlob),
            status: 0,
            created: new Date().toISOString(),
            __v: 0
        }]);

        await sendAudiotoAI(formData)
            .then(async (response) => {
                setCurrentSessionID(response?.data?.session);
                await refetchAIChatSession();
                await refetchAIChats();
                setSending(false);
                setIsAllFetched(false);
                setChatPage(2);
                consoleLog("components > ChatAI > ChatInput.js > sendAudio > response", response);
            }).catch((error) => {
                consoleLog("components > ChatAI > ChatInput.js > sendAudio > error", error);
            });
    };

    return (
        <div className="w-full pt-2 md:pt-0 dark:border-white/20 md:border-transparent md:dark:border-transparent md:w-[calc(100%-.5rem)]">

            <form
                onSubmit={imageSrc ? sendImage : handleSend}
                className="stretch mx-2 flex flex-row gap-3 last:mb-2 md:mx-4 md:last:mb-6 lg:mx-auto lg:max-w-2xl xl:max-w-3xl"
            >

                {isVisible && (
                    <div title="Scroll to end" className="absolute bottom-24 right-9 z-50">
                        <button
                            onClick={handleScrollDown}
                            type="button"
                            className="text-lg w-10 h-10 text-blue-500 bg-gray-200 rounded-full"
                        >
                            <i className="fa-solid fa-arrow-down" />
                        </button>
                    </div>
                )}

                <div className="relative flex h-full flex-1 items-stretch md:flex-col">
                    <div className="flex w-full items-center">

                        <div onPaste={handlePaste} className="overflow-hidden [&amp;:has(textarea:focus)]:border-token-border-xheavy [&amp;:has(textarea:focus)]:shadow-[0_2px_6px_rgba(0,0,0,.05)] flex flex-col w-full dark:border-token-border-medium flex-grow relative border border-token-border-medium text-white rounded-2xl bg-token-main-surface-primary">

                            {imageSrc && <div className="mx-2 mt-2 flex flex-wrap gap-2 px-2.5 md:pl-0 md:pr-4">

                                <div className="group relative inline-block text-sm text-token-text-primary">
                                    <div className="relative overflow-hidden rounded-xl border border-token-border-light">
                                        <div className="h-auto w-14 object-cover">
                                            <img src={imageSrc} alt="Pasted" />
                                        </div>
                                    </div>


                                    <button
                                        onClick={clearImage}
                                        className="absolute right-1 top-1 -translate-y-1/2 translate-x-1/2 bg-token-main-surface-secondary p-0.5 text-token-text-primary transition-colors hover:opacity-100 group-hover:opacity-100 md:opacity-0"
                                    >
                                        <span className="" data-state="closed">
                                            <i className="fa-regular fa-circle-xmark text-xl hover:text-gray-300" />
                                        </span>
                                    </button>

                                </div>

                            </div>}

                            <textarea

                                disabled={sending ? true : false}
                                ref={textAreaRef}
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                onKeyDown={handleKeypress}
                                autoFocus
                                id="chat"
                                rows="1"
                                placeholder="Input Message Here..."
                                className="m-0 w-full resize-none border-0 bg-transparent focus:ring-0 focus-visible:ring-0 dark:bg-transparent max-h-25 py-[10px] pr-10 md:py-3.5 md:pr-12 placeholder-white/50 pl-10 md:pl-[55px]"
                            />
                            <div className="absolute bottom-2 md:bottom-3 left-2 md:left-4">
                                <div className="flex">

                                    <button
                                        disabled={sending ? true : false}
                                        onClick={handleButtonClick}
                                        className="btn relative p-0 text-white"
                                        aria-label="Attach files"
                                    >
                                        <div className="flex w-full gap-2 items-center justify-center">
                                            <i className="fa-solid fa-image text-xl" />
                                        </div>
                                    </button>

                                    <input multiple="" type="file" tabIndex="-1" className="hidden" style={{ display: 'none' }} />
                                </div>
                            </div>
                            <button
                                disabled={sending ? true : false}
                                className="absolute bottom-1.5 right-2 p-0.5 text-white hover:text-gray-300 transition-colors disabled:opacity-10 md:bottom-3 md:right-3"
                                data-testid="send-button"
                            >
                                <span className="" data-state="closed">
                                    <i className="fa-solid fa-paper-plane text-xl" />
                                </span>

                            </button>

                        </div>
                    </div>
                </div>

                <input
                    className="hidden"
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept="image/*"
                />

            </form>

            {/* <div className="relative text-gray-400 px-2 py-2 text-center text-xs text-token-text-secondary md:px-[60px]">
                <span>
                    ShinefyAI can make mistakes. Consider checking important information.
                </span>
            </div> */}

        </div>
    );
};

export default ChatInput;