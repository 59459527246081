// libraries
import React from "react";
import { Button, Card, CardFooter, CardHeader, Image, Tooltip, Link } from "@nextui-org/react";

// custom components
import { getVideoSource } from "../../functions/artisan";
import consoleLog from "../../../../../../functions/consoleLog";

const Player = (props) => {

    // props
    const {
        videoData,
        defaultAvatar,
        setPlayVideo,
    } = props;

    // find video file HD
    const videoSource = getVideoSource(videoData);

    // console log activity
    // consoleLog("videoSource", videoSource);

    return (
        <div className="absolute inset-0 flex items-center justify-center z-50 backdrop-blur-sm lg:px-[15rem]">

            <Button
                isIconOnly
                variant="light"
                radius="full"
                onClick={() => setPlayVideo(false)}
                className="absolute top-2 right-2 text-2xl z-20 text-gray-700 dark:text-gray-200 bg-gray-300 dark:bg-gray-600"
            >
                <i className="fa-solid fa-times" />
            </Button>

            <Card isFooterBlurred className="w-full h-auto col-span-12 sm:col-span-7">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                    <p className="text-md text-white/60 font-bold">{videoData?.user?.name}</p>
                    <h4 className="text-white/90 font-medium text-xl">HD Video</h4>
                </CardHeader>

                <video
                    controls
                    // ref={videoRef}
                    src={videoSource}
                    className="w-full h-[40rem] object-contain border-0 rounded-t-2xl cursor-pointer"
                />

                {/* <CardFooter className="absolute bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100"> */}
                <CardFooter className="bg-black/40 bottom-0 z-10 border-default-600 dark:border-default-100">

                    <div className="flex flex-grow gap-2 items-center">
                        <Image
                            alt="Breathing app icon"
                            className="rounded-full w-11 h-11 bg-black"
                            src={defaultAvatar}
                        />
                        <div className="flex flex-col">
                            <p className="text-md text-white/60">{videoData?.user?.name}</p>
                            <p className="text-tiny text-white/60">HD Video</p>
                        </div>
                    </div>

                    {/* <div className="mr-1">
                        {videoData?.user?.id}
                    </div> */}

                    <Link
                        isExternal
                        showAnchorIcon
                        href={videoData?.user?.url}
                        anchorIcon={<i className="fa-solid fa-link ml-1" />}
                    >
                        Source
                    </Link>

                    {/* <Tooltip
                        showArrow={true}
                        color="primary"
                        content="Grant Star"
                    >
                        <Button
                            isIconOnly
                            size="sm"
                            radius="full"
                            variant="light"
                        >
                            <span className="text-lg text-warning">
                                <i className="fa-solid fa-star" />
                            </span>
                        </Button>
                    </Tooltip> */}

                </CardFooter>
            </Card>
        </div>
    );
};

export default Player;