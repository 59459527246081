// libraries
import React, { useEffect, useState } from "react";

const Spectrum = (props) => {

    // props
    const {
        uniqueKey,
    } = props;

    // react useState
    const [heights, setHeights] = useState([]);

    // random mizer function
    useEffect(() => {
        const randomHeights = Array.from({ length: 25 }, () => Math.floor(Math.random() * 12) + 1);
        setHeights(randomHeights);
    }, [uniqueKey]);

    return (
        <div className="flex flex-row items-center space-x-px">
            {heights.map((height, index) => (
                <div key={index} className={`h-${height} w-[0.23rem] bg-gray-500 rounded-lg`}></div>
            ))}
        </div>
    );
};

export default Spectrum;