// libraries
import React, { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownSection, DropdownItem, Button, cn, Avatar, Textarea, Tooltip, Chip } from "@nextui-org/react";

// custom components
import consoleLog from "../../functions/consoleLog";
import { fetchInteracts } from "../../pages/shinefy/Tools/API/toolsAPI";
import { ToastEffect } from "../../functions/ToastEffect";
import { addMessageToInteract } from "../../API/server";
import UserContext from "../UserContext";

const NotifView = (props) => {

    // props
    const {
        viewNotifContent,
    } = props;

    // context method
    const {

        // Others
        userAvatar,

        // For notification data params
        notifData,
        refetchNotifData,
        setRefetchNotifData,

    } = useContext(UserContext);

    // idon class
    const iconClasses = "text-xl text-default-500 pointer-events-none flex-shrink-0";

    // variables
    const maxRows = 8;

    // filter notif data
    const filteredNotifData = notifData?.find((data) => data?._id === viewNotifContent?._id);

    // extracted data from "notificationData"
    const toEmail = filteredNotifData?.from_email;
    const linkedID = filteredNotifData?.linked_id;
    const fromEmail = filteredNotifData?.to_email;
    const interacts = filteredNotifData?.interacts;

    // use ref
    const endOfMessagesRef = useRef(null);

    // react components
    const [interactData, setInteractData] = useState([]);
    const [message, setMessage] = useState("");
    const [rows, setRows] = useState(1);
    const [isSubmittingResponse, setIsSubmittingResponse] = useState(false);

    // console log activity
    // consoleLog("interactData", interactData);
    // consoleLog("notifData", notifData);
    // consoleLog("viewNotifContent", viewNotifContent);
    // consoleLog("filteredNotifData", filteredNotifData);
    // consoleLog("interacts", interacts);

    // update interact data
    useEffect(() => {
        setInteractData(interacts);
    }, [interacts]);

    // fetch notif interacts
    // useEffect(() => {
    //     const fetchInteractions = async () => {

    //         // check if data exist
    //         if (!filteredNotifData) { return; }

    //         // required data
    //         const requiredData = {
    //             linkedID: filteredNotifData?.linked_id,
    //             toEmail: fromEmail,
    //         };


    //         await fetchInteracts(requiredData)
    //             .then((response) => {

    //                 // set fetched data
    //                 setInteractData(response?.data?.data);

    //                 // console log activity
    //                 consoleLog("response", response?.data);
    //             });
    //     };
    //     fetchInteractions();
    // }, [filteredNotifData, notifData, refetchNotifData]);

    // handle on change
    const handleChange = (event) => {
        const { value } = event.target;
        setMessage(value);

        const lineBreaks = (value.match(/\n/g) || []).length + 1;
        setRows(Math.min(lineBreaks, maxRows));
    };

    // send message sub function
    const sensMessageSubFunction = () => {
        // Check if the message contains non-whitespace characters
        if (message.trim().length > 0) {
            handleSendMessage();
        }
    };

    // handle on key down
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // Prevent the default newline behavior
            // call the function
            sensMessageSubFunction();
        }
    };

    // handle send message
    const handleSendMessage = async () => {

        // check if email exist
        if (!toEmail) {
            ToastEffect("error", "Receiver email is not valid.");
            return;
        }

        // check if "linkedID" exist
        if (!linkedID) {
            ToastEffect("error", "linked id is invalid.");
            return;
        }

        // check if message exist
        if (message?.length <= 0) {
            ToastEffect("error", "Interact message is required.");
            return;
        }

        // Your send message logic here
        // consoleLog("Message sent:", message);
        setMessage(""); // Clear the message after sending
        setRows(1); // Reset rows to default

        // require data
        const requiredData = {
            from: fromEmail,
            to: toEmail,
            message: message,
            linkedID: linkedID,
            notifData: filteredNotifData,
            createdAt: new Date(),
            updatedAt: new Date(),
        };

        // trigger markings
        setIsSubmittingResponse(true);

        // set new data
        setInteractData(prevData => [...prevData, requiredData]);

        // send the message to the server 
        await addMessageToInteract(requiredData)
            .then((response) => {
                // refetch message interactions
                setRefetchNotifData(oldVal => oldVal + 1);
                // console log activity
                consoleLog("response", response?.data);
            });

        // disable markings when done
        setIsSubmittingResponse(false);
    };

    useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({
                // behavior: 'smooth'
            });
        }
    }, [notifData, interactData]);

    return (
        <section className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800 xl:mb-0">
            {/* <section className="bg-white dark:bg-gray-800 py-4 rounded-lg antialiased"> */}
            {/* <div className="max-w-2xl mx-auto px-4 overflow-y-auto h-[90vh]"> */}
            <div className="overflow-y-auto max-h-[85vh] px-2">

                <article className="p-6 text-base bg-white dark:bg-gray-800 sticky top-1 z-10 border-b-1 border-gray-600">
                    <footer className="flex justify-between items-center mb-2">
                        <div className="flex items-center">
                            <p className="inline-flex items-center mr-3 text-sm text-gray-800 dark:text-white font-semibold">
                                <span className="text-base mr-2 text-primary-600">
                                    <i className="fa-solid fa-bell" />
                                </span>
                                {filteredNotifData?.notif_title}
                            </p>
                            <p className="text-sm text-gray-600 dark:text-gray-400">
                                <time dateTime="2022-02-08" title="February 8th, 2022">
                                    {moment(filteredNotifData?.createdAt).format('MMM. D, YYYY')}
                                </time>
                            </p>
                        </div>

                        {filteredNotifData?.report?.isResolved && (
                            <Chip
                                startContent={
                                    <span>
                                        <i className="fa-solid fa-circle-check" />
                                    </span>
                                }
                                variant="faded"
                                color="success"
                                radius="sm"
                                size="sm"
                                className="bg-gray-700"
                            >
                                Resolved
                            </Chip>
                        )}

                    </footer>

                    <p className="text-gray-500 dark:text-gray-400">
                        {filteredNotifData?.main_content}
                    </p>

                    {/* <div className="flex items-center mt-4 space-x-4">
                        <button type="button"
                            className="flex items-center text-sm text-gray-500 hover:underline dark:text-gray-400 font-medium">
                            <svg className="mr-1.5 w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5h5M5 8h2m6-3h2m-5 3h6m2-7H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z" />
                            </svg>
                            Reply
                        </button>
                    </div> */}

                </article>

                {/* <article className="p-6 mb-3 ml-6 lg:ml-12 text-base bg-white rounded-lg dark:bg-gray-900">
                    <footer className="flex justify-between items-center mb-2">
                        <div className="flex items-center">
                            <p className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white font-semibold">
                                <img
                                    className="mr-2 w-6 h-6 rounded-full"
                                    src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                                    alt="Jese Leos" />Jese Leos</p>
                            <p className="text-sm text-gray-600 dark:text-gray-400"><time dateTime="2022-02-12"
                                title="February 12th, 2022">Feb. 12, 2022</time></p>
                        </div>
                        <button id="dropdownComment2Button" data-dropdown-toggle="dropdownComment2"
                            className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500 dark:text-gray-40 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50 dark:bg-gray-900 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                            type="button">
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                                <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                            </svg>
                            <span className="sr-only">Comment settings</span>
                        </button>
                        <div id="dropdownComment2"
                            className="hidden z-10 w-36 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600">
                            <ul className="py-1 text-sm text-gray-700 dark:text-gray-200"
                                aria-labelledby="dropdownMenuIconHorizontalButton">
                                <li>
                                    <a href="#"
                                        className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Edit</a>
                                </li>
                                <li>
                                    <a href="#"
                                        className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Remove</a>
                                </li>
                                <li>
                                    <a href="#"
                                        className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Report</a>
                                </li>
                            </ul>
                        </div>
                    </footer>
                    <p className="text-gray-500 dark:text-gray-400">Much appreciated! Glad you liked it ☺️</p>
                    <div className="flex items-center mt-4 space-x-4">
                        <button type="button"
                            className="flex items-center text-sm text-gray-500 hover:underline dark:text-gray-400 font-medium">
                            <svg className="mr-1.5 w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5h5M5 8h2m6-3h2m-5 3h6m2-7H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z" />
                            </svg>
                            Reply
                        </button>
                    </div>
                </article> */}

                {/* interactions */}
                {interactData?.map((data, idx) => {
                    return (
                        <article ref={endOfMessagesRef} key={idx} className="p-6 text-base bg-white border-t border-gray-200 dark:border-gray-700 dark:bg-gray-800">

                            <footer className="flex justify-between items-center mb-2">

                                <div className="flex items-center">
                                    <p className="inline-flex items-center mr-3 text-sm text-gray-800 dark:text-white font-semibold">
                                        {/* <img
                                            className="mr-2 w-6 h-6 rounded-full"
                                            src="https://flowbite.com/docs/images/people/profile-picture-3.jpg"
                                            alt="Bonnie Green"
                                        /> */}
                                        <Avatar
                                            // size="sm"
                                            isBordered
                                            src={data?.from === "admin" ? "user.jpg" : userAvatar}
                                            className="w-6 h-6 text-tiny mr-3"
                                        />
                                        {data?.from === "admin" ? data?.from?.charAt(0)?.toUpperCase() + data?.from?.slice(1) : data?.from}
                                    </p>
                                    <p className="text-sm text-gray-600 dark:text-gray-400">
                                        <time dateTime="2022-03-12" title="March 12th, 2022">
                                            {moment(data?.createdAt).fromNow()}
                                        </time>
                                    </p>
                                </div>

                                {/* <Dropdown className="">
                                    <DropdownTrigger>
                                        <Button
                                            isIconOnly
                                            variant="light"
                                            size="sm"
                                            startContent={
                                                <span className="text-xl">
                                                    <i className="fa-solid fa-ellipsis" />
                                                </span>
                                            }
                                        />
                                    </DropdownTrigger>
                                    <DropdownMenu variant="faded" aria-label="Dropdown menu with description">
                                        <DropdownSection title="Actions" showDivider>
                                            <DropdownItem
                                                key="edit"
                                                // shortcut="⌘⇧E"
                                                // description="Allows you to edit the file"
                                                startContent={
                                                    <span className={iconClasses}>
                                                        <i className="fa-solid fa-pen-to-square" />
                                                    </span>
                                                }
                                            >
                                                Edit file
                                            </DropdownItem>
                                        </DropdownSection>
                                        <DropdownSection title="Danger zone">
                                            <DropdownItem
                                                key="delete"
                                                className="text-danger"
                                                color="danger"
                                                // shortcut="⌘⇧D"
                                                // description="Permanently delete the file"
                                                startContent={
                                                    <span className={cn(iconClasses, "text-danger")}>
                                                        <i className="fa-solid fa-trash" />
                                                    </span>
                                                }
                                            >
                                                Delete file
                                            </DropdownItem>
                                        </DropdownSection>
                                    </DropdownMenu>
                                </Dropdown> */}

                            </footer>

                            <p className="text-gray-500 dark:text-gray-400 whitespace-pre-line">
                                {data?.message}
                            </p>

                            {/* <div className="flex items-center mt-4 space-x-4">
                                <button type="button"
                                    className="flex items-center text-sm text-gray-500 hover:underline dark:text-gray-400 font-medium">
                                    <svg className="mr-1.5 w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5h5M5 8h2m6-3h2m-5 3h6m2-7H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z" />
                                    </svg>
                                    Reply
                                </button>
                            </div> */}

                        </article>
                    );
                })}

                {interactData?.length > 0 && filteredNotifData?.canReply && (
                    <div className={`${interactData?.length <= 0 && "mt-5"} bg-white dark:bg-gray-800 sticky bottom-0 z-20 mx-auto px-4`}>
                        <div className="relative mx-2">
                            <Textarea
                                disabled={isSubmittingResponse ? true : false}
                                size="lg"
                                label="Reply Message"
                                variant="bordered"
                                placeholder="Enter your message"
                                disableAnimation
                                disableAutosize
                                classNames={{
                                    base: "h-full",
                                    // input: "resize-y min-h-[40px]",
                                }}
                                value={message}
                                onValueChange={setMessage}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                rows={rows}
                                style={{ minHeight: '40px' }}
                            />
                            <div className="absolute bottom-2 right-1">
                                <Tooltip showArrow={true} color="primary" content="Send message">
                                    {/* <span
                                        onClick={sensMessageSubFunction}
                                        className="cursor-pointer text-2xl mr-2 text-gray-500 dark:text-gray-500 hover:text-gray-400"
                                    >
                                        <i className="fa-solid fa-paper-plane" />
                                    </span> */}
                                    <Button
                                        isIconOnly
                                        size="sm"
                                        variant="light"
                                        startContent={
                                            !isSubmittingResponse && (
                                                <span className="text-2xl text-gray-500 dark:text-gray-500 hover:text-gray-400">
                                                    <i className="fa-solid fa-paper-plane" />
                                                </span>
                                            )
                                        }
                                        onClick={sensMessageSubFunction}
                                        isLoading={isSubmittingResponse ? true : false}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section >
    );
};

export default NotifView;;;;