// libraries
import React, { useContext, useEffect, useState } from "react";
import { Button, Divider, Select, SelectItem, Spinner, Switch, Tooltip, cn } from "@nextui-org/react";

// custom components
import { TTSModels, aspectRatio } from "../Utils/data";
import Video from "./CraftTab/Video";
import consoleLog from "../../../../../functions/consoleLog";
import UserContext from "../../../../../components/UserContext";
import { craftVideoContent } from "../../API/toolsAPI";
import { ToastEffect } from "../../../../../functions/ToastEffect";
import Process from "./CraftTab/Process";
import HeadNav from "./CraftTab/HeadNav";
import { payVideo, recordCredits } from "../../../../../API/account";
import { adjustCreditsView } from "../../../../../functions/itemWithExpiry";
import { cachedConfig } from "../../../../../functions/general";

const CraftTab = (props) => {

    // props
    const {
        selectedModel,
        selectedFont,
        selectedDimension,
        setSelectedDimension,
        incomingScript,
        artisanSession,
        sessionID,
        appProcessStatus,
        setRefetchArtisanSessions,
        videoSource,
        noCaptionVideo,
        selectedFontColor,
        selectedCaptionPosition,
        onOpenEM,
        pricingData,
        // setRefetchSLDBalance,
        processedData,
        setProcessedData,
        subscriptionData,
        isOldUser,
        setVideoData,
        onOpenMV,
    } = props;

    // context method
    const {

        // user data
        userData,

        // post content
        onOpenPC,
        setVideoContentData,

    } = useContext(UserContext);

    // extracted values
    const email = userData?.UserInfo?.email;
    const videoGeneratePrice = pricingData[0]?.videoGenerate;
    const orientation = aspectRatio.find((data) => data?.size === artisanSession?.video?.aspectRatio);

    // local storage key
    const localStorageKey = 'processProgressStatus';

    // react useState
    const [isCraftingVideo, setIsCraftingVideo] = useState(false);
    const [isCaptionEnabled, setIsCaptionEnabled] = useState(true);
    const [triggerVideoDisplay, setTriggerVideoDisplay] = useState(0);

    // console log activity
    // consoleLog("selectedDimension", selectedDimension);
    // consoleLog("artisanSession", artisanSession);

    // handle selected dimention
    const handleSelectedDimention = async (e) => {

        // extracted event value
        const dimention = e.target.value || "Portrait";

        // set selected dimention
        setSelectedDimension(dimention);

        // cached selected voice model
        await cachedConfig("videoDimension", dimention);

        // trigger video display if orientation changes
        setTriggerVideoDisplay((oldVal) => oldVal + 1);

        // console log activity
        consoleLog("dimention", dimention);

    };

    // process incoming progress data
    useEffect(() => {
        const processData = () => {

            // filter for signature 'process_craft_video'
            if (appProcessStatus?.app_status?.signature !== "process_craft_video") {

                // disable loading if process got error
                if (appProcessStatus?.app_status?.status === "error") {
                    setIsCraftingVideo(false);
                }

                // Retrieve existing data from local storage
                const existingData = JSON.parse(localStorage.getItem(localStorageKey)) || [];

                // Check if an item with the same signature already exists
                const existingIndex = existingData.findIndex(item => item?.signature === appProcessStatus?.app_status?.signature);

                if (existingIndex !== -1) {
                    // Update the existing item
                    existingData[existingIndex] = appProcessStatus?.app_status;
                } else {
                    // Add the new item
                    existingData.push(appProcessStatus?.app_status);
                }

                // Save the updated data back to local storage
                localStorage.setItem(localStorageKey, JSON.stringify(existingData));

                // Update the state with the new data
                setProcessedData(existingData);
            } else {
                if (appProcessStatus?.app_status?.status === "start") {
                    setIsCraftingVideo(true);
                } else {
                    setRefetchArtisanSessions((oldVal) => oldVal + 1); // refetch artisan session data
                    setIsCraftingVideo(false); // disable trigger if done
                }
            }
        };

        processData();
    }, [appProcessStatus]);

    // handle crafting video content
    const handleCraftVideo = async () => {

        // conditionally extracted the script value
        const script = artisanSession?.topic || incomingScript;

        // check if user data exist
        if (!userData) {
            ToastEffect("error", "This is not possible.");
            return;
        };

        // check if script exist
        if (!script) {
            ToastEffect("error", "Script is required.");
            return;
        };

        // check if session id exist
        if (!sessionID) {
            ToastEffect("error", "\"sessionID\" is required.");
            return;
        };

        // check if artisanSession exist
        if (!artisanSession) { return; } // do nothing

        // set trigger sending craft request
        setIsCraftingVideo(true);

        // // check if statsu is filed charge nothing.
        // if (artisanSession?.status !== "failed") {

        //     // make payment
        //     const paymentResult = await paySLD({ // pay SLD
        //         email: email, // user account email
        //         amount: pricingData?.message?.videoGenerate, // payment amount
        //         serviceKind: "generate_video"
        //     });

        //     // console log activity
        //     // consoleLog("paymentResult", paymentResult?.data);

        //     // check payment response
        //     if (paymentResult?.data?.response === "no_balance") {
        //         setIsCraftingVideo(false); // trigger is generating
        //         ToastEffect("error", "Insufficient SLD balance.");
        //         return;
        //     }

        //     // refetch subscription data
        //     // setRefetchSLDBalance((oldVal) => oldVal + 1);
        // }

        // filter to fetch the specific data
        const filteredModelData = TTSModels?.find((data) => data?.model === selectedModel);
        const filteredDimension = aspectRatio?.find((data) => data?.ratio === selectedDimension);

        // required data
        const requiredData = {
            email: email,
            script: script,
            voice_model: filteredModelData?.model,
            platform: filteredModelData?.platform,
            caption_font: selectedFont,
            aspect_ratio: filteredDimension?.size || "1024x1792",
            session_id: sessionID,
            font_color: selectedFontColor,
            caption_position: selectedCaptionPosition,
            client_auth: process.env.REACT_APP_AUTH,
        };

        // call the function to send craft request
        await craftVideoContent(requiredData)
            .then(async (response) => {

                if (response?.data?.detail === "request_limit") {
                    ToastEffect("error", "You can only process once.");
                } else {
                    // clean progress data
                    setProcessedData([]);
                    localStorage.removeItem(localStorageKey);
                }

                // process payment
                if (subscriptionData?.message === "freeAccess") {
                    let paymentKind = "freeAccess";

                    if (subscriptionData?.message === "subscribed" && isOldUser) {
                        paymentKind = "OldUser";
                    }

                    await recordCredits(email, response?.data?.task_id, "Artisan", paymentKind, videoGeneratePrice, 0, Number(0));
                } else {
                    await payVideo(email, videoGeneratePrice, "Artisan"); // pay video
                    await recordCredits(email, response?.data?.task_id, "Artisan", "payment", videoGeneratePrice, 0, Number(0)); // record credit cost
                    adjustCreditsView("tokenBalance", "pay", videoGeneratePrice); // adjust credit UI display
                }

                // console log activity
                consoleLog("craftVideoContent", response?.data);
            });

        // disable trigger sending craft request
        // setIsCraftingVideo(false);

        // console log activity
        consoleLog("requiredData", requiredData);

    };

    return (
        <div className="p-2 space-y-3">

            <h2 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                Content informations
            </h2>

            {/* <div className="items-center gap-8 rounded-lg border border-gray-200 bg-white p-6 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:flex">
                <a href="#" className="mb-4 flex aspect-square h-14 w-14 shrink-0 items-center sm:mb-0">
                    <img className="h-auto max-h-full w-full dark:hidden" src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front.svg" alt="imac image" />
                    <img className="hidden h-auto max-h-full w-full dark:block" src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg" alt="imac image" />
                </a>
                <p className="truncate max-w-[19rem] lg:max-w-lg flex-1 font-medium text-gray-500 dark:text-gray-400">
                    {artisanSession?.topic || incomingScript}
                </p>
            </div> */}

            <div className="lg:grid lg:grid-cols-2 gap-2">

                <div className="space-y-4 sm:space-y-2 rounded-lg border border-gray-100 bg-gray-50 p-6 dark:border-gray-700 dark:bg-gray-800 mb-6 md:mb-1">
                    <dl className="flex justify-between items-center">
                        <dt className="font-normal mb-1 sm:mb-0 text-gray-500 dark:text-gray-400">Voice</dt>
                        <dd className="font-medium text-gray-900 dark:text-white sm:text-end"><code>{selectedModel}</code></dd>
                    </dl>
                    <dl className="flex justify-between items-center">
                        <dt className="font-normal mb-1 sm:mb-0 text-gray-500 dark:text-gray-400">Font</dt>
                        <dd className="font-medium text-gray-900 dark:text-white sm:text-end"><code>{selectedFont}</code></dd>
                    </dl>
                    <dl className="flex justify-between items-center">
                        <dt className="font-normal mb-1 sm:mb-0 text-gray-500 dark:text-gray-400">Dimension</dt>
                        <dd className="font-medium text-gray-900 dark:text-white sm:text-end"><code>{selectedDimension}</code></dd>
                    </dl>
                    <dl className="flex justify-between items-center">
                        <dt className="font-normal mb-1 sm:mb-0 text-gray-500 dark:text-gray-400">Cost</dt>
                        <dd className="font-medium text-gray-900 dark:text-white sm:text-end">
                            <code>{artisanSession?.status === "failed" ? "0" : videoGeneratePrice} SLD</code>
                        </dd>
                    </dl>

                    {/* craft button */}
                    <Tooltip
                        showArrow={true}
                        color="secondary"
                        content={
                            <div className="px-1 py-1">
                                <div className="text-small font-bold">
                                    Craft video {artisanSession?.status === "failed" && "(failed)"}
                                </div>
                                <div className="text-tiny">
                                    This will cost <code className="text-sky-300 font-semibold">{artisanSession?.status === "failed" ? "0" : videoGeneratePrice} SLD</code>
                                </div>
                            </div>
                        }
                    >
                        <Button
                            size="lg"
                            radius="sm"
                            color={artisanSession?.status === "failed" ? "danger" : "primary"}
                            variant="shadow"
                            isLoading={isCraftingVideo}
                            className="text-lg font-semibold w-full"
                            startContent={
                                <span className={`text-xl ${isCraftingVideo && "hidden"}`}>
                                    {artisanSession?.status === "failed" ? (
                                        <i className="fa-regular fa-circle-xmark" />
                                    ) : (
                                        <i className="fa-solid fa-screwdriver-wrench" />
                                    )}
                                </span>
                            }
                            onClick={handleCraftVideo}
                        >
                            Craft Video
                        </Button>
                    </Tooltip>

                </div>

                <div className="space-y-4 sm:space-y-2 rounded-lg border border-gray-100 bg-gray-50 p-6 dark:border-gray-700 dark:bg-gray-800 mb-6 md:mb-1">
                    <div className="flex-row w-full flex-wrap md:flex-nowrap space-y-2">

                        {/* enable | disable caption */}
                        {/* <div className="flex justify-between items-center">
                            <p>Enable Caption</p>
                            <Switch
                                size="sm"
                                isSelected={isCaptionEnabled}
                                onValueChange={setIsCaptionEnabled}
                            />
                        </div> */}

                        {/* <div className="flex justify-between items-center">
                            <Switch
                                classNames={{
                                    base: cn(
                                        "inline-flex flex-row-reverse min-w-full bg-gray-100 hover:bg-gray-100/80 dark:bg-gray-900 dark:hover:bg-gray-900/80 items-center",
                                        "justify-between cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent",
                                        "data-[selected=true]:border-primary",
                                    ),
                                    wrapper: "p-0 h-4 overflow-visible",
                                    thumb: cn("w-6 h-6 border-2 shadow-lg",
                                        "group-data-[hover=true]:border-primary",
                                        //selected
                                        "group-data-[selected=true]:ml-6",
                                        // pressed
                                        "group-data-[pressed=true]:w-7",
                                        "group-data-[selected]:group-data-[pressed]:ml-4",
                                    ),
                                }}
                                isSelected={isCaptionEnabled}
                                onValueChange={setIsCaptionEnabled}
                            >
                                <div className="flex flex-col gap-1">
                                    <p className="text-medium">Enable Caption</p>
                                    <p className="text-tiny text-default-400">
                                        Toggle to enable or disable video captions.
                                    </p>
                                </div>
                            </Switch>
                        </div> */}

                        {/* aspect ratio */}
                        <Select
                            size="sm"
                            color="warning"
                            variant="bordered"
                            label="Select Orientation"
                            className="w-full"
                            selectedKeys={[selectedDimension]}
                            onChange={(e) => handleSelectedDimention(e)}
                        // defaultSelectedKeys={["Portrait"]}
                        >
                            {aspectRatio.map((data) => (
                                <SelectItem key={data?.ratio}>
                                    {data.ratio}
                                </SelectItem>
                            ))}
                        </Select>

                        {/* transition */}
                        <Select
                            isDisabled // disabled for now
                            size="sm"
                            color="secondary"
                            variant="bordered"
                            label="Select Transition (Not yet available)"
                            className="w-full"
                        >
                            {aspectRatio.map((data) => (
                                <SelectItem key={data?.ratio}>
                                    {data.ratio}
                                </SelectItem>
                            ))}
                        </Select>

                    </div>
                </div>

            </div>

            {/* <Divider className="my-4 w-full" /> */}

            {/* head nav menu */}
            <HeadNav
                isCaptionEnabled={isCaptionEnabled}
                setIsCaptionEnabled={setIsCaptionEnabled}
                onOpenEM={onOpenEM}
                onOpenPC={onOpenPC}
                setVideoContentData={setVideoContentData}
                video={isCaptionEnabled ? videoSource : noCaptionVideo}
                artisanSession={artisanSession}
            />

            <div className="lg:flex lg:justify-between lg:items-center lg:px-7">

                {/* process status */}
                <Process
                    processedData={processedData}
                />

                {/* {videoSource && ( */}
                <Video
                    video={isCaptionEnabled ? videoSource : noCaptionVideo}
                    orientation={orientation?.ratio || selectedDimension}
                    selectedDimension={selectedDimension}
                    artisanSession={artisanSession}
                    triggerVideoDisplay={triggerVideoDisplay}
                    setVideoData={setVideoData}
                    onOpenMV={onOpenMV}
                />
                {/* )} */}

            </div>

        </div>
    );
};

export default CraftTab;