// libraries
import React from "react";
import { Button, Card, CardBody, CardFooter, Image } from "@nextui-org/react";

// custom components
import { getVideoSource, updateVideoSource } from "../../functions/artisan";
import consoleLog from "../../../../../../functions/consoleLog";
import { videoSourceUpdateRequest } from "../../../../../../API/server";

const Media = (props) => {

    // props
    const {
        searchedVideos,
        handlePlayVideo,
        artisanSession,
        selectedSequence,
        setArtisanSession,
    } = props;

    // handle the update new video source link
    const handleUpdateNewVideoSource = async (data) => {

        // extract the new video source link
        const newVideoSourceLink = getVideoSource(data);
        const currentNumber = selectedSequence?.number;

        // call "updateVideoSource" function
        const newData = updateVideoSource(
            artisanSession, // array data
            newVideoSourceLink, // new video source link
            currentNumber, // reference number
            "update", // operation kind ("update" || "reverse")
        );

        // set the new modefied data
        setArtisanSession(newData);

        // prepare required data
        const requiredData = {
            sessionID: artisanSession?._id,
            number: currentNumber,
            sourceLink: newVideoSourceLink,
        };

        // call function that will update the database
        await videoSourceUpdateRequest(requiredData)
            .then((response) => {
                // console log activity 
                consoleLog("videoSourceUpdate", response?.data);
            });

        // console log activity
        consoleLog("newData", newData);
        // consoleLog("selectedNewMediaSource", data);
        // consoleLog("artisanSession", artisanSession);
        // consoleLog("selectedSequence", selectedSequence);
    };

    return (
        <div className="gap-2 grid grid-cols-2 sm:grid-cols-5 p-5 max-h-[30rem] overflow-auto">
            {searchedVideos?.videos?.map((item, index) => {
                return (
                    <Card
                        shadow="sm"
                        key={index}
                        isPressable
                        onPress={() => handlePlayVideo(item)}
                    >
                        <CardBody className="overflow-visible p-0">
                            <Image
                                shadow="sm"
                                radius="none"
                                width="100%"
                                alt={item?.user?.name}
                                className="w-full object-cover h-[140px]"
                                src={item.image}
                            />
                        </CardBody>
                        <CardFooter className="justify-between">
                            {/* <b>{item?.user?.name}</b>
                            <p className="text-default-500">HD</p> */}
                            <Button
                                size="sm"
                                variant="bordered"
                                className="w-full"
                                onClick={() => handleUpdateNewVideoSource(item)}
                            >
                                Use Media
                            </Button>
                        </CardFooter>
                    </Card>
                );
            })}
        </div>
    );
};

export default Media;