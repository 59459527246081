import React, { useContext, useEffect, useState } from "react";
import MenuCard from "../../components/MenuCard";
import { SVGtxt2image, SVGVideoEnhancer } from "../../constant/images/SVG-images";
import UserContext from "../../components/UserContext";
import MenuCard_v2 from "../../components/MenuCard_v2";

const Menu = () => {

    const userData = useContext(UserContext);

    return (
        <section className="min-h-screen font-montserrat sm:px-5 md:px-10 px-15">

            <div className="py-4 px-4 mx-auto max-w-screen-xl lg:py-8 lg:px-6">

                {/* First row featured tools */}
                <div className="text-left w-full">
                    <h1 className="HeadingTitle mb-10">Featured Tools</h1>
                </div>

                <div className="grid gap-8 lg:grid-cols-2">

                    <div className="w-full h-auto">
                        <MenuCard
                            title="Image to Video"
                            toolIcon={<SVGtxt2image />}
                            statusText="v3.0"
                            mainText="Generate viral videos about anything!"
                            buttonText="Use Tool"
                            pageRedirect="/general-purpose"
                            // only set isAnchorInvalid to true if pageRedirect = #
                            isAnchorInvalid={false}
                            isHot={true}
                            isNew={false}
                        />
                    </div>

                    <div className="text-left w-full">
                        <MenuCard
                            title="Transcriber"
                            toolIcon={<SVGVideoEnhancer />}
                            statusText="v1.3"
                            mainText="Transcribe and refine results from any media source."
                            buttonText="Use Tool"
                            pageRedirect="/transcribe"
                            // only set isAnchorInvalid to true if pageRedirect = #
                            isAnchorInvalid={false}
                            isButtonDisabled={false}
                            isComingSoon={false}
                            isHot={true}
                            isNew={false}
                        />
                    </div>

                    <div className="text-left w-full">
                        <MenuCard
                            title="AI Chat"
                            toolIcon={<i className="fa-solid fa-comments mr-2" />}
                            statusText="v2.8"
                            mainText="Engage in intelligent conversations with our AI Chat."
                            buttonText="Use Tool"
                            pageRedirect="/ai_chat"
                            // only set isAnchorInvalid to true if pageRedirect = #
                            isAnchorInvalid={false}
                            isButtonDisabled={false}
                            isComingSoon={false}
                            isHot={true}
                            isNew={false}
                        />
                    </div>

                    {/* <MenuCard_v2 /> */}

                </div>

                <div className="divider mt-20 mb-10" />

                {/* Second row Other Tools */}
                <div className="text-left w-full">
                    <h1 className="HeadingTitle mb-10">Other Tools </h1>
                </div>

                <div className="grid gap-8 lg:grid-cols-2 mb-20">

                    <div className="text-left w-full">
                        <MenuCard
                            title="Video Gallery"
                            toolIcon={<i className="fa-solid fa-film mr-2" />}
                            statusText="v1.0"
                            mainText="Showcase your videos in a stunning gallery with ease."
                            buttonText="Visit Gallery"
                            pageRedirect="/videos"
                            // only set isAnchorInvalid to true if pageRedirect = #
                            isAnchorInvalid={false}
                            isButtonDisabled={false}
                            isComingSoon={false}
                            isHot={false}
                            isNew={true}
                        />
                    </div>

                    <div className="text-left w-full">
                        <MenuCard
                            title="Image Gallery"
                            toolIcon={<i className="fa-regular fa-images mr-2" />}
                            statusText="v1.0"
                            mainText="Display and organize your images in a beautiful gallery."
                            buttonText="Visit Gallery"
                            pageRedirect="/images"
                            // only set isAnchorInvalid to true if pageRedirect = #
                            isAnchorInvalid={false}
                            isButtonDisabled={false}
                            isComingSoon={false}
                            isHot={false}
                            isNew={true}
                        />
                    </div>

                </div>
            </div>
        </section>
    );
};

export default Menu;