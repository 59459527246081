// libraries
import React, { useContext, useEffect, useState } from "react";
import { Button, Divider, Spinner, Textarea, Tooltip } from "@nextui-org/react";

// custom components
// import consoleLog, { cachedConfig, getConfigValue, isOnlySpaceOrTab } from "../../../functions/general";
// import { generateScript, saveModifiedScript } from "../../../APIs/Apps/ContentArtisan/request";
// import AppContext from "../../../components/AppContext";
import { getCreditBalance, payVideo, recordCredits } from "../../../../../API/account";
import { alertBeforeAction } from "../../Components/Transcriber/AlertBeforeAction";
import { useNavigate } from "react-router-dom";
import { cachedConfig, handlePurchaseCredits, isOnlySpaceOrTab } from "../../../../../functions/general";
import { adjustCreditsView } from "../../../../../functions/itemWithExpiry";
import { ToastEffect } from "../../../../../functions/ToastEffect";
import { generateScript, saveModifiedScript } from "../../../../../API/server";
import consoleLog from "../../../../../functions/consoleLog";
// import { paySLD } from "../../../APIs/server";

const ScriptTab = (props) => {

    // props
    const {
        myEmail,
        artisanSession,
        isFetchingArtisanSessions,
        pricingData,
        script,
        setScript,
        setSessionID,
        incomingScript,
        setIncomingScript,
        setRefetchArtisanSessions,
        subscriptionData,
        isOldUser,
    } = props;

    // context
    // const {

    //     // Alert
    //     onOpenAlert,
    //     setAlertContentData,

    // } = useContext(AppContext);

    // extracted values
    const scriptGeneratePrice = pricingData[0]?.scriptGenerate;

    // react useNavigate
    const navigate = useNavigate();

    // react useState
    const [isGenerating, setIsGenerating] = useState(false);
    const [modifiedScript, setModifiedScript] = useState("");

    // console log activity
    // consoleLog("artisanSession", artisanSession);
    // consoleLog("pricingData", pricingData);

    // always set default script
    useEffect(() => {
        setModifiedScript(artisanSession?.topic);
    }, [artisanSession]);

    // handle input value
    const handleInput = (event) => {
        setModifiedScript(event.target.textContent);
    };

    // handle key down when "Enter" is pressed
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.target.blur(); // To remove focus and trigger onBlur

            // call function will save the modified sript
            handleSaveModifiedScript();
        }
    };

    // handle save modified script
    const handleSaveModifiedScript = async () => {

        // return if "artisanSession" does not exist
        if (!artisanSession) { return; }

        // check if modefied script is grater than 4069 characters
        if ((modifiedScript)?.length > 4096) {
            ToastEffect("error", "Max of 4,096 characters only.");
            return;
        }

        try {

            // required data
            const requiredData = {
                sessionID: artisanSession?._id,
                modifiedScript: modifiedScript,
            };

            // trigger is generating
            setIsGenerating(true);

            // call function to send request to save modified script to the server
            await saveModifiedScript(requiredData)
                .then((response) => {

                    // refetch artisan session
                    setRefetchArtisanSessions((oldVal) => oldVal + 1);

                    // console log activity
                    consoleLog("response", response?.data);
                });

            // trigger is generating
            setIsGenerating(false);

            // console log activity
            consoleLog("modifiedScript", modifiedScript);

        } catch (err) {
            // console log error
            consoleLog("Error! #handleSaveModifiedScript", err?.message);
        }

    };

    // handle topic on change
    const handleTopic = async (value) => {

        // set new value
        setScript(value);

        // save script to local storage
        await cachedConfig("scriptTopic", value);

    };

    // const handle generate script function
    const handleGenerateScript = async () => {

        // if pricing does not exist return
        if (!pricingData) { return; }

        // catch if script is empty 
        if (!script || isOnlySpaceOrTab(script)) {
            ToastEffect("error", "Topic is required.");
            return;
        }

        try {

            // trigger is generating
            setIsGenerating(true);

            // // make payment
            // const paymentResult = await paySLD({ // pay SLD
            //     email: myEmail, // user account email
            //     amount: pricingData[0]?.scriptGenerate, // payment amount
            //     serviceKind: "generate_script"
            // });

            // // console log activity
            // // consoleLog("paymentResult", paymentResult?.data);

            // // check payment response
            // if (paymentResult?.data?.response === "no_balance") {

            //     // set alert data
            //     setAlertContentData({
            //         title: "This is not possible!",
            //         // content: paymentResult?.data?.message,
            //         content: "Insufficient SLD balance.",
            //     });

            //     // open alert modal
            //     onOpenAlert();

            //     // trigger is generating
            //     setIsGenerating(false);

            //     return;
            // }

            // // refetch subscription data
            // setRefetchSLDBalance((oldVal) => oldVal + 1);

            // get credit balance
            const ceditBalance = await getCreditBalance(myEmail);

            // check credit balance
            if (subscriptionData?.message !== "freeAccess") {

                // Extracting repeated variable calls
                const subscriptionCredits = ceditBalance?.data?.message?.subs || 0;
                const paidCredits = ceditBalance?.data?.message?.paid || 0;
                // const generalPurposePrice = paymentPrice[0]?.GeneralPurpose || 0;

                // Calculating the total credits and total price
                const totalCredits = (subscriptionCredits + paidCredits);

                // console.log(totalCredits, totalPrice);

                if (totalCredits < scriptGeneratePrice) {
                    setIsGenerating(false);
                    await alertBeforeAction(handlePurchaseCredits, "handleFunction2", "Low-Credits", { navigate });
                    return;
                }
            }

            // call the generate script
            await generateScript({ email: myEmail, topic: script })
                .then(async (response) => {

                    // process payment
                    if (subscriptionData?.message === "freeAccess") {
                        let paymentKind = "freeAccess";

                        if (subscriptionData?.message === "subscribed" && isOldUser) {
                            paymentKind = "OldUser";
                        }

                        await recordCredits(myEmail, response?.data?.sessionID, "Artisan", paymentKind, scriptGeneratePrice, 0, Number(0));
                    } else {
                        await payVideo(myEmail, scriptGeneratePrice, "Artisan"); // pay video
                        await recordCredits(myEmail, response?.data?.sessionID, "Artisan", "payment", scriptGeneratePrice, 0, Number(0)); // record credit cost
                        adjustCreditsView("tokenBalance", "pay", scriptGeneratePrice); // adjust credit UI display
                    }

                    // set new incoming topic script
                    setIncomingScript(response?.data?.message);
                    setModifiedScript(response?.data?.message);
                    setSessionID(response?.data?.sessionID);

                    // refetch artisan session
                    setRefetchArtisanSessions((oldVal) => oldVal + 1);

                    // console log activity
                    consoleLog("scriptResult", response?.data);

                });

            // trigger is generating
            setIsGenerating(false);

        } catch (err) {
            // console log error
            consoleLog("Error! #handleGenerateScript", err?.message);
        }

    };

    return (
        <div className="lg:min-w-[40rem] lg:max-w-[40rem] w-full">
            <div className="lg:grid lg:grid-cols-1 gap-2">

                <div className="relative mx-0 lg:mb-0 mb-2">
                    <div className="relative">
                        <Textarea
                            autoFocus
                            size="lg"
                            label="Topic"
                            variant="bordered"
                            placeholder="Enter your topic here"
                            disabled={isGenerating}
                            minRows={8}
                            maxRows={100}
                            value={script}
                            onValueChange={(value) => handleTopic(value)}
                            className="text-2xl font-semibold"
                        />

                        <div className="flex absolute bottom-0 right-0 p-4">
                            <Tooltip
                                showArrow={true}
                                color="secondary"
                                content={
                                    <div className="px-1 py-1">
                                        <div className="text-small font-bold">Generate script</div>
                                        <div className="text-tiny">This will cost <code className="text-sky-300 font-semibold">{pricingData[0]?.scriptGenerate} SLD</code></div>
                                    </div>
                                }
                            >
                                <Button
                                    size="md"
                                    color="primary"
                                    variant="shadow"
                                    radius="md"
                                    // isIconOnly
                                    isLoading={isGenerating}
                                    onClick={handleGenerateScript}
                                    startContent={
                                        <span className={`${isGenerating && "hidden"} text-3xl`}>
                                            <i className="fa-solid fa-feather" />
                                        </span>
                                    }
                                    className="text-md font-semibold"
                                />
                            </Tooltip>

                        </div>
                    </div>
                </div>

                <div className="relative mt-0 px-0">

                    {/* <Divider className="my-4 lg:hidden" /> */}

                    {artisanSession?.topic || incomingScript ? (
                        <>
                            <Tooltip
                                showArrow={true}
                                color="primary"
                                offset={-10}
                                content="Click to edit script."
                            >
                                <p
                                    className="mb-0 p-6 rounded-xl text-lg font-semibold border text-gray-500 dark:text-gray-400 whitespace-pre-line"
                                    contentEditable
                                    suppressContentEditableWarning
                                    onInput={handleInput}
                                    onKeyDown={handleKeyDown}
                                    onBlur={handleSaveModifiedScript}
                                >
                                    {artisanSession?.topic || incomingScript}
                                </p>
                            </Tooltip>
                            <code className="flex absolute -top-3 left-0 p-4 text-warning">
                                {((modifiedScript)?.length)?.toLocaleString() || ((artisanSession?.topic)?.length)?.toLocaleString() || ((incomingScript)?.length)?.toLocaleString()}/4096
                            </code>
                        </>
                    ) : (
                        <>
                            <p className="mb-0 p-4 text-lg font-semibold text-gray-500 dark:text-gray-400 whitespace-pre-line">
                                The generated script will appear here. <span className="text-gray-300 dark:text-gray-700">Ex. In the heart of ancient Egypt, in a village nestled by the mighty Nile, lived a curious girl named Amara.
                                    She was fascinated by the stories of the grand pyramids and the powerful pharaohs.
                                    But the tale that intrigued her the most was about the dark side of Egypt, a secret whispered only in the deepest shadows...</span>
                            </p>

                            {isFetchingArtisanSessions && (
                                <div className="absolute inset-0 flex items-center justify-center z-10 backdrop-blur-sm">
                                    <Spinner size="lg" label="Loading..." color="warning" />
                                </div>
                            )}
                        </>
                    )}

                </div>
            </div>
        </div>
    );
};

export default ScriptTab;