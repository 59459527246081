// libraries
import React from 'react';

// custom components
import consoleLog from '../../../../../functions/consoleLog';
import { Switch } from '@nextui-org/react';
import UserContext from '../../../../../components/UserContext';

const TranscriberOption = (props) => {

    // props
    const {
        ToolTipDisplay,
        isMobile,
        toolPrices,
        transcriberOption,
        handleTranscriberEnhancer,
        handleTranscriberLanguage,
        transcriberLanguage,
        transcriberEnhancer,
        handleSelectedLanguage,
        languageList,
        transcriberLanguageID,
        transcriberLanguageName
    } = props;

    // context method
    const {

        // Others
        appConfigData,

    } = React.useContext(UserContext);
    
    // appConfigData extracted values
    const config_enhancer = appConfigData[0]?.enhancer;
    const config_language = appConfigData[0]?.language;

    
    return (
        <div className="p-4 mt-2 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800 xl:mb-0">
            <div className="flow-root">
                <h3 className="text-xl font-semibold dark:text-white">Advance Options</h3>
                {/* <p className="text-sm font-normal text-gray-500 dark:text-gray-400">You can set up Themesberg to get notifications</p> */}
                <div className="divide-y divide-gray-200 dark:divide-gray-700">

                    {/* Enhanceer option */}
                    <div className="flex items-center justify-between py-4">
                        <div className="flex flex-col flex-grow z-50">
                            <div className="text-base font-semibold text-gray-900 dark:text-white">
                                Enhancer
                                <div className="cursor-help inline-flex items-center justify-center text-gray-600 ml-2">
                                    <ToolTipDisplay
                                        label={`
                                            <p class="break-normal ${isMobile ? "w-48" : "w-auto"}">
                                            Use enhancer will cost ${config_enhancer} Credit(s).
                                            </p>
                                        `}
                                        id="transcriber-enhancer"
                                        icon="fa-regular fa-circle-question"
                                        position="right-start"
                                    />
                                </div>

                                <span className={`inline-flex items-center justify-center w-[0.65rem] h-[0.65rem] ml-2 bg-${transcriberEnhancer ? "green" : "red"}-500 rounded-full`} />
                            </div>
                            <div className="text-sm font-semibold text-gray-500 dark:text-gray-400">
                                Enhance transcription result
                            </div>
                        </div>
                        <Switch
                            // defaultSelected aria-label="Automatic updates"
                            onChange={handleTranscriberEnhancer}
                            isSelected={transcriberEnhancer}
                        />
                        {/* <label htmlFor="enhancer" className="relative flex items-center cursor-pointer">
                            <input
                                onChange={handleTranscriberEnhancer}
                                checked={transcriberEnhancer}
                                type="checkbox"
                                id="enhancer"
                                className="sr-only"
                            />
                            <span className="h-6 bg-gray-200 border border-gray-200 rounded-full w-11 toggle-bg dark:bg-gray-700 dark:border-gray-600" />
                        </label> */}
                    </div>

                    {/* language option */}
                    <div className="flex items-center justify-between py-4">
                        <div className="flex flex-col flex-grow z-50">
                            <div className="text-base font-semibold text-gray-900 dark:text-white">
                                Language
                                <div className="cursor-help inline-flex items-center justify-center text-gray-600 ml-2">
                                    <ToolTipDisplay
                                        label={`
                                            <p class="break-normal ${isMobile ? "w-48" : "w-auto"}">
                                            Use Custom language will cost ${config_language} Credit(s).
                                            </p>
                                        `}
                                        id="transcriber-language"
                                        icon="fa-regular fa-circle-question"
                                        position="right-start"
                                    />
                                </div>

                                <span className={`inline-flex items-center justify-center w-[0.65rem] h-[0.65rem] ml-2 bg-${transcriberLanguage ? "green" : "red"}-500 rounded-full`} />
                            </div>
                            <div className="text-sm font-semibold text-gray-500 dark:text-gray-400">
                                Custom language
                            </div>
                        </div>
                        <Switch
                            // defaultSelected aria-label="Automatic updates"
                            onChange={handleTranscriberLanguage}
                            isSelected={transcriberLanguage}
                        />
                        {/* <label htmlFor="language" className="relative flex items-center cursor-pointer">
                            <input
                                onChange={handleTranscriberLanguage}
                                checked={transcriberLanguage}
                                type="checkbox"
                                id="language"
                                className="sr-only"
                            />
                            <span className="h-6 bg-gray-200 border border-gray-200 rounded-full w-11 toggle-bg dark:bg-gray-700 dark:border-gray-600" />
                        </label> */}
                    </div>
                    {transcriberLanguage && (
                        <div className="flex items-center">
                            <select
                                id="voiceModel"
                                onChange={handleSelectedLanguage}
                                className="w-full appearance-none rounded py-2 px-3 text-gray-500 dark:text-gray-400 bg-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={`${transcriberLanguageID}|${transcriberLanguageName}` || ""}
                            >
                                {languageList.map((data, index) => (
                                    <option
                                        value={`${data.language_id}|${data.name}`}
                                        key={index + 1}
                                    >
                                        {data.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}


                </div>
                {/* <div className="mt-6">
                    <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save all</button>
                </div> */}
            </div>
        </div>
    );
};

export default TranscriberOption;