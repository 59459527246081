// libraries
import React, { useEffect, useState, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Button, Chip, Select, SelectItem, Switch, Tab, Tabs, Tooltip, cn, useDisclosure } from "@nextui-org/react";

// Tools API
import { fetchDefaultVoiceModel, } from "../../API/toolsAPI";

// css
import "./index.css";

// custom components
import consoleLog from "../../../../../functions/consoleLog";
import GeneralPurposeForm from "../GeneralPurposeForm";
import formatNumber from "../../../../../functions/calculations";
import UserContext from "../../../../../components/UserContext";
import CustomThemeEditor from "../Modals/CustomThemeEditor";
import { ToastEffect } from "../../../../../functions/ToastEffect";
import { talkingPhotoList } from "../../../../../Configs/talkingPhotoList";
import { avatarList } from "../../../../../Configs/avatarList";
import { languageList } from "../../../../../Configs/languageList";
import { alertBeforeAction } from "../Transcriber/AlertBeforeAction";
import { autoOpenModalPromptEditor } from "../../functions/general";
import { safeFetchCustomVoiceModel } from "../../../../../functions/APIChecker/customVoice_Elevenlabs";
import { cachedToolsConfiguration, getCachedToolsConfiguration } from "../../../../../functions/general";
import { URLValues } from "../../VideoGenerator/Helpers/data";
import { saveConfig } from "../../API/configDBAPI";
import { toolPrices } from "../../../../../Configs/toolPrices";
import ToggleButton from "../../Components/ToggleButton";
import advanceOptionJsonData from "./index.json";
import CustomVideoThemeEditor from "../Modals/ToBeDeleted/CustomVideoThemeEditor";
import CustomImageThemeEditor from "../Modals/ToBeDeleted/CustomImageThemeEditor";
import AO_Switch from "./UI/AO_Switch";
import AO_tab1 from "./Tabs/AO_tab1";
import AO_tab2 from "./Tabs/AO_tab2";
import AO_tab3 from "./Tabs/AO_tab3";
import AO_tab5 from "./Tabs/AO_tab5";

const AdvancedOptions = (props) => {

    // props
    const {
        generateCustomVideo,
        isMobile,
        tab,
        setTab,
        avatarId,
        avatarURL,
        isAPIKeyAvatarErr,
        handleSelectedAvatar,
        loading,
        voiceId,
        backgroundMusic,
        selectedTalkingPhoto,
        selectedTalkingPhotoURL,
        onSelecTalkingPhoto,
        generateVideo,
        onSelectedVoice,
        handleDefaultVoice,
        defaultVoiceName,
        handleVideoAspectRatio,
        handleVideoTheme,
        handleImageTheme,
        videoAspectRatio,
        videoTheme,
        imageTheme,
        onSelectedLanguage,
        languageName,
        languageID,
        setAddAnimation,
        setEnableAccuracy,
        setEnableCaption,
        setEnableAvatar,
        setEnableLanguange,
        setEnableVoice,
        onSelectBackgroundMusic,
        setElevenLabsAPIKey,
        setHeyGenAPIKey,
        elevenLabsAPIKey,
        heyGenAPIKey,
        textScriptValue,
        voiceModels,
        isLoadingVoice,
        userIdea,
        handleUserIdea,
        isFieldError,
        voicePreview,
        avatarOption,
        creditCalculation,
        setCreditCalculation,
        customCreditCalculation,
        setCustomCreditCalculation,
        setCustomAudioCreditCalculation,
        setCreateCreditCalculation,
        promptKind,
        setPromptKind,
        handleEditCustomVideoTheme,
        handleCustomVideoThemePrompt,
        handleSaveCustomVideoThemePrompt,
        customVideoThemePrompt,
        savingCustomVideoThemePrompt,
        handleEditCustomImageTheme,
        handleCustomImageThemePrompt,
        handleSaveCustomImageThemePrompt,
        customImageThemePrompt,
        savingCustomImageThemePrompt,
        setElvenLabsAPIValidity,
        setTextScript,
        setVoiceModelList,
        voiceModelList,
        isOpenSE,
        onCloseSE,
        onOpenSE,
        isOpenVTE,
        onCloseVTE,
        isOpenITE,
        onCloseITE,
        videoCaptionOption,
        handleCaptionOption,
        videoSlideOption,
        handleSlideOption,
        disabledOptions,
        selectedKeys,
        setSelectedKeys,
        selectedValue,
        setIsCheckedCustomBGM,
        isCheckedCustomBGM,
        filteredOwnBGMData,
    } = props;

    // context method
    const {

        // Others
        userData,
        appConfigData,
        configData,
        setRefetchToolsConfigData,

        // debounce saveConfig function
        debouncedSaveConfig,

    } = useContext(UserContext);

    // extracted values
    const email = userData?.UserInfo?.email;
    const accuracyOwnAPI = false;
    const avatarOwnAPI = true;
    // const voiceOwnAPI = getCachedToolsConfiguration("ownElevenLabsAPI", configData)?.config === "true"; // own eleven labs API key
    const voiceOwnAPI = true;

    // use refs, navigation and location
    const audioRef = useRef(null);
    const voiceRef = useRef(null);
    const APIWarningRef = useRef(null);
    const fileRef = useRef(null);
    const location = useLocation();
    const modalCustomVideoThemeEditor = useRef(null);
    const modalCustomImageThemeEditor = useRef(null);

    // appConfigData extracted values
    const config_defaultPrice = appConfigData[0]?.default;
    const config_default_image = appConfigData[0]?.default_image;
    const config_default_voice = appConfigData[0]?.default_voice;
    const config_accuracy = appConfigData[0]?.accuracy;
    const config_caption = appConfigData[0]?.caption;
    const config_animation = appConfigData[0]?.animation;
    const config_avatar = appConfigData[0]?.avatar;
    const config_voice = appConfigData[0]?.voice;
    const config_language = appConfigData[0]?.language;
    const config_image = appConfigData[0]?.image;

    // path name
    const { pathname } = location;

    // react components
    const [isCheckedAccuracy, setIsCheckedAccuracy] = useState(
        getCachedToolsConfiguration("accuracy", configData)?.config === "true"
    );
    const [isCheckedCaption, setIsCheckedCaption] = useState(
        getCachedToolsConfiguration("caption", configData)?.config === "true"
    );
    const [isCheckedEffect, setIsCheckedEffect] = useState(
        getCachedToolsConfiguration("effect", configData)?.config === "true"
    );
    const [isCheckedCustomVoice, setIsCheckedCustomVoice] = useState(
        getCachedToolsConfiguration("CustomVoice", configData)?.config === "true"
    );
    const [isCheckedCustomLanguage, setIsCheckedCustomLanguage] = useState(
        getCachedToolsConfiguration("language", configData)?.config === "true"
    );

    // console log activity
    // consoleLog("imageTheme", imageTheme);

    const [isMidjourneyAPIKeyOkay, setIsMidjourneyAPIKeyOkay] = useState(localStorage?.getItem("isMidjourneyAPIKeyOkay") === "true");
    const [isAvatarAPIKeyOkay, setIsAvatarAPIKeyOkay] = useState(localStorage?.getItem("isAvatarAPIKeyOkay") === "true");
    const [isVoiceAPIKeyOkay, setIsVoiceAPIKeyOkay] = useState(localStorage?.getItem("isVoiceAPIKeyOkay") === "true");

    const [isCheckedAvatar, setIsCheckedAvatar] = useState("");
    const [showShowElevenLabsAPIKEy, setShowShowElevenLabsAPIKEy] = useState(false);
    const [showShowHeyGenAPIKEy, setShowShowHeyGenAPIKEy] = useState(false);
    const [showShowMidjourneyAPIKEy, setShowShowMidjourneyAPIKEy] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [inputKey, setInputKey] = useState(Math.random().toString(36));
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [avatars, setAvatars] = useState(avatarList);
    const [talkingPhoto, setTalkingPhoto] = useState(talkingPhotoList);
    const [isAvatarShow, setIsAvatarShow] = useState(false);
    const [isImageShow, setIsImageShow] = useState(false);
    const [isMidjourneyAPIKeyChecking, setIsMidjourneyAPIKeyChecking] = useState(false);
    const [isAvatarAPIKeyChecking, setIsAvatarAPIKeyChecking] = useState(false);
    const [isVoiceAPIKeyChecking, setIsVoiceAPIKeyChecking] = useState(false);
    const [advancedOptionTitle, setAdvancedOptionTitle] = useState("");
    const [debouncedElevenLabs, setDebouncedElevenLabs] = useState(elevenLabsAPIKey);
    const [isPlayingVoice, setIsPlayingVoice] = useState(false);
    const [normal_image_display, setNormal_image_display] = useState(false);
    const [advance_image_display, setAdvance_image_display] = useState(false);
    const [ownAPI_image_display, setOwnAPI_image_display] = useState(false);
    const [normal_voice_display, setNormal_voice_display] = useState(false);
    const [advance_voice_display, setAdvance_voice_display] = useState(false);
    const [ownAPI_voice_display, setOwnAPI_voice_display] = useState(false);
    const [showFeatureInfo, setShowFeatureInfo] = useState(false);

    // console log activity
    // consoleLog("appConfigData", appConfigData);

    // credit computations
    useEffect(() => {
        let value = config_defaultPrice; // Create video calculation
        let customValue = config_defaultPrice; // custom video calculation
        let createValue = config_defaultPrice;
        let customAudio = 0;

        // Accuracy Calculations
        // if (isCheckedAccuracy && !accuracyOwnAPI) value += config_accuracy;

        // default_image Calculations
        if ((!isCheckedAccuracy && accuracyOwnAPI) || (!isCheckedAccuracy && !accuracyOwnAPI)) {
            value += config_default_image;
            setNormal_image_display(true);
        } else {
            setNormal_image_display(false);
        }
        // Advance Accuracy Calculations
        if (isCheckedAccuracy && !accuracyOwnAPI) {
            setAdvance_image_display(true);
        } else {
            setAdvance_image_display(false);
        }
        // Own API Accuracy Calculations
        if (isCheckedAccuracy && accuracyOwnAPI) {
            setOwnAPI_image_display(true);
        } else {
            setOwnAPI_image_display(false);
        }
        // Caption Calculations
        if (isCheckedCaption) {
            value += config_caption;
            createValue += config_caption;
        }
        // Animation Calculations
        if (isCheckedEffect) {
            value += config_animation;
            createValue += config_animation;
        }
        // Avatar Calculations
        if (isCheckedAvatar && !avatarOwnAPI) {
            value += config_avatar;
            createValue += config_avatar;
        }

        // Voice Calculations
        if (isCheckedCustomVoice && !voiceOwnAPI) {
            value += config_voice;
            customValue += config_voice;
            // createValue += config_voice;
            customAudio += config_voice;
        };
        // default_voice Calculations
        if ((!isCheckedCustomVoice && voiceOwnAPI) || (!isCheckedCustomVoice && !voiceOwnAPI)) {
            value += config_default_voice;
            customValue += config_default_voice;
            // createValue += config_default_voice;
            setNormal_voice_display(true);
        } else {
            setNormal_voice_display(false);
        };
        // Advance Voice Calculations
        if (isCheckedCustomVoice && !voiceOwnAPI) {
            setAdvance_voice_display(true);
        } else {
            setAdvance_voice_display(false);
        }
        // Own API Voice Calculations
        if (isCheckedCustomVoice && voiceOwnAPI) {
            setOwnAPI_voice_display(true);
        } else {
            setOwnAPI_voice_display(false);
        }

        // Language Calculations
        if (isCheckedCustomVoice && isCheckedCustomLanguage && !voiceOwnAPI) {
            value += config_language;
            customValue += config_language;
            customAudio += config_language;
            // createValue += config_language;
        };

        setCreditCalculation(value);
        setCustomCreditCalculation(customValue);
        setCreateCreditCalculation(createValue);
        setCustomAudioCreditCalculation(customAudio);
    }, [
        isCheckedAccuracy,
        isCheckedCaption,
        isCheckedEffect,
        isCheckedAvatar,
        isCheckedCustomVoice,
        isCheckedCustomLanguage
    ]);

    // display for generation calculations
    const cost_display_create_video = [
        { data: `Normal Images ${formatNumber(config_default_image)} Credit(s).`, condition: !normal_image_display && "hidden" }, // normal_image_display
        { data: `Advance Images ${formatNumber(config_accuracy)} Credit(s) per image.`, condition: !advance_image_display && "hidden" }, // advance_image_display
        { data: `Own Image API 0 Credit(s).`, condition: !ownAPI_image_display && "hidden" }, // ownAPI_image_display
        { data: `Normal Voice ${formatNumber(config_default_voice)} Credit(s).`, condition: !normal_voice_display && "hidden" }, // normal_voice_display
        { data: `Advance Voice ${formatNumber(config_voice)} Credit(s).`, condition: !advance_voice_display && "hidden" }, // advance_voice_display
        { data: `Own Voice API 0 Credit(s).`, condition: !ownAPI_voice_display && "hidden" }, // ownAPI_voice_display
        { data: `Caption ${formatNumber(config_caption)} Credit(s).`, condition: !isCheckedCaption && "hidden" }, // caption_display
        { data: `Animation ${formatNumber(config_animation)} Credit(s).`, condition: !isCheckedEffect && "hidden" }, // effect_display
        { data: `Language ${formatNumber(config_language)} Credit(s).`, condition: (!isCheckedCustomLanguage || !advance_voice_display) && "hidden" }, // language_display
        { data: `Service Fee ${formatNumber(config_defaultPrice)} Credit(s).`, condition: "" }, // service_fee
    ];

    const cost_display_custom_video = [
        { data: `Normal Images`, condition: !normal_image_display && "hidden" }, // custom_image_accuracy_disabled
        { data: `Advance Images`, condition: !advance_image_display && "hidden" }, // custom_image_accuracy_enable
        { data: `Advance Images`, condition: !ownAPI_image_display && "hidden" }, // custom_image_accuracy_enable
        { data: `Normal Voice ${formatNumber(config_default_voice)} Credit(s).`, condition: !normal_voice_display && "hidden" }, // normal_voice_display
        { data: `Advance Voice ${formatNumber(config_voice)} Credit(s).`, condition: !advance_voice_display && "hidden" }, // advance_voice_display
        { data: `Own Voice API 0 Credit(s).`, condition: !ownAPI_voice_display && "hidden" }, // ownAPI_voice_display
        { data: `Language ${formatNumber(config_language)} Credit(s).`, condition: (!isCheckedCustomLanguage || !advance_voice_display) && "hidden" }, // language_display
        { data: `Service Fee ${formatNumber(config_defaultPrice)} Credit(s).`, condition: "" }, // service_fee
    ];

    // set advance option side nav title
    useEffect(() => {
        setAdvancedOptionTitle(pathname);
        switch (pathname) {
            case "/general-purpose":
                setAdvancedOptionTitle("General Purpose");
                break;
            case "/enhancer":
                setAdvancedOptionTitle("Video Enhancer");
                break;
        }
    }, []);

    // toggle play selected background music
    const togglePlay = () => {
        if (isPlaying) {
            audioRef?.current?.pause();
        } else {
            audioRef?.current?.play();
        }
        setIsPlaying(!isPlaying);
    };

    // toggle play selected voice
    const togglePlayVoice = () => {
        if (voicePreview !== "null") {
            if (isPlayingVoice) {
                voiceRef?.current?.pause();
            } else {
                voiceRef?.current?.play();
            }

            setIsPlayingVoice(!isPlayingVoice);
        }
    };

    // preview selected background music
    useEffect(() => {
        // This is a function to clean up the audio: stop it, remove listeners, and reset the ref.
        const cleanupAudio = () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                audioRef.current.removeEventListener("ended", handleEnd);
                setIsPlaying(false);
                audioRef.current = null;
            }
        };

        // Define the handleEnd function
        const handleEnd = () => setIsPlaying(false);

        // Clean up the previous audio instance before creating a new one.
        cleanupAudio();

        if (backgroundMusic) {
            audioRef.current = new Audio(
                `${process.env.REACT_APP_API_END_POINT}/music/${backgroundMusic}.mp3`
            );
            audioRef.current.addEventListener("ended", handleEnd);

            return () => {
                // Cleanup logic when component unmounts or backgroundMusic changes
                cleanupAudio();
            };
        }
    }, [backgroundMusic]);

    // preview selected voice
    useEffect(() => {
        // This is a function to clean up the audio: stop it, remove listeners, and reset the ref.
        const cleanupAudio = () => {
            if (voiceRef.current) {
                voiceRef.current.pause();
                voiceRef.current.currentTime = 0;
                voiceRef.current.removeEventListener("ended", handleEnd);
                setIsPlayingVoice(false);
                voiceRef.current = null;
            }
        };

        // Define the handleEnd function
        const handleEnd = () => setIsPlayingVoice(false);

        // Clean up the previous audio instance before creating a new one.
        cleanupAudio();

        if (voicePreview) {
            voiceRef.current = new Audio(voicePreview);
            voiceRef.current.addEventListener("ended", handleEnd);

            return () => {
                // Cleanup logic when component unmounts or backgroundMusic changes
                cleanupAudio();
            };
        }
    }, [voicePreview]);

    // handle show elevenlabs API
    const handleShowelevenLabsAPI = () => {
        if (showShowElevenLabsAPIKEy) {
            setShowShowElevenLabsAPIKEy(false);
        } else {
            setShowShowElevenLabsAPIKEy(true);
        }
    };

    // handle show heygen API
    const handleShowHeyGenAPI = () => {
        if (showShowHeyGenAPIKEy) {
            setShowShowHeyGenAPIKEy(false);
        } else {
            setShowShowHeyGenAPIKEy(true);
        }
    };

    // handle toggle slide accuracy function
    const handleToggleAccuracy = async () => {

        // toggle value and variables
        const toggleValue = !isCheckedAccuracy;
        const kind = "accuracy";

        // toggle button
        setIsCheckedAccuracy(toggleValue);

        // cached config to localstorage
        cachedToolsConfiguration(kind, toggleValue);

        // Call the debounced function
        debouncedSaveConfig(kind, email, pathname, kind, toggleValue);

    };

    // handle toggle video caption function
    const handleToggleCaption = async () => {

        // toggle value and variables
        const toggleValue = !isCheckedCaption;
        const kind = "caption";

        // toggle button
        setIsCheckedCaption(toggleValue);

        // cached config to localstorage
        cachedToolsConfiguration(kind, toggleValue);

        // Call the debounced function
        debouncedSaveConfig(kind, email, pathname, kind, toggleValue);

    };

    // handle toggle slide animation function
    const handleToggleEffects = async () => {

        // toggle value and variables
        const toggleValue = !isCheckedEffect;
        const kind = "effect";

        // toggle button
        setIsCheckedEffect(toggleValue);

        // cached config to localstorage
        cachedToolsConfiguration(kind, toggleValue);

        // Call the debounced function
        debouncedSaveConfig(kind, email, pathname, kind, toggleValue);

    };

    // handle toggle custom voice
    const handleToggleCustomVoice = async () => {

        // toggle value and variables
        const toggleValue = !isCheckedCustomVoice;
        const kind = "CustomVoice";

        // toggle button
        setIsCheckedCustomVoice(toggleValue); // toggle button

        // cached config to localstorage
        cachedToolsConfiguration(kind, toggleValue);

        // Call the debounced function
        debouncedSaveConfig(kind, email, pathname, kind, toggleValue);

    };

    // handle toggle custom BGM
    const handleToggleCustomBGM = async () => {

        // toggle value and variables
        const toggleValue = !isCheckedCustomBGM;
        const kind = "CustomBGM";

        // toggle button
        setIsCheckedCustomBGM(toggleValue); // toggle button

        // cached config to localstorage
        cachedToolsConfiguration(kind, toggleValue);

        // Call the debounced function
        debouncedSaveConfig(kind, email, pathname, kind, toggleValue);

    };

    // handle custom language
    const handleCustomLanguage = () => {

        // toggle value and variables
        const toggleValue = !isCheckedCustomLanguage;
        const kind = "language";

        // toggle button
        setIsCheckedCustomLanguage(toggleValue);

        // cached config to localstorage
        cachedToolsConfiguration(kind, toggleValue);

        // Call the debounced function
        debouncedSaveConfig(kind, email, pathname, kind, toggleValue);

    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedElevenLabs(elevenLabsAPIKey);
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, [elevenLabsAPIKey]);

    // fetch custom voice model
    useEffect(() => {
        handleAPIKeyOnChange(debouncedElevenLabs);
        if (elevenLabsAPIKey && isCheckedCustomVoice && voiceOwnAPI) {
            safeFetchCustomVoiceModel(elevenLabsAPIKey, setElvenLabsAPIValidity)
                .then((res) => {
                    setVoiceModelList(res?.data?.voices);
                });
        }
        // else {
        //     fetchDefaultVoiceModel()
        //         .then((response) => {
        //             setElvenLabsAPIValidity({});
        //             setVoiceModelList(response?.data?.voices);
        //         });
        // }
    }, [debouncedElevenLabs]);

    // handle own eleven labs API key
    const handleAPIKeyOnChange = (value) => {

        if (!value) { return; } // check if no value
        if (!email) { return; } // check if no email

        // variables
        const kind = "ElevenLabsAPIKey";

        // cached config to localstorage
        cachedToolsConfiguration(kind, value);

        // Call the debounced function
        debouncedSaveConfig(kind, email, pathname, kind, value);

    };

    // handle text script on change
    const handleTextScriptOnChange = (event) => {

        // toggle value and variables
        const inputValue = event?.target?.value; // extracted value
        const kind_local_storage = "userScriptContent";
        const kind_config = "script";

        // set value
        setTextScript(inputValue); // set value in useState to be usable

        // cached config to localstorage
        cachedToolsConfiguration(kind_local_storage, inputValue);

        // triggers modal to auto open when maximum text reach its limit
        autoOpenModalPromptEditor(inputValue, onOpenSE);

        // save inputs Idea or Own script to the database
        debouncedSaveConfig(kind_config, email, pathname, kind_config, inputValue);

    };

    // hadnle bas
    const handleTabs = (tab) => {
        setTab(tab);
        localStorage.setItem(`AOT_${pathname}`, tab);
    };

    // handle model list
    useEffect(() => {
        setVoiceModelList(voiceModels);
    }, [voiceModels]);

    // toggle button extentions
    useEffect(() => {
        if (isCheckedEffect) {
            setAddAnimation(1);
        } else {
            setAddAnimation(0);
        }

        if (isCheckedAccuracy) {
            setEnableAccuracy(1);
        } else {
            setEnableAccuracy(0);
        }

        if (isCheckedCustomVoice) {
            setEnableVoice(1);
        } else {
            setEnableVoice(0);
        }

        if (isCheckedCaption) {
            setEnableCaption(1);
        } else {
            setEnableCaption(0);
        }

        if (isCheckedAvatar) {
            setEnableAvatar(1);
        } else {
            setEnableAvatar(0);
        }

        if (isCheckedCustomLanguage) {
            setEnableLanguange(1);
        } else {
            setEnableLanguange(0);
        }
    }, [
        isCheckedEffect,
        isCheckedAccuracy,
        isCheckedCaption,
        isCheckedAvatar,
        isCheckedCustomVoice,
        isCheckedCustomLanguage,
        // isCheckedAvatarOption,
    ]);

    // handle check eleven labs voice API
    const handleCheckVoiceAPI = async () => {

        if (!elevenLabsAPIKey) {
            ToastEffect("error", "No API key provided");
            return;
        }

        setIsVoiceAPIKeyChecking(true);
        await axios.post(`${process.env.REACT_APP_ELEVENLAB_API}/text-to-speech/21m00Tcm4TlvDq8ikWAM/stream?optimize_streaming_latency=0`, {
            text: "string",
            model_id: "eleven_monolingual_v1",
            voice_settings: {
                stability: 0,
                similarity_boost: 0,
                style: 0,
                use_speaker_boost: true,
            },
        }, {
            headers: {
                accept: "*/*",
                "xi-api-key": elevenLabsAPIKey,
                "Content-Type": "application/json",
            },
        }).then((response) => {
            consoleLog(
                "AdvancedOptions > handleCheckVoiceAPI > response",
                response
            );
            if (response?.status === 200) {
                setIsVoiceAPIKeyOkay(true);
                localStorage.setItem("isVoiceAPIKeyOkay", !isVoiceAPIKeyOkay);
                setIsVoiceAPIKeyChecking(false);
                ToastEffect("success", "ElevenLabs API key is working.");
            }
        }).catch((err) => {
            setIsVoiceAPIKeyChecking(false);
            consoleLog("AdvancedOptions > handleCheckVoiceAPI > error", err);
            if (err?.response?.data?.detail) {
                ToastEffect("error", err?.response?.data?.detail?.message);
            } else {
                ToastEffect("error", "Something went wrong!");
            }
        });
    };

    return (
        <div className={`lg:flex containerDiv fontMontserrat ${isLoadingVoice && "blurEffect"}`}>
            <div className="w-full max-w-full">
                {/* loading when getting voice models */}
                {isLoadingVoice && (
                    <div className="loadingSpinner">
                        <i className="fa-solid fa-spinner fa-spin fa-2xl"></i>
                    </div>
                )}

                {/* Title */}
                <div className="divTitle pb-[30px] text-gray-700 dark:text-gray-100">
                    <h1 className="fontMontserrat">{advancedOptionTitle}</h1>
                    <p className="fontKarla">
                        {pathname === "/general-purpose" ? "Generate viral videos about anything!" : "Let's you generate script from shorts/reels for your AI Video!"}
                    </p>
                </div>

                <div className="pb-0">
                    <div>
                        <GeneralPurposeForm
                            inputValue={userIdea}
                            handleOnChange={handleUserIdea}
                            isError={isFieldError}
                            isMobile={isMobile}
                            configData={configData}
                            email={email}
                            pathname={pathname}
                            textScriptValue={textScriptValue}
                            handleTextScriptOnChange={handleTextScriptOnChange}
                            promptKind={promptKind}
                            setPromptKind={setPromptKind}
                            setRefetchToolsConfigData={setRefetchToolsConfigData}
                            isOpenSE={isOpenSE}
                            onCloseSE={onCloseSE}
                            onOpenSE={onOpenSE}
                        />
                    </div>

                    <div>
                        <Button
                            isLoading={loading}
                            radius="full"
                            disabled={loading || disabledOptions?.AO_GenerateButton ? true : false}
                            onClick={() => alertBeforeAction(generateVideo, generateCustomVideo, "Generate-Video", { creditCalculation, customCreditCalculation })}
                            className="w-full flex items-center justify-center text-white hover:bg-gradient-to-r from-purple-400 via-purple-600 to-purple-700 bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-purple-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                        >
                            <span className="w-full text-center">
                                {loading ? (
                                    <span className="font-semibold">
                                        <i className="fa-solid fa-clapperboard fa-bounce mr-2.5 text-lg" />
                                        Processing...
                                    </span>
                                ) : (
                                    <span className="font-semibold">
                                        <i className="fa-solid fa-video mr-2.5 text-lg" />
                                        Generate Video
                                    </span>
                                )}

                                <span className="float-left font-semibold">

                                    <Tooltip
                                        color="default"
                                        placement="top"
                                        showArrow={true}
                                        content={
                                            <div className="px-1 py-2">
                                                <div className="text-small font-bold mb-1">Create Video <i className="fa-solid fa-coins text-yellow-300" /> {formatNumber(creditCalculation)}</div>
                                                {cost_display_create_video?.map((item, idx) => {
                                                    return (
                                                        <p key={idx} className={`break-words whitespace-pre-line ${item?.condition}`}>
                                                            <li>{item?.data}</li>
                                                        </p>
                                                    );
                                                })}

                                                <div className="text-small font-bold mt-1">Custom Video <i className="fa-solid fa-coins text-yellow-300" /> {formatNumber(customCreditCalculation)}</div>
                                                {cost_display_custom_video?.map((item, idx) => {
                                                    return (
                                                        <p key={idx} className={`break-words whitespace-pre-line ${item?.condition}`}>
                                                            <li>{item?.data}</li>
                                                        </p>
                                                    );
                                                })}
                                            </div>
                                        }>
                                        <i className="fa-solid fa-circle-question text-yellow-300 text-xl hover:text-yellow-400" />
                                    </Tooltip>

                                </span>
                            </span>
                        </Button>
                        {/* <button
                            disabled={loading || disabledOptions?.AO_GenerateButton ? true : false}
                            onClick={() => alertBeforeAction(generateVideo, generateCustomVideo, "Generate-Video", { creditCalculation, customCreditCalculation })}
                            className="w-full flex items-center justify-center text-white hover:bg-gradient-to-r from-purple-400 via-purple-600 to-purple-700 bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-purple-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                        >
                            <span className="w-full text-center">
                                {loading ? (
                                    <span className="font-semibold">
                                        <i className="fa-solid fa-clapperboard fa-bounce mr-2.5 text-lg" />
                                        Processing...
                                    </span>
                                ) : (
                                    <>
                                        <span className="font-semibold">
                                            <i className="fa-solid fa-video mr-2.5 text-lg" />
                                            Generate Video
                                        </span>

                                        <span className="float-right font-semibold">
                                            <i className="fa-solid fa-coins text-lg" /> {formatNumber(creditCalculation)}
                                        </span>
                                    </>
                                )}

                                <span className="float-right font-semibold">
                                    {loading && (
                                        <i className="fa-solid fa-circle-notch fa-spin left-4 text-lg" />
                                    )}
                                </span>

                                <span className="float-left font-semibold">

                                    <Tooltip
                                        color="default"
                                        placement="top"
                                        showArrow={true}
                                        content={
                                            <div className="px-1 py-2">
                                                <div className="text-small font-bold mb-1">Create Video <i className="fa-solid fa-coins text-yellow-300" /> {formatNumber(creditCalculation)}</div>
                                                {cost_display_create_video?.map((item, idx) => {
                                                    return (
                                                        <p key={idx} className={`break-words whitespace-pre-line ${item?.condition}`}>
                                                            <li>{item?.data}</li>
                                                        </p>
                                                    );
                                                })}

                                                <div className="text-small font-bold mt-1">Custom Video <i className="fa-solid fa-coins text-yellow-300" /> {formatNumber(customCreditCalculation)}</div>
                                                {cost_display_custom_video?.map((item, idx) => {
                                                    return (
                                                        <p key={idx} className={`break-words whitespace-pre-line ${item?.condition}`}>
                                                            <li>{item?.data}</li>
                                                        </p>
                                                    );
                                                })}
                                            </div>
                                        }>
                                        <i className="fa-solid fa-circle-question text-yellow-300 text-xl hover:text-yellow-400" />
                                    </Tooltip>

                                </span>
                            </span>
                        </button> */}
                    </div>
                </div>

                <div className="sectionDivider mb-1">
                    <hr />
                </div>

                <>

                    <div className="flex w-full flex-col mb-1">
                        <Tabs
                            onSelectionChange={handleTabs}
                            selectedKey={tab}
                            aria-label="Options"
                            color="primary"
                            variant="underlined"
                            classNames={{
                                tabList: "gap-6 w-full relative rounded-none p-0 border-b border-divider",
                                cursor: "w-full bg-[#22d3ee]",
                                tab: "max-w-fit px-0 h-12",
                                tabContent: "group-data-[selected=true]:text-[#06b6d4]"
                            }}
                        >
                            <Tab
                                isDisabled={disabledOptions?.AO_TabVideo}
                                key="tab1"
                                title={
                                    <div className="flex items-center space-x-2">
                                        <i className="fa-solid fa-video" />
                                        <span>Video</span>
                                        <Chip size="sm" variant="faded">7</Chip>
                                    </div>
                                }
                            >
                                {/* Video Settings */}
                                <AO_tab1
                                    isCheckedAccuracy={isCheckedAccuracy}
                                    handleToggleAccuracy={handleToggleAccuracy}
                                    config_image={config_image}
                                    isCheckedCaption={isCheckedCaption}
                                    handleToggleCaption={handleToggleCaption}
                                    config_caption={config_caption}
                                    isCheckedEffect={isCheckedEffect}
                                    handleToggleEffects={handleToggleEffects}
                                    config_animation={config_animation}
                                    videoAspectRatio={videoAspectRatio}
                                    handleVideoAspectRatio={handleVideoAspectRatio}
                                    imageTheme={imageTheme}
                                    handleImageTheme={handleImageTheme}
                                    handleEditCustomImageTheme={handleEditCustomImageTheme}
                                    promptKind={promptKind}
                                    videoTheme={videoTheme}
                                    handleVideoTheme={handleVideoTheme}
                                    handleEditCustomVideoTheme={handleEditCustomVideoTheme}
                                    videoCaptionOption={videoCaptionOption}
                                    handleCaptionOption={handleCaptionOption}
                                    videoSlideOption={videoSlideOption}
                                    handleSlideOption={handleSlideOption}
                                    disabledOptions={disabledOptions}
                                />
                            </Tab>
                            {isCheckedAvatar && avatarOwnAPI && (
                                <Tab
                                    key="tab2"
                                    title={
                                        <div className="flex items-center space-x-2">
                                            <i className="fa-solid fa-user-tie" />
                                            <span>Avatar</span>
                                            <Chip size="sm" variant="faded">0</Chip>
                                        </div>
                                    }
                                >
                                    {/* Avatar */}
                                    <AO_tab2
                                        config_avatar={config_avatar}
                                        isCheckedAvatar={isCheckedAvatar}
                                        avatarOwnAPI={avatarOwnAPI}
                                        setHeyGenAPIKey={setHeyGenAPIKey}
                                        heyGenAPIKey={heyGenAPIKey}
                                        showShowHeyGenAPIKEy={showShowHeyGenAPIKEy}
                                        isAPIKeyAvatarErr={isAPIKeyAvatarErr}
                                        handleShowHeyGenAPI={handleShowHeyGenAPI}
                                        isAvatarAPIKeyOkay={isAvatarAPIKeyOkay}
                                        isAvatarAPIKeyChecking={isAvatarAPIKeyChecking}
                                        avatarOption={avatarOption}
                                        onSelecTalkingPhoto={onSelecTalkingPhoto}
                                        selectedTalkingPhoto={selectedTalkingPhoto}
                                        selectedTalkingPhotoURL={selectedTalkingPhotoURL}
                                        talkingPhoto={talkingPhoto}
                                        isFileUploading={isFileUploading}
                                        isImageShow={isImageShow}
                                        inputKey={inputKey}
                                        fileRef={fileRef}
                                        handleSelectedAvatar={handleSelectedAvatar}
                                        avatarId={avatarId}
                                        avatarURL={avatarURL}
                                        avatars={avatars}
                                        isAvatarShow={isAvatarShow}
                                    />
                                </Tab>
                            )}
                            <Tab
                                isDisabled={disabledOptions?.AO_TabVoice}
                                key="tab3"
                                title={
                                    <div className="flex items-center space-x-2">
                                        <i className="fa-solid fa-microphone" />
                                        <span>Voice</span>
                                        <Chip size="sm" variant="faded">4</Chip>
                                    </div>
                                }
                            >
                                {/* Own Voice */}
                                <AO_tab3
                                    config_voice={config_voice}
                                    handleToggleCustomVoice={handleToggleCustomVoice}
                                    isCheckedCustomVoice={isCheckedCustomVoice}
                                    voiceOwnAPI={voiceOwnAPI}
                                    setElevenLabsAPIKey={setElevenLabsAPIKey}
                                    elevenLabsAPIKey={elevenLabsAPIKey}
                                    showShowElevenLabsAPIKEy={showShowElevenLabsAPIKEy}
                                    handleShowelevenLabsAPI={handleShowelevenLabsAPI}
                                    handleDefaultVoice={handleDefaultVoice}
                                    defaultVoiceName={defaultVoiceName}
                                    onSelectedVoice={onSelectedVoice}
                                    voiceId={voiceId}
                                    voicePreview={voicePreview}
                                    voiceModelList={voiceModelList}
                                    togglePlayVoice={togglePlayVoice}
                                    isPlayingVoice={isPlayingVoice}
                                    config_language={config_language}
                                    handleCustomLanguage={handleCustomLanguage}
                                    isCheckedCustomLanguage={isCheckedCustomLanguage}
                                    onSelectedLanguage={onSelectedLanguage}
                                    languageID={languageID}
                                    languageName={languageName}
                                    languageList={languageList}
                                />
                            </Tab>
                            <Tab
                                isDisabled={disabledOptions?.AO_TabMusic}
                                key="tab5"
                                title={
                                    <div className="flex items-center space-x-2">
                                        <i className="fa-solid fa-music" />
                                        <span>Music</span>
                                        <Chip size="sm" variant="faded">1</Chip>
                                    </div>
                                }
                            >
                                {/* Background Music */}
                                <AO_tab5
                                    onSelectBackgroundMusic={onSelectBackgroundMusic}
                                    backgroundMusic={backgroundMusic}
                                    togglePlay={togglePlay}
                                    isPlaying={isPlaying}
                                    handleToggleCustomBGM={handleToggleCustomBGM}
                                    isCheckedCustomBGM={isCheckedCustomBGM}
                                    selectedKeys={selectedKeys}
                                    setSelectedKeys={setSelectedKeys}
                                    selectedValue={selectedValue}
                                    filteredOwnBGMData={filteredOwnBGMData}
                                />
                            </Tab>
                        </Tabs>
                    </div>
                </>
            </div>

            {/* Custom Video Theme Editor */}
            <CustomThemeEditor
                isOpen={isOpenVTE}
                onClose={onCloseVTE}
                handleThemePrompt={handleCustomVideoThemePrompt}
                handleSaveThemePrompt={handleSaveCustomVideoThemePrompt}
                themePrompt={customVideoThemePrompt}
                savingThemePrompt={savingCustomVideoThemePrompt}
                modalKind="videoThemeEditor"
            />

            {/* Custom Image Theme Editor */}
            <CustomThemeEditor
                isOpen={isOpenITE}
                onClose={onCloseITE}
                handleThemePrompt={handleCustomImageThemePrompt}
                handleSaveThemePrompt={handleSaveCustomImageThemePrompt}
                themePrompt={customImageThemePrompt}
                savingThemePrompt={savingCustomImageThemePrompt}
                modalKind="imageThemeEditor"
            />

        </div>
    );
};

export default AdvancedOptions;
