// libraries
import React, { useState } from "react";
import { Switch, cn, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button } from "@nextui-org/react";

// custom components
import consoleLog from "../../../../../../functions/consoleLog";

const HeadNav = (props) => {

    // props
    const {
        isCaptionEnabled,
        setIsCaptionEnabled,
        onOpenEM,
        onOpenPC,
        setVideoContentData,
        video,
        artisanSession,
    } = props;

    // react useState
    const [selectedItem, setSelectedItem] = useState("");

    // console log activity
    // consoleLog("artisanSession", artisanSession);

    // handle selected item in advance menu
    const handleEditVideo = () => {

        // chek if content is available
        if (!video) { return; }

        // open modal edit
        onOpenEM();

    };

    // handle post content
    const handlePostContent = () => {

        // chek if content is available
        if (!video) { return; }

        // open post content modal
        onOpenPC();

        // set video link to be posted
        setVideoContentData({ link: video, data: artisanSession });

    };

    return (
        <div className="relative bg-white shadow-md dark:bg-gray-700/40 sm:rounded-lg lg:min-w-[42rem]">
            <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                {/* <div className="w-full md:w-1/2">
                    <form className="flex items-center">
                        <label for="simple-search" className="sr-only">Search</label>
                        <div className="relative w-full">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input type="text" id="simple-search" className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search" required="" />
                        </div>
                    </form>
                </div> */}

                <Dropdown className="dark:bg-gray-900">
                    <DropdownTrigger>
                        <Button
                            variant="bordered"
                            className="w-full lg:w-auto"
                        >
                            Advance Option
                        </Button>
                    </DropdownTrigger>
                    <DropdownMenu aria-label="Advance Option">
                        <DropdownItem
                            key="edit"
                            onClick={handleEditVideo}
                        >
                            Edit Content
                        </DropdownItem>
                        {/* <DropdownItem
                            key="post"
                            onClick={handlePostContent}
                        >
                            Post Content
                        </DropdownItem> */}
                    </DropdownMenu>
                </Dropdown>

                <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
                    {/* <button type="button" className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                        <svg className="h-3.5 w-3.5 mr-2" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" />
                        </svg>
                        Add product
                    </button> */}
                    <Switch
                        isDisabled={!video ? true : false}
                        classNames={{
                            base: cn(
                                "inline-flex flex-row-reverse min-w-full bg-gray-100 hover:bg-gray-100/80 dark:bg-gray-900 dark:hover:bg-gray-900/80 items-center",
                                "justify-between cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                            ),
                            wrapper: "p-0 h-4 overflow-visible",
                            thumb: cn("w-6 h-6 border-2 shadow-lg",
                                "group-data-[hover=true]:border-primary",
                                //selected
                                "group-data-[selected=true]:ml-6",
                                // pressed
                                "group-data-[pressed=true]:w-7",
                                "group-data-[selected]:group-data-[pressed]:ml-4",
                            ),
                        }}
                        isSelected={isCaptionEnabled}
                        onValueChange={setIsCaptionEnabled}
                    >
                        <div className="flex flex-col gap-1">
                            <p className="text-medium">Enable Caption</p>
                            {/* <p className="text-tiny text-default-400">
                                        Toggle to enable or disable video captions.
                                    </p> */}
                        </div>
                    </Switch>
                    <div className="flex items-center w-full space-x-3 md:w-auto">
                        <div className="flex justify-between items-center">
                            {/* blank */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeadNav;