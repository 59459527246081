import React, { useContext, useState } from "react";
import MyPlan from "./MyPlan";
import Pricing from "./Pricing";
import UserContext from "../../components/UserContext";
import consoleLog from "../../functions/consoleLog";

const UserSubscription = () => {

    // context method
    const {

        // Others
        productData,
        subscriptionData,
        appConfigData,

    } = useContext(UserContext);

    // react components
    const [showPricingPlan, setShowPricingPlan] = useState(appConfigData[0]?.showPricingPlan);

    // console log activity
    // consoleLog("subscriptionData", subscriptionData);

    return (
        <div className="py-8 px-4 mx-auto max-w-screen-2xl lg:py-16 lg:px-6">

            {(subscriptionData?.message === "subscribed" || subscriptionData?.message === "cancelled" || subscriptionData?.message === "trial" || subscriptionData?.message === "expiredSubscription") && (
                <div className="w-auto bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    <MyPlan />
                </div>
            )}

            <div className={`${!productData?.length || !showPricingPlan && "hidden"} py-8 mx-auto`}>
                <Pricing />
            </div>

        </div>
    );
};

export default UserSubscription;