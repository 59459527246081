import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import ToolTipDisplay from "../ToolTip/ToolTipDisplay";
import { toolPrices } from "../../../../../Configs/toolPrices";
import { generateAudio } from "../../API/toolsAPI";
import { getCreditBalance, payVideo, recordCredits, recordRefundCredits, refundCredits, updateToolsConfig } from "../../../../../API/account";
// import ModalPreviewVideo from "./ModalPreviewVideo";
// import ErrorImage from "../../Images/ErrorImage.png";
import { adjustCreditsView } from "../../../../../functions/itemWithExpiry";
import { ToastEffect } from "../../../../../functions/ToastEffect";
import consoleLog from "../../../../../functions/consoleLog";
import { alertBeforeAction } from "./AlertBeforeAction";


const ModalEditorLeftSide = (props) => {

    const {
        functionRefundCredits,
        userConfig,
        email,
        isMobile,
        modalData,
        content,
        handleNewTitle,
        handleNewValue,
        setSelectedVoice,
        selectedVoice,
        voiceId,
        setVoiceId,
        voicePreview,
        setVoicePreview,
        toolsData,
        fetchData,
        audioFileName,
        modalPreviewVideo,
        showModalPreviewVideo,
        voiceModelList,
        elevanLabsAPIKey,
        subscriptionData,
        isOldUser,
        images,
        audioSrc
    } = props;

    const audioRef = useRef(null);

    const modalId = modalData?.id;
    const voiceOwnAPI = userConfig?.userConfig?.voice || false;

    const [isProcessing, setIsProcessing] = useState(false);

    // console.log("audioFileName", audioFileName);

    useEffect(() => {
        // If there's an audio file and the audio element exists, reload its source
        if (audioFileName && audioRef.current) {
            const newSource = `${process.env.REACT_APP_SHINEFY_SERVER}/file/data/${email}/${modalData?.id}/${audioFileName}`;
            audioRef.current.src = newSource; // Update the source directly
            audioRef.current.load(); // Reload the audio element
        }
    }, [audioFileName, email, modalData?.id]); // React to changes in audioFileName

    const calculationCost = (text) => {

        const characters = text.length;

        const cost = (characters / 100) * toolPrices("audio");

        const roundedNumber = parseFloat(cost.toFixed(2));
        const formattedNumber = roundedNumber.toLocaleString();

        return formattedNumber;
    };

    /** handle selected default voices */
    const handleSelectedVoice = async (e, kind) => {
        const value = e.target.value;
        setSelectedVoice(value);

        await updateToolsConfig(email, value, kind, modalData?.id);
        fetchData();
    };

    /** handle eleven labs selected voice */
    const handleSelectedElvenLabsVoice = async (e, kind) => {
        const [voiceID, voicePreview] = e.target.value.split("|");
        setVoiceId(voiceID);
        setVoicePreview(voicePreview);
        await updateToolsConfig(email, voiceID, kind, modalData?.id);
        await updateToolsConfig(email, voicePreview, "voicePreview", modalData?.id);
        
        // consoleLog("GeneralPurpose > index.js > handleSelectVoice", `"${voiceID} ${voicePreview}"`);
    };

    // const functionRefundCredits = async () => {
    //   if (subscriptionData?.message !== "freeAccess" && subscriptionData?.message !== "expiredSubscription") {
    //     await refundCredits(email, modalId)
    //       .then(async (value) => {
    //         if (value?.data?.message === "no subscription") { } else {
    //           await recordRefundCredits(email, modalId, "GenerateAudio", "refund", value?.data || 0);
    //           adjustCreditsView("tokenBalance", "refund", value?.data);
    //         }
    //       });
    //   }
    // };

    /** handle video generation function */
    // const handleGenerateAudio = async () => {

    //     const audioCost = calculationCost(content);

    //     setIsProcessing(true);

    //     // Check credit balance first
    //     if (subscriptionData?.message !== "freeAccess") {

    //         const ceditBalance = await getCreditBalance(email);

    //         // Extracting repeated variable calls
    //         const subscriptionCredits = ceditBalance?.data?.message?.subs || 0;
    //         const paidCredits = ceditBalance?.data?.message?.paid || 0;
    //         // const generalPurposePrice = paymentPrice[0]?.GeneralPurpose || 0;

    //         // Calculating the total credits and total price
    //         const totalCredits = (subscriptionCredits + paidCredits);

    //         // console.log(totalCredits, totalPrice);

    //         if (totalCredits < audioCost) {
    //             setIsProcessing(false);
    //             ToastEffect("error", "Not enough credits.");
    //             return;
    //         }
    //     }

    //     if (subscriptionData?.message === "freeAccess" || (voiceOwnAPI && voiceModelList?.length > 0)) {
    //         let paymentKind = "freeAccess";

    //         if (subscriptionData?.message === "subscribed" && isOldUser) {
    //             paymentKind = "OldUser";
    //         }

    //         await recordCredits(email, modalId, "VT_GenerateAudio", paymentKind, audioCost);
    //     } else {
    //         // pay video
    //         await payVideo(email, audioCost, "VT_GenerateAudio");

    //         // record payment
    //         await recordCredits(email, modalId, "VT_GenerateAudio", "payment", audioCost);
    //         adjustCreditsView("tokenBalance", "pay", audioCost);
    //     }

    //     await generateAudio(email, modalId, content, selectedVoice, "audio", voiceOwnAPI, elevanLabsAPIKey, voiceId)
    //         .then((response) => {
    //             fetchData();
    //             setIsProcessing(false);
    //             // console.log("response", response.data.message);
    //             // console.log("response", response?.data?.cost);
    //             if (response?.data?.message === "error generating audio!") {
    //                 // functionRefundCredits({ action: "VT_GenerateAudio", taskID: modalId });
    //                 ToastEffect("error", "Error generating audio!");
    //             }
    //         }).catch(async (error) => {
    //             // functionRefundCredits({ action: "VT_GenerateAudio", taskID: modalId });
    //             ToastEffect("error", "Error generating audio!");
    //             consoleLog("#generateAudio", error);
    //         });
    // };

    return (
        <>
            <div className="relative my-2"> {/* Input field with icon and spacing from textarea */}
                <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                    <i className="fa-solid fa-pen-to-square w-4 h-4 text-gray-500 dark:text-gray-400" />
                </div>
                <input
                    onChange={handleNewTitle}
                    type="text"
                    id="input-group-1"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder={modalData?.title ? modalData?.title : modalData?.task_id}
                />
            </div>
            <textarea
                onChange={handleNewValue}
                value={content}
                id="comment"
                rows="23"
                className="p-3 resize-none h-full text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                placeholder="Transcription..."
                required
                autoFocus
            />
            {/* <div>
                <audio controls>
                    <source src="http://localhost:3000/file/audio/test_audio.mp3" type="audio/mp3" />
                    Your browser does not support the audio element.
                </audio>
            </div> */}

            {/* <div className="flex items-center px-2 py-2 rounded-lg bg-gray-50 dark:bg-gray-700">
                <button type="button" className="p-2 text-gray-500 rounded-lg cursor-help hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                    <i className="fa-solid fa-circle-question text-2xl" />
                    <span className="sr-only">Add emoji</span>
                </button>
                <audio className="w-full" controls>
                    <source src="http://localhost:3000/file/audio/test_audio.mp3" type="audio/mp3" />
                    Your browser does not support the audio element.
                </audio>
                <div className="ml-2">
                    <button
                        data-tooltip-id="tooltip-prompt"
                        data-tooltip-content="Generate audio"
                        type="button"
                        className="mr-1 px-3 py-2 font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                        <i className="fa-solid fa-retweet text-xl" />
                        <Tooltip id="tooltip-prompt" />
                    </button>
                </div>
            </div> */}

            {/* <div className="w-full mb-0 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700/40 dark:border-gray-600">
                <div className="px-4 py-2 rounded-t-lg dark:bg-gray-100">
                    <audio className="w-full" controls ref={audioRef}>
                        {audioFileName && (
                            <source
                                src={audioSrc}
                                type="audio/mp3"
                            />
                        )}
                        Your browser does not support the audio element.
                    </audio>
                </div>
                <div className="flex space-x-1 items-center justify-between px-3 py-2 border-t dark:border-gray-600">

                    <div className="float-right p-[0.15rem] text-gray-800">
                        <ToolTipDisplay
                            label={`
                            <p class="break-words whitespace-pre-line -mt-5 ${isMobile ? "w-48" : "w-72"}">
                                ${voiceOwnAPI ?
                                                    "• Using own API key cost 0 Credit(s)." :
                                                    `• Using this feature will cost you ${toolPrices("audio")} Credit(s) per 100 characters.`
                                                }

                                • Please note that all uploaded custom media files, such as images and videos, will not be stored permanently. They will be deleted from the database once the system restarts.
                            </p>
                            `}
                            id="transcriber-audio"
                            icon="fa-solid fa-circle-question text-gray-400 text-xl hover:text-gray-500"
                        />
                    </div>


                    <div
                        data-tooltip-id="tooltip-characters"
                        data-tooltip-content="Total characters"
                        type="button"
                        className="inline-flex justify-center items-center p-2 text-gray-500 rounded-lg hover:text-gray-900 bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:bg-gray-600"
                    >
                        {(content.length).toLocaleString()}
                        <Tooltip id="tooltip-characters" />
                    </div>

                    <div
                        data-tooltip-id="tooltip-cost"
                        data-tooltip-content="Total cost"
                        className="inline-flex justify-center items-center p-2 text-gray-500 rounded-lg hover:text-gray-900 bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:bg-gray-600"
                    >
                        <i className="fa-solid fa-coins mr-1" />
                        {calculationCost(content)}
                        <Tooltip id="tooltip-cost" />
                    </div>

                    {voiceModelList.length > 0 ? (
                        <select
                            id="voiceModel"
                            onChange={(e) => handleSelectedElvenLabsVoice(e, "voiceID")}
                            className="ml-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            value={`${voiceId}|${voicePreview}` || ""}
                        >
                            {voiceModelList?.length > 0 && voiceModelList.map((data, index) => (
                                <option
                                    value={`${data.voice_id}|${data.preview_url}`}
                                    key={index + 1}
                                >
                                    {data.name}{data?.labels?.accent && ` - ${data?.labels?.accent}`} {data?.labels?.gender && `(${data?.labels?.gender})`}
                                </option>
                            ))}
                        </select>
                    ) : (
                        <select
                            onChange={(e) => handleSelectedVoice(e, "voice")}
                            value={selectedVoice}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                            <option value="alloy">Alloy</option>
                            <option value="echo">Echo</option>
                            <option value="fable">Fable</option>
                            <option value="onyx">Onyx</option>
                            <option value="nova">Nova</option>
                            <option value="shimmer">Shimmer</option>
                        </select>
                    )}

                    <div className="flex">

                        <button
                            disabled={isProcessing ? true : false}
                            onClick={() => audioFileName ? alertBeforeAction(handleGenerateAudio, "function2", "re-generate-audio", {}) : handleGenerateAudio()}
                            data-tooltip-id="tooltip-audio"
                            data-tooltip-content="Generate Audio"
                            type="submit"
                            className="inline-flex items-center py-1.5 w-auto px-3 font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800"
                        >
                            {isProcessing ? (
                                <i className="fa-solid fa-sync fa-spin text-lg" />
                            ) : (
                                <i className="fa-solid fa-retweet text-lg" />
                            )}
                            <Tooltip id="tooltip-audio" />
                        </button>

                    </div>
                </div>
            </div> */}

            {/* {modalPreviewVideo && (
                <ModalPreviewVideo
                    images={images}
                    audioSrc={audioSrc}
                    modalData={modalData}
                    showModalPreviewVideo={showModalPreviewVideo}
                />
            )} */}

        </>
    );
};

export default ModalEditorLeftSide;