// libraries
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, CardFooter, Chip, CircularProgress, Input, Spinner, Textarea, Tooltip } from "@nextui-org/react";

// custom components
import consoleLog from "../../../../../../functions/consoleLog";
import { updateVideoSource } from "../../functions/artisan";
import { videoSourceUpdateRequest } from "../../../../../../API/server";
import Caption from "./Caption";
import UserContext from "../../../../../../components/UserContext";
import { ToastEffect } from "../../../../../../functions/ToastEffect";
import { uploadMediaFile } from "../../../API/toolsAPI";

const Sequence = (props) => {

    // props
    const {
        scrollLeft,
        scrollRight,
        scrollContainerRef,
        artisanSession,
        selectedSequence,
        handleClickedSequence,
        setArtisanSession,
        // captionData,
    } = props;

    // context method
    const {

        // user data
        userData,

    } = useContext(UserContext);

    // extracted userData value
    const email = userData?.UserInfo?.email;
    const captionData = artisanSession?.video?.videoCaption;


    // react useRef
    const videoRef = useRef(null);
    const fileInputRef = useRef(null);

    // react useState
    const [uploadProgress, setUploadProgress] = useState(null);
    const [currentInProgress, setCurrentInProgress] = useState(null);
    const [isEditCaption, setIsEditCaption] = useState(false);
    const [selectedCaptionData, setSelectedCaptionData] = useState(null);

    // console log activity
    // consoleLog("setVideoContentData", setVideoContentData);
    // consoleLog("captionData", captionData);

    // handle reload video tag
    useEffect(() => {

        // reload video tag
        if (videoRef.current) {
            videoRef.current.load();
        }

        // console log activity
        // consoleLog("setVideoContentData", "triggered");

    }, [artisanSession]);

    // handle undo video source changes changes
    const handleUndoChanges = async (currentNumber, prevVideoSource) => {

        // call "updateVideoSource" function
        const newData = updateVideoSource(
            artisanSession, // array data
            "", // new video source link
            currentNumber, // reference number
            "reverse", // operation kind ("update" || "reverse")
        );

        // set the new modefied data
        setArtisanSession(newData);

        // prepare required data
        const requiredData = {
            sessionID: artisanSession?._id,
            number: currentNumber,
            sourceLink: prevVideoSource,
        };

        // call function that will update the database
        await videoSourceUpdateRequest(requiredData)
            .then((response) => {
                // console log activity 
                consoleLog("videoSourceReverse", response?.data);
            });

    };

    // handle click upload
    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    // handle file change upload media
    const handleFileChange = async (event, currentNumber) => {

        // selected file data
        const file = event.target.files[0];

        // extract file size
        const sizeInMB = file?.size / (1024 * 1024);

        // extract file extension
        const fileExtension = file?.name?.split(".").pop().toLowerCase();

        // check if file is not a video media
        if (fileExtension !== "mp4") {
            ToastEffect("error", "Media file is invalid.");
            return;
        }

        // check media file size
        if (sizeInMB > 25) {
            ToastEffect("error", "File size exceeds 25MB.");
            return;
        }

        if (file) {

            const formData = new FormData();
            formData.append('file', file);
            formData.append('email', email);
            formData.append('refrence_id', artisanSession?._id);
            formData.append('kind', "own_media");
            formData.append('linked_to', "content_artisan");

            try {

                // set current inprogress number
                setCurrentInProgress(currentNumber);

                // call function upload
                const uploadResponse = await uploadMediaFile(formData, (progress) => {
                    setUploadProgress(progress); // set progress value

                    // console log activity
                    consoleLog("progress", progress);
                });

                // call update video source function 
                await handleUndoChanges(currentNumber, uploadResponse?.data?.file_link);

                // call "updateVideoSource" function
                const newData = updateVideoSource(
                    artisanSession, // array data
                    uploadResponse?.data?.file_link, // new video source link
                    currentNumber, // reference number
                    "update", // operation kind ("update" || "reverse")
                );

                // set the new modefied data
                setArtisanSession(newData);
                setUploadProgress(null); // clear progress
                setCurrentInProgress(null); // clear current in progress number

                // console log activity
                // consoleLog("formData", formData);
                // consoleLog("uploadResponse", uploadResponse?.data);

            } catch (error) {
                consoleLog("Error! #handleFileChange:", error);
            }
        }
    };

    // handle edit clicked caption
    const handleEditCaption = (data, idx) => {

        // set is edit caption
        setIsEditCaption(!isEditCaption);

        // set the selected caption data
        setSelectedCaptionData({ selectedData: data, referenceID: idx });

        // console log activity
        consoleLog("editCaption", data);

    };

    return (
        <>

            <Button
                isIconOnly
                // color="danger"
                variant="light"
                radius="full"
                aria-label="scroll left"
                onClick={scrollLeft}
                className="absolute left-0 top-1/2 transform -translate-y-44 z-10"
            >
                <span className="text-3xl text-gray-200 opacity-50 hover:opacity-[100%]">
                    <i className="fa-solid fa-circle-arrow-left" />
                </span>
            </Button>

            <div ref={scrollContainerRef} className="overflow-x-auto w-full">
                <div className="flex w-max space-x-2">
                    {artisanSession?.video?.videoSequence?.map((item, index) => {

                        // caption timing
                        // const captionTiming = artisanSession?.video?.videoCaption?.find((data) => (data?.id + 1) === item?.number);

                        // console log activity
                        // consoleLog("captionTiming", captionTiming);
                        // consoleLog("videoData", item);

                        return (
                            <div key={index}>
                                <Card
                                    // isPressable
                                    // onPress={() => console.log(item)}
                                    shadow="sm"

                                    className={`w-[15rem] ${(selectedSequence?.number || 1) === item?.number && "border-3 border-warning"}`}
                                >
                                    <CardBody className="overflow-visible p-0">

                                        {/* hidden input file type */}
                                        <input
                                            type="file"
                                            accept="video/*"
                                            ref={fileInputRef}
                                            onChange={(event) => handleFileChange(event, item?.number)}
                                            className="hidden"
                                        />

                                        {/* upload progress */}
                                        {uploadProgress && currentInProgress === item?.number && (
                                            <div className="absolute inset-0 flex items-center justify-center z-[9] backdrop-blur-sm">
                                                <CircularProgress
                                                    aria-label="uploading..."
                                                    size="lg"
                                                    value={uploadProgress || 0}
                                                    color="warning"
                                                    showValueLabel={true}
                                                    className="text-warning"
                                                />
                                            </div>
                                        )}

                                        {/* upload own media */}
                                        <div className="absolute top-0 z-[9] p-1 space-x-1">
                                            <Tooltip content="Upload own media" offset={-2} color="primary" showArrow={true}>
                                                <Button
                                                    shadow
                                                    isIconOnly
                                                    // color="primary"
                                                    size="sm"
                                                    radius="full"
                                                    variant="flat"
                                                    className="bg-gray-700 bg-opacity-[0.75]"
                                                    onClick={handleButtonClick}
                                                >
                                                    <span className="text-lg text-white">
                                                        <i className="fa-solid fa-plus" />
                                                    </span>
                                                </Button>
                                            </Tooltip>


                                            {/* undo video source changes */}
                                            {item?.video_source_prev && (
                                                // <div className="absolute top-[50%] bottom-[50%] right-[50%] left-[50%] z-[9]">
                                                <Tooltip content="Undo changes" offset={-2} color="primary" showArrow={true}>
                                                    <Button
                                                        isIconOnly
                                                        size="sm"
                                                        variant="flat"
                                                        radius="full"
                                                        className="bg-primary hover:bg-primary-300 p-2 bg-opacity-[0.75]"
                                                        onClick={() => handleUndoChanges(item?.number, item?.video_source_prev)}
                                                    >
                                                        <i className="fa-solid fa-rotate-left text-xl text-white" />
                                                    </Button>
                                                </Tooltip>
                                                // </div>
                                            )}
                                        </div>

                                        <video
                                            // controls
                                            ref={videoRef}
                                            preload="metadata"
                                            src={item?.video_source}
                                            className="w-auto object-cover h-[10rem] cursor-pointer"
                                            onClick={() => handleClickedSequence(item)}
                                        />

                                    </CardBody>
                                    <CardFooter className="grid grid-cols-1 gap-1">

                                        <Textarea
                                            readOnly
                                            size="lg"
                                            variant="bordered"
                                            placeholder="Caption text"
                                            disableAnimation
                                            disableAutosize
                                            value={item.text}
                                            classNames={{ base: "h-full", }}
                                            style={{ minHeight: '40px' }}
                                        />

                                    </CardFooter>
                                </Card>
                            </div>
                        );
                    })}

                </div>
            </div>

            <div className="relative mt-2 border-3 border-gray-300 dark:border-gray-600 rounded-lg p-2">

                {isEditCaption && (
                    <div className="absolute flex inset-0 justify-center items-center z-50 backdrop-blur-sm">

                        <Button
                            isIconOnly
                            size="sm"
                            variant="light"
                            radius="full"
                            onClick={() => setIsEditCaption(false)}
                            className="absolute top-2 right-2 text-xl z-20 text-gray-700 dark:text-gray-200 bg-gray-300 dark:bg-gray-600 hover:bg-gray-200 dark:hover:bg-gray-700"
                        >
                            <i className="fa-solid fa-times" />
                        </Button>

                        <Caption
                            selectedCaptionData={selectedCaptionData}
                            artisanSession={artisanSession}
                            setArtisanSession={setArtisanSession}
                        />

                    </div>
                )}

                <div className="w-auto space-x-2 min-h-[5rem] max-h-[15rem] overflow-auto">

                    {captionData?.map((item, index) => {
                        return (
                            <span key={index}>
                                {/* <Tooltip
                                    color="primary"
                                    showArrow
                                    content={
                                        <div className="px-1 py-2">
                                            <div className="text-small font-bold">Custom Content</div>
                                            <div className="text-tiny">This is a custom tooltip content</div>
                                        </div>
                                    }
                                > */}
                                <Chip
                                    // color={item?.selected ? "warning" : "default"}
                                    radius="sm"
                                    size="sm"
                                    variant="bordered"
                                    className="text-lg cursor-pointer mt-2"
                                    onClick={() => handleEditCaption(item, index)}
                                >
                                    {`${item?.word || item?.text} `}
                                </Chip>
                                {/* </Tooltip> */}
                            </span>
                        );
                    })}
                </div>

            </div>

            <Button
                isIconOnly
                // color="danger"
                variant="light"
                radius="full"
                aria-label="scroll left"
                onClick={scrollRight}
                className="absolute right-0 top-1/2 transform -translate-y-44 z-10"
            >
                <span className="text-3xl text-gray-200 opacity-50 hover:opacity-[100%]">
                    <i className="fa-solid fa-circle-arrow-right" />
                </span>
            </Button>

        </>
    );
};

export default Sequence;