// libraries
import React, { useEffect, useState } from "react";
import { Progress } from "@nextui-org/react";

// custom components
import consoleLog from "../../../../../../functions/consoleLog";

const Process = (props) => {

    // props
    const {
        processedData,
    } = props;

    // console log activity
    // consoleLog("processedData", processedData);

    return (
        <>
            {/* <h2 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                Crafting progress status
            </h2> */}

            <div className="px-10">
                <ol className="relative border-s border-gray-200 dark:border-gray-700">
                    {processedData?.map((data, idx) => {

                        if (data) {
                            return (
                                <li key={idx} className="mb-10 ms-6">
                                    <span className={`${data?.status === "error" ? "bg-red-500" : data?.status === "completed" ? "bg-success text-black" : "bg-gray-100 dark:bg-gray-800 text-gray-500 dark:text-gray-400"} absolute -start-2.5 flex h-5 w-5 items-center justify-center rounded-full ring-8 ring-white dark:ring-gray-900`}>
                                        <span className="text-xs">
                                            {data?.status === "progress" ? (
                                                <i className="fa-solid fa-spinner fa-spin" />
                                            ) : data?.status === "error" ? (
                                                <i className="fa-solid fa-xmark" />
                                            ) : (
                                                <i className="fa-solid fa-check" />
                                            )}

                                        </span>
                                    </span>
                                    <h3 className="mb-1.5 text-lg font-semibold leading-none text-gray-900 dark:text-white">
                                        {data?.signature}
                                    </h3>
                                    <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                                        {data?.status === "error" ? "Error processing your video." : data?.description}
                                    </p>

                                    {data?.status === "progress" && (
                                        <Progress
                                            // label="Monthly expenses"
                                            aria-label="Processing..."
                                            // formatOptions={{ style: "currency", currency: "ARS" }}
                                            size="sm"
                                            value={data?.current}
                                            maxValue={data?.total}
                                            color="warning"
                                            showValueLabel={false}
                                            className="max-w-[10rem] mt-2"
                                        />
                                    )}

                                </li>
                            );
                        }
                    })}

                    {!processedData[0] && (
                        <li className="mb-10 ms-6">
                            <span className="bg-gray-100 dark:bg-gray-800 text-gray-500 dark:text-gray-400 absolute -start-2.5 flex h-5 w-5 items-center justify-center rounded-full ring-8 ring-white dark:ring-gray-900">
                                <span className="text-xs">
                                    <i className="fa-solid fa-check" />
                                </span>
                            </span>
                            <h3 className="mb-1.5 text-lg font-semibold leading-none text-gray-900 dark:text-white">
                                GoSlidely
                            </h3>
                            <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                                Content Artisan
                            </p>
                        </li>
                    )}
                </ol>
            </div>
        </>
    );
};

export default Process;