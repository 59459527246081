// libraries
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// css
import "./index.css";
import "../Components/TextType/index.css";

// custom components
import { verifyToken } from "../../../../API/authAPI";
import { createVideo, deleteGeneratedVideo, calculate_accuracy, createCustomVideo, handleReProcessVG } from "../API/toolsAPI";
import consoleLog from "../../../../functions/consoleLog";
import { ToastEffect } from "../../../../functions/ToastEffect";
import AdvancedOptions from "../Components/AdvancedOptions";
import UserContext from "../../../../components/UserContext";
import { getCreditBalance, payVideo, recordCredits } from "../../../../API/account";
import { adjustCreditsView } from "../../../../functions/itemWithExpiry";
import { toolPrices } from "../../../../Configs/toolPrices";
import { addMarkerToData, cachedToolsConfiguration, capitalizeFirstLetter, getCachedToolsConfiguration, handlePurchaseCredits, removeMarkedData } from "../../../../functions/general";
import { alertBeforeAction } from "../Components/Transcriber/AlertBeforeAction";
import { autoOpenModalPromptEditor } from "../functions/general";
import advanceOptionJsonData from "../Components/AdvancedOptions/index.json";
import VG_Table from "./components/VG_Table";
import VG_CustomMedia from "./components/VG_CustomMedia";
import { URLValues } from "./Helpers/data";
import { Tooltip, useDisclosure } from "@nextui-org/react";

const GeneralPurpose = () => {

    // context method
    const {
        // Others
        userData,
        appConfigData,
        subscriptionData,
        configData,
        toolsDataStatus,

        // For image2video data params
        setToolsDataParam,
        toolsDataParam,
        setVideos,
        videos,
        itemsPerPage,
        totalSumVideos,
        pageCount,
        setRefetchToolsData,

        // debounce saveConfig function
        debouncedSaveConfig,

        // own BGM
        ownBGM,

    } = useContext(UserContext);

    // extracted values
    const email = userData?.UserInfo?.email;
    const isOldUser = userData?.UserInfo?.is_oldSubs;
    // const voiceOwnAPI = getCachedToolsConfiguration("ownElevenLabsAPI", configData)?.config === "true"; // own eleven labs API key
    const voiceOwnAPI = true;
    const accuracyOwnAPI = false;
    const avatarOwnAPI = true;

    // appConfigData extracted values
    const config_image = appConfigData[0]?.image;

    // react components
    const foulRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    // extract path name
    const { pathname, search } = location;

    // Use URLSearchParams with the `search` string from location
    const queryParams = new URLSearchParams(search);

    // extract the "p" and "id" for custom media
    const URLPage = queryParams.get('p');
    const URLDataID = queryParams.get('id');

    // get Item from localstorage
    const currentTab = localStorage?.getItem(`AOT_${pathname}`);
    const accessToken = localStorage.getItem('access');
    const refreshToken = localStorage.getItem('refresh');

    // next ui components
    const { isOpen: isOpenSE, onOpen: onOpenSE, onClose: onCloseSE } = useDisclosure(); // SE - (Script Editor)
    const { isOpen: isOpenVTE, onOpen: onOpenVTE, onClose: onCloseVTE } = useDisclosure(); // VTE - (Video Theme Editor)
    const { isOpen: isOpenITE, onOpen: onOpenITE, onClose: onCloseITE } = useDisclosure(); // ITE - (Image Theme Editor)

    // react components custom
    const [voiceId, setVoiceId] = useState(
        getCachedToolsConfiguration("voiceId", configData)?.normal_config
        || process.env.REACT_APP_ELEVENLAB_VOICE_ID
    );
    const [voicePreview, setVoicePreview] = useState(
        getCachedToolsConfiguration("voicePreview", configData)?.normal_config
        || process.env.REACT_APP_ELEVENLAB_VOICE_PREVIEW
    );
    const [videoAspectRatio, setVideoAspectRatio] = useState(
        getCachedToolsConfiguration("videoAspectRatio", configData)?.config
        || "default"
    );
    const [videoCaptionOption, setVideoCaptionOption] = useState(
        getCachedToolsConfiguration("videoCaptionOption", configData)?.config
        || "default"
    );
    const [videoSlideOption, setVideoSlideOption] = useState(
        getCachedToolsConfiguration("videoSlideOption", configData)?.config
        || "default"
    );
    const [imageTheme, setImageTheme] = useState(
        capitalizeFirstLetter(getCachedToolsConfiguration("imageTheme", configData)?.config)
        || "Default"
    );
    const [customImageThemePrompt, setCustomImageThemePrompt] = useState(
        getCachedToolsConfiguration("customImageThemePrompt", configData)?.content
        || ""
    );
    const [videoTheme, setVideoTheme] = useState(
        getCachedToolsConfiguration("videoTheme", configData)?.config
        || "default"
    );
    const [customVideoThemePrompt, setCustomVideoThemePrompt] = useState(
        getCachedToolsConfiguration("customVideoThemePrompt", configData)?.content
        || ""
    );
    const [promptKind, setPromptKind] = useState(
        getCachedToolsConfiguration("scriptPrompt", configData)?.config
        || "idea"
    );
    const [userIdea, setUserIdea] = useState(
        getCachedToolsConfiguration("userIdeaContent", configData)?.normal_config
        || ""
    );
    const [textScript, setTextScript] = useState(
        getCachedToolsConfiguration("userScriptContent", configData)?.normal_config
        || ""
    );
    const [defaultVoiceName, setDefaultVoiceName] = useState(
        getCachedToolsConfiguration("defaultVoiceName", configData)?.config
        || process.env.REACT_APP_OPENAI_VOICE_NAME
    );
    const [languageID, setLanguageID] = useState(
        getCachedToolsConfiguration("languageID", configData)?.normal_config
        || "en"
    );
    const [languageName, setLanguageName] = useState(
        getCachedToolsConfiguration("languageName", configData)?.normal_config
        || "English"
    );
    const [elevenLabsAPIKey, setElevenLabsAPIKey] = useState(
        getCachedToolsConfiguration("ElevenLabsAPIKey", configData)?.normal_config
        || ""
    );
    const [backgroundMusic, setBackgroundMusic] = useState(
        getCachedToolsConfiguration("BackgroundMusic", configData)?.normal_config
        || ""
    );
    const [isCheckedCustomBGM, setIsCheckedCustomBGM] = useState(
        getCachedToolsConfiguration("CustomBGM", configData)?.config === "true"
    );
    const [selectedKeys, setSelectedKeys] = useState(
        new Set([getCachedToolsConfiguration("selectedOwnBGM", configData)?.normal_config || (ownBGM?.data?.length > 0 ? ownBGM?.data[0]?._id : "")])
    );

    // react components
    const [disabledOptions, setDisabledOptions] = useState({});

    // selected own BGM key
    const selectedValue = useMemo(() => Array.from(selectedKeys).join(", "), [selectedKeys]);

    // filter and get own BGM from array
    const filteredOwnBGMData = ownBGM?.data?.find((data) => data?._id === selectedValue);

    // console logt activity
    // consoleLog("configData", configData);
    // consoleLog("URLPage", URLPage);
    // consoleLog("filteredOwnBGMData", filteredOwnBGMData);

    const [selected, setSelected] = useState("audio");
    const [addAnimation, setAddAnimation] = useState(false);
    const [enableAccuracy, setEnableAccuracy] = useState(false);
    const [enableCaption, setEnableCaption] = useState(false);
    const [enableAvatar, setEnableAvatar] = useState(false);
    const [enableLanguange, setEnableLanguange] = useState(false);
    const [enableAvatarOption, setEnableAvatarOption] = useState(false);
    const [generateVideoStatus, setGenerateVideoStatus] = useState([]);
    const [tab, setTab] = useState(currentTab ? currentTab : "tab1");
    const [enableVoice, setEnableVoice] = useState(false);
    const [avatarId, setAvatarId] = useState("");
    const [avatarURL, setAvatarURL] = useState("");
    const [selectedTalkingPhoto, setSelectedTalkingPhoto] = useState("");
    const [selectedTalkingPhotoURL, setSelectedTalkingPhotoURL] = useState("");
    const [heyGenAPIKey, setHeyGenAPIKey] = useState("");
    const [midjourneyAPIKey, setMidjourneyAPIKey] = useState("");
    const [voiceModels, setVoiceModels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isFieldError, setIsFieldError] = useState(false);
    const [hasFoulWords, setHasFoulWords] = useState(false);
    const [scriptHasFoulWords, setScriptHasFoulWords] = useState(false);
    const [foulPhrase, setFoulPhrase] = useState("");
    const [isVoiceLoading, setIsVoiceLoading] = useState(false);
    const [selectedVideoStyle, setSelectedVideoStyle] = useState("default");
    const [isMaximumRequests, setIsMaximumRequests] = useState(false);
    const [pageRangeDisplayed, setPageRangeDisplayed] = useState(0);
    const [isDeletingData, setIsDeletingData] = useState({});
    const [isAPIKeyAvatarErr, setIsAPIKeyAvatarErr] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isAdvanceOptionHidden, setIsAdvanceOptionHidden] = useState(true);
    const [avatarOption, setAvatarOption] = useState("avatar");
    const [creditCalculation, setCreditCalculation] = useState(0);
    const [customCreditCalculation, setCustomCreditCalculation] = useState(0);
    const [customAudioCreditCalculation, setCustomAudioCreditCalculation] = useState(0);
    const [createCreditCalculation, setCreateCreditCalculation] = useState(0);
    const [isRetrying, setIsRetrying] = useState([]);
    // const [showPromptEditor, setShowPromptEditor] = useState(false);
    // const [editCustomVideoTheme, setEditCustomVideoTheme] = useState(false);
    // const [editCustomImageTheme, setEditCustomImageTheme] = useState(false);
    const [savingCustomVideoThemePrompt, setSavingCustomVideoThemePrompt] = useState(false);
    const [savingCustomImageThemePrompt, setSavingCustomImageThemePrompt] = useState(false);
    const [elvenLabsAPIValidity, setElvenLabsAPIValidity] = useState({});
    const [isCustomMedia, setIsCustomMedia] = useState(false);
    const [mediaSoureceData, setMediaSoureceData] = useState([]);
    const [voiceModelList, setVoiceModelList] = useState([]);

    // console log activity
    // consoleLog("configData", configData);
    // consoleLog("languageName", languageName);
    // consoleLog("enableLanguange", enableLanguange);
    // consoleLog("enableVoice", enableVoice);

    useEffect(() => {
        const check = async () => {
            if (isCustomMedia || URLPage === URLValues?.customMedia) {
                // consoleLog("selected", selected);
                // consoleLog("tab", tab);
                // check selected custom media tab
                if (selected === "audio") {
                    setDisabledOptions({
                        // main
                        AO_GenerateButton: true,
                        // tabs
                        AO_TabVideo: true,
                        AO_TabVoice: false,
                        AO_TabMusic: true,
                        // sub options
                        AO_sub_accuracy: true,
                        AO_sub_animation: true,
                        AO_sub_caption: true,
                        AO_sub_aspectRatio: true,
                        AO_sub_imgTheme: true,
                        AO_sub_vidTheme: true,
                    });
                    setTab("tab3");
                    localStorage.setItem(`AOT_${pathname}`, "tab3");
                } else if (selected === "media") {
                    setDisabledOptions({
                        // main
                        AO_GenerateButton: true,
                        // tabs
                        AO_TabVideo: false,
                        AO_TabVoice: true,
                        AO_TabMusic: false,
                        // sub options
                        AO_sub_accuracy: true,
                        AO_sub_animation: false,
                        AO_sub_caption: true,
                        AO_sub_captionOption: true,
                        AO_sub_slideOption: false,
                        AO_sub_aspectRatio: true,
                        AO_sub_imgTheme: true,
                        AO_sub_vidTheme: true,
                    });
                    setTab("tab1");
                    localStorage.setItem(`AOT_${pathname}`, "tab1");
                } else if (selected === "caption") {
                    setDisabledOptions({
                        // main
                        AO_GenerateButton: true,
                        // tabs
                        AO_TabVideo: true,
                        AO_TabVoice: true,
                        AO_TabMusic: false,
                        // sub options
                        AO_sub_accuracy: true,
                        AO_sub_animation: true,
                        AO_sub_caption: true,
                        AO_sub_captionOption: true,
                        AO_sub_slideOption: true,
                        AO_sub_aspectRatio: true,
                        AO_sub_imgTheme: true,
                        AO_sub_vidTheme: true,
                    });
                    setTab("tab5");
                    localStorage.setItem(`AOT_${pathname}`, "tab5");
                }
            }
        };
        check();
    }, [selected, isCustomMedia]);

    // check if mode is mobile
    useEffect(() => {
        // Function to check if the screen is mobile
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth <= 1023); // Adjust the threshold as per your requirement
        };

        // Check the screen size initially
        checkIsMobile();

        // Attach the event listener to window resize
        window.addEventListener('resize', checkIsMobile);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', checkIsMobile);
    }, []);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {

        // refetch tools data
        setRefetchToolsData(oldVal => oldVal + 1);

        // calculate new offset
        const newOffset = (event.selected * itemsPerPage) % totalSumVideos;

        // declaire required parameter
        const required_param = {
            email: email,
            start: itemsPerPage,
            type: process.env.REACT_APP_VIDEO_GEN_ID,
            offset: newOffset
        };

        // set new generated video data use state
        setToolsDataParam(required_param);
    };

    // handle delete video function
    const handleDeleteVideo = async (data) => {

        // variables
        const toDeleteVideoID = data?._id;
        const toDeleteKind = data?.content_url;

        // check first if 2 required data exist
        if (email && toDeleteVideoID) {

            // call marker function and insert data
            await addMarkerToData(setIsDeletingData, toDeleteVideoID);

            let action_type = "";

            // condition
            if (toDeleteKind === "custom_video") {
                action_type = toDeleteKind;
            } else {
                action_type = "image2video";
            }

            await deleteGeneratedVideo({
                user_email: email,
                user_auth: process.env.REACT_APP_AUTH,
                video_id: toDeleteVideoID,
                action_type: action_type
            }).then(async () => {
                const updatedVideos = videos.filter((video) => video._id !== toDeleteVideoID);
                setVideos(updatedVideos);
                ToastEffect("success", "Delete successfully.");

                // call marker to remove marked data
                await removeMarkedData(setIsDeletingData, toDeleteVideoID);
            });
        }
    };

    // handle resize paginate
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setPageRangeDisplayed(1);
            } else {
                setPageRangeDisplayed(itemsPerPage);
            }
        };

        // Initial check and add event listener
        handleResize();
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // userIdea function handler
    const handleUserIdea = (e) => {

        // extracted value and kind
        const inputValue = e.target.value;
        const kind = "idea";
        const config_key = "userIdeaContent";

        // set value in useState to be usable
        setUserIdea(inputValue);

        // cached config to localstorage
        cachedToolsConfiguration(config_key, inputValue);

        // Trigger modal to open in case the max character limit is reached.
        autoOpenModalPromptEditor(inputValue, onOpenSE);

        // Call the debounced function
        debouncedSaveConfig(kind, email, pathname, kind, inputValue);
    };

    // handle on select elevenlabs voices
    const handleSelectedVoice = (e) => {

        // extracted value
        const [voiceID, voicePreview] = e.target.value.split("|");

        // set value to useState
        setVoiceId(voiceID);
        setVoicePreview(voicePreview);

        // variables
        const kind_voiceID = "voiceId";
        const kind_voicePreview = "voicePreview";

        // cached config to localstorage
        cachedToolsConfiguration(kind_voiceID, voiceID);
        cachedToolsConfiguration(kind_voicePreview, voicePreview);

        // save configuration to the database
        debouncedSaveConfig(kind_voiceID, email, pathname, kind_voiceID, voiceID);
        debouncedSaveConfig(kind_voicePreview, email, pathname, kind_voicePreview, voicePreview);

        // console log activity
        consoleLog("voiceID", voiceID);
        consoleLog("voicePreview", voicePreview);
    };

    // handle selected language
    const handleSelectedLanguage = (e) => {

        // extracted value
        const [languageID, languageName] = e.target.value.split("|");

        // set value to useState
        setLanguageID(languageID);
        setLanguageName(languageName);

        // variables
        const kind_languageID = "languageID";
        const kind_languageName = "languageName";

        // cached config to localstorage
        cachedToolsConfiguration(kind_languageID, languageID);
        cachedToolsConfiguration(kind_languageName, languageName);

        // save configuration to the database
        debouncedSaveConfig(kind_languageID, email, pathname, kind_languageID, languageID);
        debouncedSaveConfig(kind_languageName, email, pathname, kind_languageName, languageName);
    };

    // handle on select default voices
    const handleDefaultVoice = (e) => {

        // extracted value
        const selectedVoice = e.target.value; // extracted value

        // set value to useState
        setDefaultVoiceName(selectedVoice);

        // variables
        const kind = "defaultVoiceName";

        // cached config to localstorage
        cachedToolsConfiguration(kind, selectedVoice);

        // save configuration to the database
        debouncedSaveConfig(kind, email, pathname, kind, selectedVoice);
    };

    // handle on select aspect ratio
    const handleVideoAspectRatio = async (e) => {

        // extracted value
        const aspectRatio = e.target.value || "default";

        // set value to useState
        setVideoAspectRatio(aspectRatio);

        // variables
        const kind = "videoAspectRatio";

        // cached config to localstorage
        cachedToolsConfiguration(kind, aspectRatio);

        // save configuration to the database
        debouncedSaveConfig(kind, email, pathname, kind, aspectRatio);

        // console log activity
        // consoleLog("aspectRatio", aspectRatio);
    };

    // handle on select caption option
    const handleCaptionOption = async (e) => {

        // extracted value
        const captionOption = e.target.value || "default";

        // set value to useState
        setVideoCaptionOption(captionOption);

        // variables
        const kind = "videoCaptionOption";

        // cached config to localstorage
        cachedToolsConfiguration(kind, captionOption);

        // save configuration to the database
        debouncedSaveConfig(kind, email, pathname, kind, captionOption);
    };

    // handle on select slide option
    const handleSlideOption = async (e) => {

        // extracted value
        const slideOption = e.target.value || "default";

        // set value to useState
        setVideoSlideOption(slideOption);

        // variables
        const kind = "videoSlideOption";

        // cached config to localstorage
        cachedToolsConfiguration(kind, slideOption);

        // save configuration to the database
        debouncedSaveConfig(kind, email, pathname, kind, slideOption);
    };

    // handle on select image theme
    const handleImageTheme = async (e) => {

        // extracted value
        const imageTheme = e.target.value || "Default";

        // set value to useState
        setImageTheme(imageTheme);

        // conditional for Custom Theme modal editor
        if (imageTheme === "Custom Theme") {
            onOpenITE();
        }

        // variables
        const kind = "imageTheme";

        // cached config to localstorage
        cachedToolsConfiguration(kind, imageTheme);

        // save configuration to the database
        debouncedSaveConfig(kind, email, pathname, kind, { value: imageTheme, content: customImageThemePrompt });
    };

    // handle custom image theme prompt
    const handleCustomImageThemePrompt = (e) => {
        const CustomImageThemePrompt = e.target.value; // extracted value
        setCustomImageThemePrompt(CustomImageThemePrompt); // set value to useState

        // cached config to localstorage
        cachedToolsConfiguration("customImageThemePrompt", CustomImageThemePrompt);
    };

    // handle on select video theme
    const handleVideoTheme = async (e) => {

        // extracted value
        const videoTheme = e.target.value || "default";

        // set value to useState
        setVideoTheme(videoTheme);

        // conditional for Custom Theme modal editor
        if (videoTheme === "custom_theme") {
            onOpenVTE();
        }

        // variables
        const kind = "videoTheme";

        // cached config to localstorage
        cachedToolsConfiguration(kind, videoTheme);

        // save configuration to the database
        debouncedSaveConfig(kind, email, pathname, kind, { value: videoTheme, content: customVideoThemePrompt });
    };

    // handle custom video theme prompt
    const handleCustomVideoThemePrompt = (e) => {
        const CustomVideoThemePrompt = e.target.value; // extracted value
        setCustomVideoThemePrompt(CustomVideoThemePrompt); // set value to useState

        // cached config to localstorage
        cachedToolsConfiguration("customVideoThemePrompt", CustomVideoThemePrompt);
    };

    // handle selected background music
    const handleSelectBackgroundMusic = (event) => {

        // extracted value
        const selectedValue = event.target.value;

        // set value to useState
        setBackgroundMusic(selectedValue);

        // variables
        const kind = "BackgroundMusic";

        // cached config to localstorage
        cachedToolsConfiguration(kind, selectedValue);

        // save configuration to the database
        debouncedSaveConfig(kind, email, pathname, kind, selectedValue);
    };

    // on click outside modal function
    useEffect(() => {
        // Attach the listeners on component mount.
        document.addEventListener("mousedown", handleClickOutsideDownload);
        // Detach the listeners on component unmount.
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideDownload);
        };
    }, []);

    // handle on click outside modal download
    const handleClickOutsideDownload = (event) => {
        if (foulRef.current && !foulRef.current.contains(event.target)) {
            setIsFieldError(false);
            // setHasFoulWords(false)
        }
    };

    // generate video function
    const generateVideo = async () => {
        setLoading(true);
        setIsDisabled(true);
        setScriptHasFoulWords(false);
        setIsAPIKeyAvatarErr(false);

        // check if Script or Idea is empty.
        if ((promptKind === "idea" && !userIdea) || (promptKind === "script" && !textScript)) {
            ToastEffect("error", "Idea or Script should not be empty");
            setLoading(false);
            setIsDisabled(false);
            return;
        }

        // check if no Midjourney API key is provided
        if (enableAccuracy && !midjourneyAPIKey && accuracyOwnAPI) {
            setLoading(false);
            setIsDisabled(false);
            ToastEffect("error", "Please provide the MidJourney auth key first.");
            return;
        }

        // check if no HeyGen API key is provided
        if (enableAvatar && !heyGenAPIKey && avatarOwnAPI) {
            setLoading(false);
            setIsDisabled(false);
            ToastEffect("error", "Please provide the HeyGen API key first.");
            return;
        }

        // check if no seelected image
        if (avatarOption === "image" && enableAvatar && !selectedTalkingPhoto) {
            setLoading(false);
            setIsDisabled(false);
            ToastEffect("error", "Select or upload an image first!");
            return;
        }

        // check if no seelected avatar
        if (avatarOption === "avatar" && enableAvatar && !avatarId) {
            setLoading(false);
            setIsDisabled(false);
            ToastEffect("error", "Select an avatar first!");
            return;
        }

        // check if no ElevenLabs API key is provided
        if (enableVoice && !elevenLabsAPIKey && voiceOwnAPI) {
            setLoading(false);
            setIsDisabled(false);
            ToastEffect("error", "Please provide the ElevenLabs API key first.");
            return;
        }

        if (elvenLabsAPIValidity?.status) {
            setLoading(false);
            setIsDisabled(false);
            ToastEffect("error", `ElevenLabs: ${elvenLabsAPIValidity?.message}`);
            return;
        }

        // required data
        const requiredData = {
            access: accessToken,
            refresh: refreshToken,
        };

        // setVideo("");
        setIsFieldError(false);

        // verify user if valid or returns user to home if no email or not allowed
        await verifyToken(requiredData);

        let midjourney_APIKey;
        let heygen_api_key;
        let voice_key;
        let avatar_id = "";
        let talking_photo_id = "";
        let user_prompt = "";
        let text_script = "";
        let language_id = "";
        let language_name = "";
        let user_voice = "";
        let custom_video_theme_config = "";
        let custom_video_theme_content = "";
        let custom_image_theme_config = "";
        let custom_image_theme_content = "";
        let aspect_ratio = "";
        let caption_option = "";
        let BGM_source = "";

        // check if own BGM
        if (isCheckedCustomBGM) {
            BGM_source = filteredOwnBGMData?.media_link || "";
        } else {
            BGM_source = backgroundMusic || "";
        }

        // check if own midjourney API key
        if (accuracyOwnAPI && enableAccuracy) {
            midjourney_APIKey = midjourneyAPIKey;
        } else {
            midjourney_APIKey = "";
        }

        // check if own heygen API key
        if (avatarOwnAPI && enableAvatar) {
            heygen_api_key = heyGenAPIKey;
        } else {
            heygen_api_key = "";
        }

        // check if its avatar or image
        if (avatarOption === "avatar") {
            if (enableAvatar) {
                avatar_id = avatarId;
            }
        } else {
            if (enableAvatar) {
                talking_photo_id = selectedTalkingPhoto;
            }
        }

        // check if own 11Labs API key
        if (voiceOwnAPI && enableVoice) {
            voice_key = elevenLabsAPIKey;
        } else {
            voice_key = "";
        }

        // check if idea or script
        if (promptKind === "idea") {
            user_prompt = userIdea;
        } else {
            text_script = textScript;
        }

        // check if voice is on
        if (!enableVoice || !enableLanguange) {
            language_id = "";
            language_name = "";
        } else {
            language_id = languageID;
            language_name = languageName;
        }

        // check if voice is enable of disable
        if (enableVoice) {
            user_voice = voiceId;
        } else {
            user_voice = defaultVoiceName;
        }

        // check if video theme is custom
        if (videoTheme === "custom_theme") {
            custom_video_theme_config = videoTheme;
            custom_video_theme_content = customVideoThemePrompt;
        } else if (videoTheme === "default") {
            custom_video_theme_config = advanceOptionJsonData?.videoTheme[1]?.value;
            custom_video_theme_content = "";
        } else {
            custom_video_theme_config = videoTheme;
            custom_video_theme_content = "";
        }

        // check if image theme is custom
        if (imageTheme === "Custom Theme") {
            custom_image_theme_config = imageTheme;
            custom_image_theme_content = customImageThemePrompt;
        } else {
            custom_image_theme_config = imageTheme;
            custom_image_theme_content = "";
        }

        // check aspect ration
        if (videoAspectRatio === "default") {
            aspect_ratio = advanceOptionJsonData?.aspectRatio[1]?.value;
        } else {
            aspect_ratio = videoAspectRatio;
        }

        // caption option
        if (videoCaptionOption === "default") {
            caption_option = advanceOptionJsonData?.captionOption[1]?.value;
        } else {
            caption_option = videoCaptionOption;
        }

        // check credit balance
        const ceditBalance = await getCreditBalance(email);

        if (subscriptionData?.message !== "freeAccess") {

            // Extracting repeated variable calls
            const subscriptionCredits = ceditBalance?.data?.message?.subs || 0;
            const paidCredits = ceditBalance?.data?.message?.paid || 0;
            // const generalPurposePrice = paymentPrice[0]?.GeneralPurpose || 0;

            // Calculating the total credits and total price
            const totalCredits = (subscriptionCredits + paidCredits);

            // console.log(totalCredits, totalPrice);

            if (totalCredits < creditCalculation) {
                setLoading(false);
                setIsDisabled(false);
                // ToastEffect("error", "Not enough credits.");
                await alertBeforeAction(handlePurchaseCredits, "handleFunction2", "Low-Credits", { navigate });
                return;
            }
        }

        // --------------------------------
        // --- process prompt data
        // --------------------------------
        const data_process = {
            user_email: email,
            user_auth: process.env.REACT_APP_AUTH,
            user_prompt: user_prompt,
            text_script: text_script,
            user_voice: user_voice,
            voice_key: voice_key,
            languageName: language_name,
            languageID: language_id,
            accuracy: enableAccuracy,
            avatar: enableAvatar,
            avatar_id: avatar_id,
            bg_music: BGM_source,
            isOwnBGM: isCheckedCustomBGM,
            caption: enableCaption,
            effects: addAnimation,
            heygen_api_key: heygen_api_key,
            midjourney_APIKey: midjourney_APIKey,
            talking_photo_id: talking_photo_id,
            toolId: process.env.REACT_APP_VIDEO_GEN_ID,
            toolName: process.env.REACT_APP_VIDEO_GEN_NAME,
            videoStyle: selectedVideoStyle,
            voice: enableVoice,
            amount: creditCalculation,
            aspect_ratio: aspect_ratio,
            // created: getMySQLDateTimeFormat(),
            created: new Date(),
            image_price: Number(config_image),
            video_theme_config: custom_video_theme_config,
            video_theme_content: custom_video_theme_content,
            image_theme_config: custom_image_theme_config,
            image_theme_content: custom_image_theme_content,
            message: "",
            task_id: "",
            data_id: "",
            caption_option: caption_option,
            slide_option: videoSlideOption,
        };

        // --------------------------------
        // --- display results
        // --------------------------------
        // consoleLog("data_process", data_process);
        // setLoading(false);
        // setIsDisabled(false);

        try {

            if (enableAccuracy) { // process with accuracy

                // --------------------------------
                // --- process video creation
                // --------------------------------
                const response = await calculate_accuracy(data_process);
                const taskId = response?.data?.task_id;

                // --------------------------------
                // --- check if max request reach
                // --------------------------------
                if (response?.data?.detail == "request_limit") {
                    ToastEffect("error", "Maximum request limit reached.");
                    setLoading(false);
                    setIsDisabled(false);
                    return;
                }

                // --------------------------------
                //  --- Check if task ID exist
                // --------------------------------
                if (!taskId) {
                    ToastEffect("error", "No task ID received!");
                    setLoading(false);
                    setIsDisabled(false);
                    return;
                }

                // --------------------------------
                //  --- Save Task ID
                // --------------------------------
                // await saveTaskId(email, process.env.REACT_APP_VIDEO_GEN_ID, "taskId", taskId);

                setLoading(false);
                setIsDisabled(false);
                // consoleLog("#generateVideo", response);

            } else { // process without accuracy

                await createTheVideo(data_process);
            }

            // finally re-fetch data
            setRefetchToolsData(oldVal => oldVal + 1);

        } catch (error) { // error handler
            consoleLog("index.js - error generateVideo", error);
            setLoading(false);
            setIsDisabled(false);
            return;
        }
    };

    // create video function 
    const createTheVideo = async (data) => {
        setLoading(true);
        setIsDisabled(true);

        const image_cost_calculation = Number(data?.image_cost) || 0;
        const generation_cost = Number(data?.amount) || 0;
        const total_cost_Calculation = generation_cost + image_cost_calculation;

        // check credit balance
        const ceditBalance = await getCreditBalance(email);

        let text_script = "";

        // check if idea or script
        if (promptKind !== "idea") {
            text_script = textScript;
        }

        if (subscriptionData?.message !== "freeAccess") {

            // Extracting repeated variable calls
            const subscriptionCredits = ceditBalance?.data?.message?.subs || 0;
            const paidCredits = ceditBalance?.data?.message?.paid || 0;
            // const generalPurposePrice = paymentPrice[0]?.GeneralPurpose || 0;

            // Calculating the total credits and total price
            const totalCredits = (subscriptionCredits + paidCredits);

            // console.log(totalCredits, totalPrice);

            if (totalCredits < total_cost_Calculation) {
                setLoading(false);
                setIsDisabled(false);
                // ToastEffect("error", "Not enough credits.");
                await alertBeforeAction(handlePurchaseCredits, "handleFunction2", "Low-Credits", { navigate });
                return;
            }
        }

        const data_process = {
            user_email: email,
            user_auth: process.env.REACT_APP_AUTH,
            user_prompt: data?.user_prompt || "",
            text_script: data?.video_script || text_script,
            accuracy: data?.accuracy || enableAccuracy,
            avatar: data?.avatar,
            avatar_id: data?.avatar_id || "",
            bg_music: data?.bg_music || "",
            isOwnBGM: data?.isOwnBGM,
            caption: data?.caption,
            effects: data?.effects,
            heygen_api_key: data?.heygen_api_key || "",
            midjourney_APIKey: data?.midjourney_APIKey || "",
            talking_photo_id: data?.talking_photo_id || "",
            toolId: process.env.REACT_APP_VIDEO_GEN_ID,
            toolName: process.env.REACT_APP_VIDEO_GEN_NAME,
            videoStyle: data?.videoStyle,
            voice: data?.voice,
            user_voice: data?.user_voice || "",
            voice_key: data?.voice_key || "",
            languageName: data?.languageName || "",
            languageID: data?.languageID || "",
            amount: total_cost_Calculation,
            aspect_ratio: data?.aspect_ratio,
            created: new Date(),
            image_price: Number(config_image),
            slide_count: data?.image_total_count || 10,
            image_prompt: JSON.stringify(data?.image_prompts) || "",
            video_script: data?.video_script || "",
            audio_path: data?.output_audio_path || "",
            audio_source: data?.audio || "",
            video_theme_config: data?.video_theme_config,
            video_theme_content: data?.video_theme_content,
            image_theme_config: data?.image_theme_config,
            image_theme_content: data?.image_theme_content,
            message: data?.message,
            task_id: data?.task_id,
            data_id: data?._id || data?.data_id,
            caption_option: data?.caption_option,
            slide_option: data?.slide_option,
        };

        // --------------------------------
        // --- display results
        // --------------------------------
        // setLoading(false);
        // setIsDisabled(false);
        // consoleLog("data_process", data_process);
        // consoleLog("data", data);
        // consoleLog("total_cost_Calculation", total_cost_Calculation);
        // consoleLog("generation_cost", generation_cost);
        // consoleLog("image_cost_calculation", image_cost_calculation);

        // --------------------------------
        //  --- extra function for testing
        // --------------------------------
        // await payVideo(email, total_cost_Calculation, "GeneralPurpose"); // pay video
        // adjustCreditsView("tokenBalance", "pay", total_cost_Calculation); // adjust credit UI display

        try {

            // --------------------------------
            // --- process video creation
            // --------------------------------
            await createVideo(data_process)
                .then(async (response) => {

                    const taskId = response?.data?.task_id;
                    // consoleLog("#createTheVideo", response);

                    // --------------------------------
                    // --- check if max request reach
                    // --------------------------------
                    if (response?.data?.detail == "request_limit") {
                        ToastEffect("error", "Maximum request limit reached.");
                        setLoading(false);
                        setIsDisabled(false);
                        return;
                    }

                    // --------------------------------
                    //  --- Check if task ID exist
                    // --------------------------------
                    if (!taskId) {
                        ToastEffect("error", "No task ID received!");
                        setLoading(false);
                        setIsDisabled(false);
                        return;
                    }

                    // --------------------------------
                    // --- save taskId
                    // --------------------------------
                    // await saveTaskId(email, process.env.REACT_APP_VIDEO_GEN_ID, "taskId", taskId);

                    // --------------------------------
                    // --- process payment check if free access or not
                    // --------------------------------
                    if (subscriptionData?.message === "freeAccess") {
                        let paymentKind = "freeAccess";

                        if (subscriptionData?.message === "subscribed" && isOldUser) {
                            paymentKind = "OldUser";
                        }

                        await recordCredits(email, taskId, "GeneralPurpose", paymentKind, total_cost_Calculation, image_cost_calculation, Number(config_image));
                    } else {

                        // --------------------------------
                        // --- record payment
                        // --------------------------------
                        if (data?.amount_status !== "paid") { // Check if the process has already been paid for, and if it's already paid, skip the payment (for accuracy video only).
                            await payVideo(email, total_cost_Calculation, "GeneralPurpose"); // pay video
                            await recordCredits(email, taskId, "GeneralPurpose", "payment", total_cost_Calculation, image_cost_calculation, Number(config_image)); // record credit cost
                            adjustCreditsView("tokenBalance", "pay", total_cost_Calculation); // adjust credit UI display
                        }
                    }

                });


            // finally re-fetch data
            setRefetchToolsData(oldVal => oldVal + 1);
            setLoading(false);

        } catch (error) {
            consoleLog("index.js - error createTheVideo", error);
            setLoading(false);
            setIsDisabled(false);
            return;
        }
    };

    // create custom video function
    const generateCustomVideo = async () => {
        setLoading(true);
        setIsDisabled(true);

        // check if Script or Idea is empty.
        if ((promptKind === "idea" && !userIdea) || (promptKind === "script" && !textScript)) {
            ToastEffect("error", "Idea or Script should not be empty");
            setLoading(false);
            setIsDisabled(false);
            return;
        }

        if (elvenLabsAPIValidity?.status) {
            setLoading(false);
            setIsDisabled(false);
            ToastEffect("error", `ElevenLabs: ${elvenLabsAPIValidity?.message}`);
            return;
        }

        // check if no ElevenLabs API key is provided
        if (enableVoice && !elevenLabsAPIKey && voiceOwnAPI) {
            setLoading(false);
            setIsDisabled(false);
            ToastEffect("error", "Please provide the ElevenLabs API key first.");
            return;
        }

        let voice_key;
        let language_name = "";
        let user_prompt = "";
        let text_script = "";
        let language_id = "";
        let user_voice = "";
        let custom_video_theme_config = "";
        let custom_video_theme_content = "";
        let aspect_ratio = "";
        let caption_option = "";

        // check if idea or script
        if (promptKind === "idea") {
            user_prompt = userIdea;
        } else {
            text_script = textScript;
        }

        // check if voice is on
        if (!enableVoice || !enableLanguange) {
            language_id = "";
            language_name = "";
        } else {
            language_id = languageID;
            language_name = languageName;
        }

        // check if voice is enable of disable
        if (enableVoice) {
            user_voice = voiceId;
        } else {
            user_voice = defaultVoiceName;
        }

        // check if own 11Labs API key
        if (voiceOwnAPI && enableVoice) {
            voice_key = elevenLabsAPIKey;
        } else {
            voice_key = "";
        }

        // check if video theme is custom
        if (videoTheme === "custom_theme") {
            custom_video_theme_config = videoTheme;
            custom_video_theme_content = customVideoThemePrompt;
        } else if (videoTheme === "default") {
            custom_video_theme_config = advanceOptionJsonData?.videoTheme[1]?.value;
            custom_video_theme_content = "";
        } else {
            custom_video_theme_config = videoTheme;
            custom_video_theme_content = "";
        }

        // check aspect ration
        if (videoAspectRatio === "default") {
            aspect_ratio = advanceOptionJsonData?.aspectRatio[1]?.value;
        } else {
            aspect_ratio = videoAspectRatio;
        }

        // caption option
        if (videoCaptionOption === "default") {
            caption_option = advanceOptionJsonData?.captionOption[1]?.value;
        } else {
            caption_option = videoCaptionOption;
        }

        // check credit balance
        const ceditBalance = await getCreditBalance(email);

        // check subscription status 
        if (subscriptionData?.message !== "freeAccess") {

            // Extracting repeated variable calls
            const subscriptionCredits = ceditBalance?.data?.message?.subs || 0;
            const paidCredits = ceditBalance?.data?.message?.paid || 0;
            // const generalPurposePrice = paymentPrice[0]?.GeneralPurpose || 0;

            // Calculating the total credits and total price
            const totalCredits = (subscriptionCredits + paidCredits);

            // console.log(totalCredits, totalPrice);

            if (totalCredits < customCreditCalculation) {
                setLoading(false);
                setIsDisabled(false);
                // ToastEffect("error", "Not enough credits.");
                await alertBeforeAction(handlePurchaseCredits, "handleFunction2", "Low-Credits", { navigate });
                return;
            }
        }

        const data = {
            user_auth: process.env.REACT_APP_AUTH,
            user_prompt: user_prompt,
            user_email: email,
            accuracy: enableAccuracy,
            text_script: text_script,
            aspect_ratio: aspect_ratio,
            toolId: process.env.REACT_APP_VIDEO_GEN_ID,
            toolName: process.env.REACT_APP_VIDEO_GEN_NAME,
            amount: customCreditCalculation,
            languageName: language_name,
            languageID: language_id,
            user_voice: user_voice,
            voice_key: voice_key,
            created: new Date(),
            video_theme_config: custom_video_theme_config,
            video_theme_content: custom_video_theme_content,
            caption_option: caption_option,
        };

        // TEST TO BE DISABLED
        setLoading(false);
        setIsDisabled(false);

        try {

            // --------------------------------
            // --- process custom video creation
            // --------------------------------
            await createCustomVideo(data)
                .then(async (response) => {

                    const taskId = response?.data?.task_id;

                    // --------------------------------
                    // --- check if max request reach
                    // --------------------------------
                    if (response?.data?.detail == "request_limit") {
                        ToastEffect("error", "Maximum request limit reached.");
                        setLoading(false);
                        setIsDisabled(false);
                        return;
                    }

                    // --------------------------------
                    // --- check if no task id return and throw an error message
                    // --------------------------------
                    if (!taskId) {
                        ToastEffect("error", "No task ID received!");
                        setLoading(false);
                        setIsDisabled(false);
                        return;
                    }

                    // --------------------------------
                    // --- save taskId
                    // --------------------------------
                    // await saveTaskId(email, process.env.REACT_APP_VIDEO_GEN_ID, "taskId", taskId);
                    // consoleLog("#generateCustomVideo", response);

                    // --------------------------------
                    // --- process payment check if free access or not
                    // --------------------------------
                    if (subscriptionData?.message === "freeAccess") {
                        let paymentKind = "freeAccess";


                        if (subscriptionData?.message === "subscribed" && isOldUser) {
                            paymentKind = "OldUser";
                        }

                        await recordCredits(email, taskId, "GeneralPurpose", paymentKind, customCreditCalculation);
                    } else {

                        // --------------------------------
                        // --- record payment
                        // --------------------------------
                        await payVideo(email, customCreditCalculation, "GeneralPurpose"); // pay video
                        await recordCredits(email, taskId, "GeneralPurpose", "payment", customCreditCalculation); // record credit cost
                        adjustCreditsView("tokenBalance", "pay", customCreditCalculation); // adjust credit UI display
                    }
                });

            // finally re-fetch data
            setRefetchToolsData(oldVal => oldVal + 1);
            setLoading(false);

        } catch (error) {
            consoleLog("index.js - error generateCustomVideo", error);
            setLoading(false);
            setIsDisabled(false);
            return;
        }

    };

    // Re-process failed generated videos
    const handleRetryProcess = async (data) => {

        // set loading by id
        setIsRetrying(prevVal => [...prevVal, data?.id]);

        // re-process cost
        const reProcessCost = Number(data?.amount);

        // check credit balance
        const ceditBalance = await getCreditBalance(email);

        // check subscription status 
        if (subscriptionData?.message !== "freeAccess") {

            // Extracting repeated variable calls
            const subscriptionCredits = ceditBalance?.data?.message?.subs || 0;
            const paidCredits = ceditBalance?.data?.message?.paid || 0;

            // Calculating the total credits and total price
            const totalCredits = (subscriptionCredits + paidCredits);

            if (totalCredits < reProcessCost) {
                // ToastEffect("error", "Not enough credits.");
                setIsRetrying(prevVal => prevVal.filter(index => index !== data?.id));
                await alertBeforeAction(handlePurchaseCredits, "handleFunction2", "Low-Credits", { navigate });
                return;
            }
        }

        const requiredData = {
            user_auth: process.env.REACT_APP_AUTH,
            user_email: data?.email,
            task_id: data?.task_id,
            data_id: data?._id,
        };

        try {

            // --------------------------------
            // --- process re trying the failed process
            // --------------------------------
            await handleReProcessVG(requiredData)
                .then(async (response) => {

                    const taskId = response?.data?.task_id;

                    // --------------------------------
                    // --- check if max request reach
                    // --------------------------------
                    if (response?.data?.detail == "request_limit") {
                        setIsRetrying(prevVal => prevVal.filter(index => index !== data?.id));
                        ToastEffect("error", "Maximum request limit reached.");
                        return;
                    }

                    // --------------------------------
                    // --- check if no task id return and throw an error message
                    // --------------------------------
                    if (!taskId) {
                        setIsRetrying(prevVal => prevVal.filter(index => index !== data?.id));
                        ToastEffect("error", "No task ID received!");
                        return;
                    }

                    // --------------------------------
                    // --- save the taskId
                    // --------------------------------
                    // await saveTaskId(email, process.env.REACT_APP_VIDEO_GEN_ID, "taskId", taskId);
                    // consoleLog("#handleRetryProcess", response?.data);

                    // --------------------------------
                    // --- process payment - disabled for re-process failed generation since auto refund has been removed
                    // --------------------------------
                    // if (subscriptionData?.message === "freeAccess") {
                    //     let paymentKind = "freeAccess";

                    //     if (subscriptionData?.message === "subscribed" && isOldUser) {
                    //         paymentKind = "OldUser";
                    //     }

                    //     await recordCredits(email, taskId, "GeneralPurpose", paymentKind, reProcessCost);
                    // } else {
                    //     // record payment
                    //     await payVideo(email, reProcessCost, "GeneralPurpose"); // pay video
                    //     await recordCredits(email, taskId, "GeneralPurpose", "payment", reProcessCost); // record credit cost
                    //     adjustCreditsView("tokenBalance", "pay", reProcessCost); // adjust credit UI display
                    // }

                });

            // finally re-fetch data
            setRefetchToolsData(oldVal => oldVal + 1);
            setIsRetrying(prevVal => prevVal.filter(index => index !== data?.id)); // disable loading effect

        } catch (error) {
            consoleLog("index.js - error handleRetryProcess", error);
            setIsRetrying(prevVal => prevVal.filter(index => index !== data?.id));
            return;
        }
    };

    // handle hide advance option
    const handleHideAdvanceOption = () => {
        if (isAdvanceOptionHidden) {
            setIsAdvanceOptionHidden(false);
        } else {
            setIsAdvanceOptionHidden(true);
        }
    };

    // handle open modal for custom video theme edit
    const handleEditCustomVideoTheme = () => {
        onOpenVTE();
    };

    // handle open modal for custom image theme edit
    const handleEditCustomImageTheme = () => {
        onOpenITE();
    };

    // handle save custom video theme prompt
    const handleSaveCustomVideoThemePrompt = () => {

        // declare variables
        let videoThemeVal = "";

        // conditional option for "videoTheme"
        if (videoTheme === "default") {
            videoThemeVal = advanceOptionJsonData?.videoTheme[1]?.value;
        } else {
            videoThemeVal = videoTheme;
        }

        // variable
        const kind = "videoTheme";

        // set state to true
        setSavingCustomVideoThemePrompt(true);

        // save configuration to the database
        debouncedSaveConfig(kind, email, pathname, kind, { value: videoThemeVal, content: customVideoThemePrompt });

        // set state to false
        setSavingCustomVideoThemePrompt(false); // done saving
        onOpenVTE(); // close modal
    };

    // handle save custom image theme prompt
    const handleSaveCustomImageThemePrompt = () => {

        // variable
        const kind = "imageTheme";

        // set state to true
        setSavingCustomImageThemePrompt(true); // saving

        // save configuration to the database
        debouncedSaveConfig(kind, email, pathname, kind, { value: imageTheme, content: customImageThemePrompt });

        // set state to false
        setSavingCustomImageThemePrompt(false); // done saving
        onOpenITE(); // close modal
    };

    return (

        <div className="lg:flex lg:justify-between min-h-screen pt-2 lg:pt-0">

            {isMobile && (
                <>
                    {/* <Header email={email} /> */}
                    <div className="flex justify-end max-[425px]:mx-[24px] md:mx-[54px] lg:mx-0">
                        <button
                            className={`${isAdvanceOptionHidden && 'active'} my-3 mx-5 w-[40px] p-1.5 text-white flex items-center justify-center text-xs relative`}
                            // onClick={handleToggleAdvancedOptions}
                            onClick={handleHideAdvanceOption}
                        >
                            {/* <img src={Vector} /> */}
                            <i className="fa-solid fa-bars text-xl" />
                        </button>
                    </div>
                </>
            )}

            {!isAdvanceOptionHidden && (
                <Tooltip
                    color="primary"
                    placement="right"
                    showArrow={true}
                    content="Show advance option"
                    className="text-base font-semibold"
                >
                    <div
                        onClick={handleHideAdvanceOption}
                        className="invisible lg:visible cursor-pointer top-[0.7%] left-[1%] absolute z-40 text-xl dark:text-gray-300 border px-2 py-1 rounded-md bg-gray-400/30 hover:bg-gray-300/80 dark:hover:bg-gray-200/10"
                    >
                        <i className="fa-regular fa-window-maximize fa-rotate-90" />
                    </div>
                </Tooltip>
            )}

            {isAdvanceOptionHidden && (
                // <div className="block lg:w-[600px] z-40 ml-1 max-h-screen overflow-y-auto bg-gray-500">
                <div className="block lg:w-[600px] z-10 ml-1 max-h-screen overflow-y-auto bg-gray-200 dark:bg-gray-700/75 relative">
                    <AdvancedOptions
                        generateCustomVideo={generateCustomVideo}
                        isMobile={isMobile}
                        tab={tab}
                        setTab={setTab}
                        foulRef={foulRef}
                        scriptHasFoulWords={scriptHasFoulWords}
                        foulPhrase={foulPhrase}
                        loading={loading}
                        voiceId={voiceId}
                        voicePreview={voicePreview}
                        backgroundMusic={backgroundMusic}
                        generateVideo={generateVideo}
                        onSelectedVoice={handleSelectedVoice}
                        handleDefaultVoice={handleDefaultVoice}
                        setDefaultVoiceName={setDefaultVoiceName}
                        defaultVoiceName={defaultVoiceName}
                        handleVideoAspectRatio={handleVideoAspectRatio}
                        handleVideoTheme={handleVideoTheme}
                        handleImageTheme={handleImageTheme}
                        videoAspectRatio={videoAspectRatio}
                        videoTheme={videoTheme}
                        imageTheme={imageTheme}
                        setVideoAspectRatio={setVideoAspectRatio}
                        setVideoTheme={setVideoTheme}
                        setImageTheme={setImageTheme}
                        onSelectedLanguage={handleSelectedLanguage}
                        setLanguageName={setLanguageName}
                        setLanguageID={setLanguageID}
                        languageID={languageID}
                        languageName={languageName}
                        setAddAnimation={setAddAnimation}
                        setEnableAccuracy={setEnableAccuracy}
                        setEnableCaption={setEnableCaption}
                        setEnableAvatar={setEnableAvatar}
                        setEnableLanguange={setEnableLanguange}
                        setEnableAvatarOption={setEnableAvatarOption}
                        setEnableVoice={setEnableVoice}
                        onSelectBackgroundMusic={handleSelectBackgroundMusic}
                        setElevenLabsAPIKey={setElevenLabsAPIKey}
                        setMidjourneyAPIKey={setMidjourneyAPIKey}
                        midjourneyAPIKey={midjourneyAPIKey}
                        elevenLabsAPIKey={elevenLabsAPIKey}
                        textScriptValue={textScript}
                        voiceModels={voiceModels}
                        isLoadingVoice={isVoiceLoading}
                        userIdea={userIdea}
                        textScript={textScript}
                        handleUserIdea={handleUserIdea}
                        isFieldError={isFieldError}
                        isMaximumRequests={isMaximumRequests}
                        hasFoulWords={hasFoulWords}
                        setHeyGenAPIKey={setHeyGenAPIKey}
                        heyGenAPIKey={heyGenAPIKey}
                        avatarId={avatarId}
                        avatarURL={avatarURL}
                        selectedTalkingPhoto={selectedTalkingPhoto}
                        selectedTalkingPhotoURL={selectedTalkingPhotoURL}
                        handleHideAdvanceOption={handleHideAdvanceOption}
                        setSelectedTalkingPhoto={setSelectedTalkingPhoto}
                        setSelectedTalkingPhotoURL={setSelectedTalkingPhotoURL}
                        setAvatarURL={setAvatarURL}
                        setAvatarId={setAvatarId}
                        isAPIKeyAvatarErr={isAPIKeyAvatarErr}
                        setVoiceId={setVoiceId}
                        setVoicePreview={setVoicePreview}
                        setBackgroundMusic={setBackgroundMusic}
                        avatarOption={avatarOption}
                        setAvatarOption={setAvatarOption}
                        creditCalculation={creditCalculation}
                        setCreditCalculation={setCreditCalculation}
                        customCreditCalculation={customCreditCalculation}
                        setCustomCreditCalculation={setCustomCreditCalculation}
                        setCustomAudioCreditCalculation={setCustomAudioCreditCalculation}
                        setCreateCreditCalculation={setCreateCreditCalculation}
                        promptKind={promptKind}
                        setPromptKind={setPromptKind}
                        handleEditCustomVideoTheme={handleEditCustomVideoTheme}
                        handleCustomVideoThemePrompt={handleCustomVideoThemePrompt}
                        handleSaveCustomVideoThemePrompt={handleSaveCustomVideoThemePrompt}
                        customVideoThemePrompt={customVideoThemePrompt}
                        setCustomVideoThemePrompt={setCustomVideoThemePrompt}
                        savingCustomVideoThemePrompt={savingCustomVideoThemePrompt}
                        handleEditCustomImageTheme={handleEditCustomImageTheme}
                        handleCustomImageThemePrompt={handleCustomImageThemePrompt}
                        handleSaveCustomImageThemePrompt={handleSaveCustomImageThemePrompt}
                        customImageThemePrompt={customImageThemePrompt}
                        setCustomImageThemePrompt={setCustomImageThemePrompt}
                        savingCustomImageThemePrompt={savingCustomImageThemePrompt}
                        setElvenLabsAPIValidity={setElvenLabsAPIValidity}
                        setTextScript={setTextScript}
                        voiceModelList={voiceModelList}
                        setVoiceModelList={setVoiceModelList}
                        isOpenSE={isOpenSE}
                        onCloseSE={onCloseSE}
                        onOpenSE={onOpenSE}
                        isOpenVTE={isOpenVTE}
                        onCloseVTE={onCloseVTE}
                        isOpenITE={isOpenITE}
                        onCloseITE={onCloseITE}
                        videoCaptionOption={videoCaptionOption}
                        handleCaptionOption={handleCaptionOption}
                        videoSlideOption={videoSlideOption}
                        handleSlideOption={handleSlideOption}
                        disabledOptions={disabledOptions}
                        selectedKeys={selectedKeys}
                        setSelectedKeys={setSelectedKeys}
                        selectedValue={selectedValue}
                        setIsCheckedCustomBGM={setIsCheckedCustomBGM}
                        isCheckedCustomBGM={isCheckedCustomBGM}
                        filteredOwnBGMData={filteredOwnBGMData}
                    />

                    {!isMobile && (
                        <Tooltip
                            color="primary"
                            placement="right"
                            showArrow={true}
                            content="Hide advance option"
                            className="text-base font-semibold"
                        >
                            <div
                                onClick={handleHideAdvanceOption}
                                className="cursor-pointer absolute top-2 right-2 z-50 text-xl dark:text-gray-300 border px-2 py-1 rounded-md bg-gray-400/30 hover:bg-gray-300/80 dark:hover:bg-gray-200/10"
                            >
                                <i className="fa-regular fa-window-maximize fa-rotate-90" />
                            </div>
                        </Tooltip>
                    )}

                </div>
            )}

            {/* Identifies if custom media is being triggered. */}
            {isCustomMedia || URLPage === URLValues?.customMedia && URLDataID ? (
                <>
                    {/* Custom Media UI v2.0 */}
                    <VG_CustomMedia
                        mediaSoureceData={mediaSoureceData}
                        enableVoice={enableVoice}
                        enableLanguange={enableLanguange}
                        languageID={languageID}
                        languageName={languageName}
                        elevenLabsAPIKey={elevenLabsAPIKey}
                        defaultVoiceName={defaultVoiceName}
                        voiceId={voiceId}
                        isOldUser={isOldUser}
                        subscriptionData={subscriptionData}
                        voiceOwnAPI={voiceOwnAPI}
                        isMobile={isMobile}
                        customAudioCreditCalculation={customAudioCreditCalculation}
                        voiceModelList={voiceModelList}
                        accuracyOwnAPI={accuracyOwnAPI}
                        enableAccuracy={enableAccuracy}
                        midjourneyAPIKey={midjourneyAPIKey}
                        imageTheme={imageTheme}
                        customImageThemePrompt={customImageThemePrompt}
                        setIsCustomMedia={setIsCustomMedia}
                        selectedTalkingPhoto={selectedTalkingPhoto}
                        avatarOption={avatarOption}
                        avatarId={avatarId}
                        enableAvatar={enableAvatar}
                        toolsDataParam={toolsDataParam}
                        setRefetchToolsData={setRefetchToolsData}
                        backgroundMusic={backgroundMusic}
                        addAnimation={addAnimation}
                        enableCaption={enableCaption}
                        videoAspectRatio={videoAspectRatio}
                        createCreditCalculation={createCreditCalculation}
                        creditCalculation={creditCalculation}
                        setSelected={setSelected}
                        selected={selected}
                        setDisabledOptions={setDisabledOptions}
                        videoSlideOption={videoSlideOption}
                        isCheckedCustomBGM={isCheckedCustomBGM}
                        filteredOwnBGMData={filteredOwnBGMData}
                    />
                </>
            ) : (
                <>
                    {/* Video Generator Table UI v2.0 */}
                    <VG_Table
                        setMediaSoureceData={setMediaSoureceData}
                        setIsCustomMedia={setIsCustomMedia}
                        setVideos={setVideos}
                        handleDeleteVideo={handleDeleteVideo}
                        isDeletingData={isDeletingData}
                        videos={videos}
                        handleRetryProcess={handleRetryProcess}
                        createTheVideo={createTheVideo}
                    />
                </>
            )}

        </div>
    );
};

export default GeneralPurpose;
