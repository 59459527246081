import React from "react";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import consoleLog from "../../../../../functions/consoleLog";

const RightSideTable = (props) => {

    const {
        transcriberData,
        progressstyle,
        percentageCalculator,
        isMobile,
        handleView,
        handleDeleteOnclick,
        isDeleting,
        deletingIDX,
        toolsDataStatus,
        refetchData,
    } = props;

    // console log activity
    // consoleLog("toolsDataStatus", toolsDataStatus);

    return (
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
            <thead className="bg-gray-50 dark:bg-gray-700">
                <tr>
                    <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                        Process
                    </th>
                    <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                        Date &amp; Time
                    </th>
                    <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-white">
                        Cost
                    </th>
                    <th scope="col" className="p-4 text-xs font-medium tracking-wider text-center text-gray-500 uppercase dark:text-white">
                        Status
                    </th>
                    <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase dark:text-white">
                        <span>Action</span>
                    </th>
                </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-800">

                {transcriberData?.map((data, idx) => {
                    const foundObject = toolsDataStatus.find((status) => status?.tools_status?.task_id === data?.task_id);
                    return (
                        <tr key={idx} className="bg-gray-50 hover:bg-gray-100 dark:bg-gray-600 dark:hover:bg-gray-500 border-t-2 dark:border-gray-500 ">
                            <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                                <div
                                    data-tooltip-id="tooltip-transcriber-taskID"
                                    data-tooltip-content={data?.title ? data?.title : data?.task_id}
                                    title={data?.title ? data?.title : data?.task_id}
                                    className="font-semibold truncate w-[16.5rem] cursor-help"
                                >
                                    <Tooltip id="tooltip-transcriber-taskID" />
                                    {/* {data?.task_id} */}
                                    {data?.title ? data?.title : data?.task_id}
                                </div>
                            </td>
                            <td className="p-4 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                                {moment(data?.created).format("MMM DD ,YYYY hh:mm A")}
                            </td>
                            <td className="p-4 text-sm font-semibold text-gray-900 whitespace-nowrap dark:text-gray-300">
                                <i className="fas fa-coins text-yellow-300" /> <code className="text-base">{(Number(data?.amount)).toFixed(2)}</code>
                            </td>
                            <td className="p-4 whitespace-nowrap text-center">

                                {foundObject?.tools_status?.task_id === data?.task_id && foundObject?.tools_status?.status === "processing" ? (
                                    <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                                        <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{ width: `${percentageCalculator(foundObject?.tools_status?.total, foundObject?.tools_status?.current)}%` }}>
                                            <code>{Number(percentageCalculator(foundObject?.tools_status?.total, foundObject?.tools_status?.current)).toFixed(0)}% / {foundObject?.tools_status?.status}</code>
                                        </div>
                                    </div>
                                ) : (
                                    <span className={data.status === "completed" ? progressstyle.complete_v2 : data.status === "processing" || data.status === "rebuilding" ? progressstyle.processsing_v2 : progressstyle.fail_v2} >
                                        <span className="relative capitalize px-3 py-0.5 transition-all ease-in duration-75 bg-white dark:bg-gray-600 rounded-md">
                                            {data.status === "error-transcriber-generate-video" ? "video generating fails" : data.status}
                                        </span>
                                    </span>
                                )}

                            </td>

                            <td className="px-6 py-4 text-right">

                                <div className="flex justify-end space-x-1">

                                    {(data.status === "failed" || data.status === "error") && (
                                        <div
                                            data-tooltip-id="tooltip-transcriber-error"
                                            data-tooltip-html={`
                                                <p class="break-normal ${isMobile ? "w-48" : "max-w-lg"}">
                                                ${data.status === "error" ? "Something went wrong!" : data.prompt}
                                                </p>
                                            `}
                                            data-tooltip-place="right-start"
                                            className="items-start justify-start text-start text-yellow-200 cursor-help"
                                        >
                                            <Tooltip id="tooltip-transcriber-error" />
                                            <i className="fa-regular fa-circle-question fa-lg" />
                                        </div>
                                    )}

                                    {/* {data?.prompt && data.status !== "processing" && (
                                        <button
                                            onClick={() => handlePlayVideo(data)}
                                            className="px-3 py-2 text-base font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        >
                                            <i className="fa-solid fa-film" />
                                        </button>
                                    )} */}

                                    {(data.status === "completed" || data.status === "rebuilding" || data.status === "error-transcriber-generate-video") && (
                                        <button
                                            data-tooltip-id="tooltip-transcriber-view"
                                            data-tooltip-content="View or edit result"
                                            onClick={() => handleView(data, idx)}
                                            type="button"
                                            className="px-3 py-2 text-base font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        >
                                            <Tooltip id="tooltip-transcriber-view" />
                                            <i className="fa-solid fa-pen-to-square" />
                                        </button>
                                    )}

                                    {data.status !== "processing" && (
                                        <button
                                            onClick={() => handleDeleteOnclick(data?._id, idx)}
                                            className="px-3 py-2.5 text-xs font-medium text-center inline-flex items-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                                        >
                                            {isDeleting && deletingIDX === idx ? (
                                                <i className="fa-solid fa-spinner fa-spin-pulse" />
                                            ) : (
                                                <i className="fa-solid fa-trash" />
                                            )}
                                        </button>
                                    )}

                                </div>

                            </td>

                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default RightSideTable;