import React, { useState, useEffect } from "react";
import { saveAdvanceConfig } from "../../API/chatAPI";

const AdvanceOption = (props) => {

    const {
        refetchAdvanceConfig,
        userData,
        advanceConfigs
    } = props;

    // console.log(userData);

    // use state
    const [tempValue, setTempValue] = useState(advanceConfigs[0]?.temp || 0.5); // 0.5
    const [topPValue, setTopPValue] = useState(advanceConfigs[0]?.topP || 0.9); // 0.9
    const [PP_Value, setPP_Value] = useState(advanceConfigs[0]?.pp || 0.3); // 0.3
    const [FP_Value, setFP_Value] = useState(advanceConfigs[0]?.fp || 0.3); // 0.3
    const [newdata, setNewData] = useState({});
    const [debouncedValue, setDebouncedValue] = useState(newdata.value);

    const handleSlideTemp = (event) => {
        const newValue = event.target.value;
        setTempValue(newValue);
        if (newValue <= 1 && newValue >= 0) {
            debounceFunction({ value: newValue, kind: "temp" });
        }
    };

    const handleSlideTopP = (event) => {
        const newValue = event.target.value;
        setTopPValue(newValue);
        if (newValue <= 1 && newValue >= 0) {
            debounceFunction({ value: newValue, kind: "topP" });
        }
    };

    const handleSlideFP = (event) => {
        const newValue = event.target.value;
        setFP_Value(newValue);
        if (newValue <= 2 && newValue >= 0) {
            debounceFunction({ value: newValue, kind: "fp" });
        }
    };

    const handleSlidePP = (event) => {
        const newValue = event.target.value;
        setPP_Value(newValue);
        if (newValue <= 2 && newValue >= 0) {
            debounceFunction({ value: newValue, kind: "pp" });
        }
    };

    const debounceFunction = (data) => {
        setNewData(data);
    };

    // Update debouncedValue after 500ms the user has stopped typing
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(newdata.value);
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, [newdata]);

    // Call handleMidjourneyAPIKey when debouncedValue changes
    useEffect(() => {
        handleDebounceFunction(debouncedValue);
    }, [debouncedValue]);

    // save debounced value
    const handleDebounceFunction = async (value) => {

        if (!value) {
            return;
        }

        const email = userData?.userData?.UserInfo?.email;
        await saveAdvanceConfig(email, newdata.kind, value)
            .then(() => {
                refetchAdvanceConfig();
            });

    };

    return (
        <>
            <div className="flex flex-row items-center justify-between text-xs mt-5 mb-2">
                <span className="block mb-2 text-sm text-white">Temperature</span>
                <input onChange={handleSlideTemp} value={tempValue} className="flex text-center justify-center h-6 w-10 bg-gray-300 rounded-md" />
            </div>
            <input
                onChange={handleSlideTemp}
                value={tempValue}
                type="range"
                min="0"
                max="1"
                step="0.01"
                id="default-range"
                className="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-700"
            />

            <div className="flex flex-row items-center justify-between text-xs mt-6 mb-2">
                <span className="block mb-2 text-xs text-white">Top P</span>
                <input onChange={handleSlideTopP} value={topPValue} className="flex text-center justify-center h-6 w-10 bg-gray-300 rounded-md" />
            </div>
            <input
                onChange={handleSlideTopP}
                value={topPValue}
                type="range"
                min="0"
                max="1"
                step="0.01"
                id="default-range"
                className="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-700"
            />

            <div className="flex flex-row items-center justify-between text-xs mt-6 mb-2">
                <span className="block mb-2 text-xs text-white">Frequency Penalty</span>
                <input onChange={handleSlideFP} value={FP_Value} className="flex text-center justify-center h-6 w-10 bg-gray-300 rounded-md" />
            </div>
            <input
                onChange={handleSlideFP}
                value={FP_Value}
                type="range"
                min="0"
                max="2"
                step="0.01"
                id="default-range"
                className="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-700"
            />

            <div className="flex flex-row items-center justify-between text-xs mt-6 mb-2">
                <span className="block mb-2 text-xs text-white">Presence Penalty</span>
                <input onChange={handleSlidePP} value={PP_Value} className="flex text-center justify-center h-6 w-10 bg-gray-300 rounded-md" />
            </div>
            <input
                onChange={handleSlidePP}
                value={PP_Value}
                type="range"
                min="0"
                max="2"
                step="0.01"
                id="default-range"
                className="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-700"
            />
        </>
    );
};

export default AdvanceOption;