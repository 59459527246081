// libraries
import React, { useEffect, useRef, useState } from "react";

// custom components
import consoleLog from "../../../../../../functions/consoleLog";

const Video = (props) => {

    // props
    const {
        video,
        orientation,
        selectedDimension,
        artisanSession,
        triggerVideoDisplay,
        setVideoData,
        onOpenMV,
    } = props;

    // default video source
    const defaultVideoLandscape = "https://storage.googleapis.com/shine-devtools/shinefy-files/shinefy_landscape_default.mp4";
    const defaultVideoPortrait = "https://storage.googleapis.com/shine-devtools/shinefy-files/shinefy_portrait_default.mp4";
    const videoSource = orientation === "Landscape" ? defaultVideoLandscape : defaultVideoPortrait;

    // react useRef
    const videoRef = useRef(null);

    // react useState
    const [currentVideo, setCurrentVideo] = useState(videoSource);

    // console log activity
    // consoleLog("currentVideo", currentVideo);

    // triggers to reload video from video tag
    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.load();
        }
    }, [currentVideo, artisanSession]);

    // triggers to set default if no video or video source is loading
    useEffect(() => {
        if (video || triggerVideoDisplay) {
            setCurrentVideo(videoSource); // Set to default video while loading
            const handleLoadedData = () => {
                setCurrentVideo(video || videoSource); // Set to actual video once loaded
            };

            const videoElement = videoRef.current;
            videoElement.addEventListener('loadeddata', handleLoadedData);

            return () => {
                videoElement.removeEventListener('loadeddata', handleLoadedData);
            };
        }
    }, [video, triggerVideoDisplay]);

    // handle open video play
    const handleVideoPlay = (data) => {

        // set data
        setVideoData(data);

        // call open modal
        onOpenMV();

        // console log activity
        // consoleLog("data", data);
    };

    return (
        <div className={`relative ${orientation === "Landscape" ? "lg:w-[40rem]" : "lg:w-[20rem]"} h-auto`}>

            <div
                className="absolute top-1/2 bottom-1/2 left-1/2 right-1/2 flex items-center justify-center z-10"
                onClick={() => handleVideoPlay({ artisanSession, videoSource: currentVideo, orientation })}
            >
                {/* <Spinner size="lg" label="Authenticating..." color="warning" /> */}
                <i className="fa-regular fa-circle-play text-8xl text-warning cursor-pointer" />
            </div>

            <video
                // controls
                ref={videoRef}
                className="w-full h-auto border-0 rounded-lg"
            >
                <source
                    src={currentVideo}
                    type="video/mp4"
                />
            </video>

        </div>
    );
};

export default Video;