// libraries
import React, { useContext } from "react";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import { Avatar } from "@nextui-org/react";

// custom components
import { checkName } from "../functions/subscriptionStatus";
import UserContext from "./UserContext";
import { calculateRemainingTime, displayPaidCreditsValue } from "../pages/shinefy/Tools/functions/general";
import { treuncateTo2Decimal } from "../functions/general";
import consoleLog from "../functions/consoleLog";

const MyPlanDetails = (props) => {

    // props
    const {
        handleBuyCredits,
        urlCardValue,
    } = props;

    // context method
    const {

        // others
        userData,
        userAvatar,
        subscriptionData,
        userLiveCreditBalance

    } = useContext(UserContext);

    // extracted value from "subscriptionData"
    const subscriptionMessage = subscriptionData?.message;

    // userData extracted variable values
    const isOldUser = userData?.UserInfo?.is_oldSubs;
    const p_credit_expiration = userData?.UserInfo?.p_credits_expiration;

    // console log activity
    // consoleLog("subscriptionData", subscriptionData);

    return (
        <div className="w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700 flex flex-col">
            <div className="flex items-center justify-between mb-4">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">Plan Details</h5>
                <div className={`text-sm font-medium px-1 rounded-md ${isOldUser ? "bg-yellow-500 text-gray-700" : "bg-sky-400 text-gray-700"} font-semibold`}>
                    <code>{isOldUser ? "Gold" : "New"}</code>
                </div>
            </div>
            <div className="flow-root">
                <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
                    <li className="py-3 sm:py-4">
                        <div className="flex items-center space-x-4">
                            {/* <div className="flex-shrink-0">
                                <img className="w-8 h-8 rounded-full" src="user.jpg" alt="Michael image" />
                            </div> */}
                            <Avatar
                                size="sm"
                                isBordered
                                src={userAvatar}
                            />
                            <div className="flex-1 min-w-0">
                                <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                    {checkName(userData?.UserInfo?.first_name, userData?.UserInfo?.last_name)}
                                </p>
                                <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                    {userData?.UserInfo?.email}
                                </p>
                            </div>

                            {/* <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                $97
                            </div> */}

                        </div>
                    </li>
                    <li className="py-3 sm:py-4">
                        <div className="flex items-center space-x-4">
                            <div className="flex-1 min-w-0">
                                <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                    Monthly Credits
                                </p>
                                <span
                                    data-tooltip-id="tooltip-reset-remaining-time-MMMDYYYY"
                                    data-tooltip-html={`
                                        <p class="break-normal text-sm font-semibold">
                                            ${moment(subscriptionData?.status?.subscription_interval || subscriptionData?.status?.valid_till).format("MMM D, YYYY - hh:mm A")}
                                        </p>
                                    `}
                                    className="text-xs font-medium text-gray-400"
                                >
                                    {calculateRemainingTime((subscriptionData?.status?.subscription_interval || subscriptionData?.status?.valid_till), "monthly_credits")}
                                    <Tooltip id="tooltip-reset-remaining-time-MMMDYYYY" />
                                </span>
                            </div>
                            <div className="inline-flex items-center text-lg font-semibold text-gray-900 dark:text-gray-400">
                                <code>{treuncateTo2Decimal(subscriptionData?.status?.credits)}</code>
                            </div>
                        </div>
                    </li>
                    <li className="py-3 sm:py-4">
                        <div className="flex items-center space-x-4">
                            <div className="flex-1 min-w-0">
                                <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                    Paid Credits
                                </p>
                                <span
                                    data-tooltip-id="tooltip-reset-remaining-time-MMMDYYYY"
                                    data-tooltip-html={`
                                        <p class="break-normal text-sm font-semibold">
                                            ${moment(p_credit_expiration).format("MMM D, YYYY - hh:mm A")}
                                        </p>
                                    `}
                                    className="text-xs font-medium text-gray-400"
                                >
                                    {calculateRemainingTime(userData?.UserInfo?.p_credits_expiration, "paid_credits")}
                                    <Tooltip id="tooltip-reset-remaining-time-p_credits-MMMDYYYY" />
                                </span>
                            </div>
                            <div className="inline-flex items-center text-lg font-semibold text-gray-900 dark:text-gray-400">
                                <code>{treuncateTo2Decimal(displayPaidCreditsValue(userData?.UserInfo?.p_credits_expiration, userLiveCreditBalance?.paidCredits))}</code>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <button
                disabled={subscriptionMessage === "expiredSubscription" ? true : false}
                onClick={handleBuyCredits}
                className={`relative ${urlCardValue === "buy-credits" && "border-y-4 border-x-4 border-sky-400"} ${subscriptionMessage === "expiredSubscription" ? "bg-gray-500 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-700"} focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-blue-900 mt-auto`}
            >
                {urlCardValue === "buy-credits" && (
                    <div className="absolute right-[0.5rem] -mt-[1.5rem]">
                        <span className="flex h-5 w-5">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-500 opacity-75" />
                        </span>
                    </div>
                )}
                Buy More Credits
            </button>

        </div>
    );
};

export default MyPlanDetails;