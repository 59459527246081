// libraries
import axios from "axios";
import debounce from "lodash.debounce";

// custom components
import { ToastEffect } from "../functions/ToastEffect";
import consoleLog from "../functions/consoleLog";

/** fetch tools log spent data */
export const fetchToolsLogSpentData = async () => {

    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/admin/log-spent`);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #fetchToolsLogSpentData");
        consoleLog("Error! fetchToolsLogSpentData", error);
        // throw error.response;
    }

};

/** fetch server activities */
export const fetchServerActivities = async () => {

    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/admin/activities`);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #fetchServerActivities");
        consoleLog("Error! fetchServerActivities", error);
    }

};

/** fetch login records */
export const fetchLoginRecords = async () => {

    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/admin/login-records`);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #fetchLoginRecords");
        consoleLog("Error! fetchLoginRecords", error);
    }

};

/** fetch account records */
export const fetchAccounts = async () => {

    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/admin/accounts`);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #fetchAccounts");
        consoleLog("Error! fetchAccounts", error);
    }

};

/** fetch active subscribers */
export const fetchActiveSubscribers = async () => {

    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/admin/subscribers`);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #fetchActiveSubscribers");
        consoleLog("Error! fetchActiveSubscribers", error);
    }

};

/** fetch all billing activities */
export const fetchBillingActivities = async () => {

    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/admin/billing-activities`);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #fetchBillingActivities");
        consoleLog("Error! fetchBillingActivities", error);
    }

};

/** fetch admin notifications */
export const fetchAdminNotifications = async () => {

    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/admin/admin-notif`);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #fetchAdminNotifications");
        consoleLog("Error! fetchAdminNotifications", error);
    }

};

/** fetch bot chats */
export const fetchBotchats = async () => {

    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/admin/bot-chats`);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #fetchBotchats");
        consoleLog("Error! fetchBotchats", error);
    }

};

/** fetch admin configs */
export const fetchAdminConfigs = async () => {

    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/admin/get-admin-configs`);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #fetchAdminConfigs");
        consoleLog("Error! fetchAdminConfigs", error);
    }

};

/** send interact message to the server for processing */
export const addMessageToInteract = async (data) => {

    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/admin/admin-interact`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #addMessageToInteract");
        consoleLog("Error! addMessageToInteract", error);
    }

};

/** send request to the server for notification status */
export const markNotificationAsRead = async (data) => {

    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/admin/mark-notif-as-read`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #markNotificationAsRead");
        consoleLog("Error! markNotificationAsRead", error);
    }

};

/** send request to the server for remove notification */
export const removeNotification = async (data) => {

    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/admin/remove-notif`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #removeNotification");
        consoleLog("Error! removeNotification", error);
    }

};

/** send request to the server for remove interact message */
export const removeInteractMessage = async (data) => {

    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/admin/remove-interact`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #removeInteractMessage");
        consoleLog("Error! removeInteractMessage", error);
    }

};

/** handle authenticate via google */
export const authenticateViaGoogle = async (data) => {

    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/auth/google-auth`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #authenticateViaGoogle");
        consoleLog("Error! authenticateViaGoogle", error);
    }

};

/** fetch Artisan sessions data from the server */
export const fetchArtisanSessions = (data) => {
    return new Promise((resolve, reject) => {
        debouncedFetchArtisanSessions(data, resolve, reject);
    });
};

// sub function for fetching Artisan sessions
const debouncedFetchArtisanSessions = debounce(async (data, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/artisan/get-sessions`, data);
        resolve(response);
    } catch (error) {
        consoleLog("debouncedFetchArtisanSessions", error);
        reject(error);
    }
}, 500);

/** fetch Own background music data from the server */
export const fetchOwnBGM = (data) => {
    return new Promise((resolve, reject) => {
        debouncedFetchOwnBGM(data, resolve, reject);
    });
};

// sub function for fetching own background music
const debouncedFetchOwnBGM = debounce(async (data, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/tools/get-own-BGM`, data);
        resolve(response);
    } catch (error) {
        consoleLog("debouncedFetchOwnBGM", error);
        reject(error);
    }
}, 500);

/** send request for GenerateScript */
export const generateScript = (data) => {
    return new Promise((resolve, reject) => {
        debouncedGenerateScript(data, resolve, reject);
    });
};

// sub function for GenerateScript
const debouncedGenerateScript = debounce(async (data, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/artisan/generate-script`, data);
        resolve(response);
    } catch (error) {
        consoleLog("debouncedGenerateScript", error);
        reject(error);
    }
}, 500);

/** send request to the server to save modified script */
export const saveModifiedScript = (data) => {
    return new Promise((resolve, reject) => {
        debouncedSaveModifiedScript(data, resolve, reject);
    });
};

// debounced sub function
const debouncedSaveModifiedScript = debounce(async (data, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/artisan/save-modified-script`, data);
        resolve(response);
    } catch (error) {
        consoleLog("debouncedSaveModifiedScript", error);
        reject(error);
    }
}, 1000);

/** fetch video from pexel using keyword */
export const fetchVideosFromPexel = (data) => {
    return new Promise((resolve, reject) => {
        debouncedFetchVideosFromPexel(data, resolve, reject);
    });
};

// debounced sub function
const debouncedFetchVideosFromPexel = debounce(async (data, resolve, reject) => {
    try {

        const params = {
            query: data?.keywords,
            per_page: data?.perPage,
            orientation: data?.orientation,
            size: "HD",
        };

        const headers = {
            Authorization: process.env.REACT_APP_PEXELS_API_KEY, // Use your Pexels API key
        };

        const response = await axios.get(`${process.env.REACT_APP_PEXELS_ENDPOINT}/videos/search`, {
            headers: headers,
            params: params,
        });

        resolve(response);
    } catch (error) {
        consoleLog("debouncedFetchVideosFromPexel", error);
        reject(error);
    }
}, 500);

/** send request to the server for video source updates */
export const videoSourceUpdateRequest = (data) => {
    return new Promise((resolve, reject) => {
        debouncedvideoSourceUpdateRequest(data, resolve, reject);
    });
};

// debounced sub function
const debouncedvideoSourceUpdateRequest = debounce(async (data, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/artisan/update-video-source`, data);
        resolve(response);
    } catch (error) {
        consoleLog("debouncedvideoSourceUpdateRequest", error);
        reject(error);
    }
}, 1000);

/** update caption data */
export const updateArtisanCaptionData = (data) => {
    return new Promise((resolve, reject) => {
        debouncedUpdateArtisanCaptionData(data, resolve, reject);
    });
};

// debounced sub function
const debouncedUpdateArtisanCaptionData = debounce(async (data, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/artisan/update-caption-data`, data);
        resolve(response);
    } catch (error) {
        consoleLog("debouncedUpdateArtisanCaptionData", error);
        reject(error);
    }
}, 500);

/** send request to the server to rename BGM data */
export const renameBGMData = (data) => {
    return new Promise((resolve, reject) => {
        debouncedRenameBGMData(data, resolve, reject);
    });
};

// debounced sub function
const debouncedRenameBGMData = debounce(async (data, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/tools/rename-BGM`, data);
        resolve(response);
    } catch (error) {
        consoleLog("debouncedReCraftVideoContent", error);
        reject(error);
    }
}, 500);

/** send request to the server to delete BGM data */
export const deleteBGMData = (data) => {
    return new Promise((resolve, reject) => {
        debouncedDeleteBGMData(data, resolve, reject);
    });
};

// debounced sub function
const debouncedDeleteBGMData = debounce(async (data, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/tools/delete-BGM`, data);
        resolve(response);
    } catch (error) {
        consoleLog("debouncedReCraftVideoContent", error);
        reject(error);
    }
}, 500);

/** send request to the server for resolving the issue */
export const resolveIssue = (data) => {
    return new Promise((resolve, reject) => {
        debouncedResolveIssue(data, resolve, reject);
    });
};

// debounced sub function
const debouncedResolveIssue = debounce(async (data, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/admin/resolve-issue`, data);
        resolve(response);
    } catch (error) {
        consoleLog("debouncedResolveIssue", error);
        reject(error);
    }
}, 500);

/** Send request to the server to send DM to all guild members */
export const sendDMToAll = (data) => {
    return new Promise((resolve, reject) => {
        debouncedSendDMToAll(data, resolve, reject);
    });
};

// debounced sub function
const debouncedSendDMToAll = debounce(async (data, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/admin/send-dm`, data);
        resolve(response);
    } catch (error) {
        consoleLog("debouncedSendDMToAll", error);
        reject(error);
    }
}, 500);

/** update bot chat session status */
export const updateBotChatSessionStatus = (data) => {
    return new Promise((resolve, reject) => {
        debouncedUpdateBotChatSessionStatus(data, resolve, reject);
    });
};

// debounced sub function
const debouncedUpdateBotChatSessionStatus = debounce(async (data, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/admin/update-bot-chat-session-status`, data);
        resolve(response);
    } catch (error) {
        consoleLog("debouncedUpdateBotChatSessionStatus", error);
        reject(error);
    }
}, 500);

/** send dm to user */
export const sendDMToTheUser = (data) => {
    return new Promise((resolve, reject) => {
        debouncedSendDMToTheUser(data, resolve, reject);
    });
};

// debounced sub function
const debouncedSendDMToTheUser = debounce(async (data, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/admin/send-admin-dm`, data);
        resolve(response);
    } catch (error) {
        consoleLog("debouncedSendDMToTheUser", error);
        reject(error);
    }
}, 500);

/** search guild member */
export const searchGuildMember = (data) => {
    return new Promise((resolve, reject) => {
        debouncedSearchGuildMember(data, resolve, reject);
    });
};

// debounced sub function
const debouncedSearchGuildMember = debounce(async (data, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/admin/search-guild-member`, data);
        resolve(response);
    } catch (error) {
        consoleLog("debouncedSearchGuildMember", error);
        reject(error);
    }
}, 500);

/** search guild channel */
export const searchGuildChannel = (data) => {
    return new Promise((resolve, reject) => {
        debouncedSearchGuildChannel(data, resolve, reject);
    });
};

// debounced sub function
const debouncedSearchGuildChannel = debounce(async (data, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/admin/search-guild-channel`, data);
        resolve(response);
    } catch (error) {
        consoleLog("debouncedSearchGuildChannel", error);
        reject(error);
    }
}, 500);