import React from "react";

import "./index.css";

const ModalV2 = ({
    isOpen,
    onClose,
    children,
    handleOnSuccess,
    isDeleteIsLoading,
}) => {
    if (!isOpen) return null;

    return (
        <div onClick={onClose} className="absolute z-10 modal-overlay modalV2">
            <div
                onClick={(e) => e.stopPropagation()}
                className="modal-content w-[395px] h-[305px]"
            >
                {children}
                <div className="flex justify-center mt-4">
                    <button
                        className="close-button fontKarla bg-blue-600 hover:bg-blue-500 rounded-md p-1 px-2 text-white fontMontserrat flex items-center justify-center mr-[17px]"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        disabled={isDeleteIsLoading ? true : false}
                        className="confirm-button fontKarla rounded-md p-1 px-2 bg-red-600 hover:bg-red-500 text-white fontMontserrat flex items-center justify-center"
                        onClick={handleOnSuccess}
                    >
                        {isDeleteIsLoading ? (
                            <i className="fa-solid fa-spinner fa-spin-pulse text-base" />
                        ) : (
                            "Delete"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModalV2;
