// libraries
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar } from "@nextui-org/react";

// image
import shinefy_img from "../constant/images/Shinefy.png";

// custom components
import { LoadingEffect2, SideNavMenuCedits, SideNavMenuDropDown, SideNavMenuLink, SideNavNotifications } from "./SideNavMenu";
import { getStyle, getStatus, checkName } from "../functions/subscriptionStatus";
import AvatarImage from "./AvatarImage";
import { getServerRestartTime } from "../API/authAPI";
import CountDownTimer from "./CountDownTimer";
import consoleLog from "../functions/consoleLog";

const SideNav = (props) => {

    // props
    const {
        isLoading,
        userData,
        userAvatar,
        subscriptionData,
        discordData,
        logout,
        notifData,
        // totalSumNotifData,
    } = props;

    // sse navigate
    const navigate = useNavigate();

    // use ref
    const dropdownRef = useRef(null);
    const sideNavRef = useRef(null);

    // use location
    const location = useLocation();
    const { pathname } = location;

    // react components
    const [isOpen, setIsOpen] = useState(false);
    const [showSideNav, setShowSideNav] = useState(false);
    const [showAIVideoTools, setShowAIVideoTools] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [remainingTime, setRemainingTime] = useState(null);
    const [showAIGallery, setShowAIGallery] = useState(false);

    const [isSelected, setIsSelected] = useState(() => {
        const storedTheme = localStorage.getItem('theme');
        if (storedTheme) {
            return storedTheme === 'dark';
        } else {
            return window.matchMedia("(prefers-color-scheme: dark)").matches;
        }
    });
    const [currentTheme, setCurrentTheme] = useState(() => {
        const storedTheme = localStorage.getItem('theme');
        if (storedTheme) {
            return storedTheme;
        } else {
            return window.matchMedia("(prefers-color-scheme: dark)").matches ? 'dark' : 'light';
        }
    });

    // console log activity
    // consoleLog("userData", userData?.UserInfo?.role);

    // handle switch theme dark | light
    useEffect(() => {
        const handleThemeMode = () => {
            if (isSelected) {
                localStorage.setItem('theme', 'dark');
                document.documentElement.classList.add("dark");
            } else {
                localStorage.setItem('theme', 'light');
                document.documentElement.classList.remove("dark");
            }
            setCurrentTheme(localStorage.getItem('theme'));
        };
        handleThemeMode();
    }, [isSelected]);

    // handle switch theme dark | light
    const handleTheme = () => {
        if (localStorage.theme === "dark") {
            localStorage.theme = "light";
            document.documentElement.classList.remove("dark");
        } else {
            localStorage.theme = "dark";
            document.documentElement.classList.add("dark");
        }
        setCurrentTheme(localStorage.theme);
    };

    // fetch server restart time
    useEffect(() => {
        const fetchRestartTime = async () => {
            await getServerRestartTime()
                .then((response) => {
                    setRemainingTime(parseTime(response?.data?.remaining_time));
                    // consoleLog("response", response?.data);
                });
        };
        fetchRestartTime();
    }, []);

    // Parse the initial time and convert to milliseconds
    const parseTime = (timeString) => {
        const [hours, minutes, seconds, ms] = timeString.split(/[:.]/);
        return (
            parseInt(hours, 10) * 60 * 60 * 1000
            + parseInt(minutes, 10) * 60 * 1000
            + parseInt(seconds, 10) * 1000
            // + parseInt(ms, 10)
        );
    };

    useEffect(() => {
        // Interval to update the remaining time
        const interval = setInterval(() => {
            setRemainingTime((prevTime) => {
                if (prevTime <= 0) {
                    clearInterval(interval);
                    return 0;
                }
                return prevTime - 10; // Update the time by decreasing 10 milliseconds
            });
        }, 10); // 10 ms for smooth countdown

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [remainingTime, isOpen]);

    const formatTime = (ms) => {
        const hours = Math.floor(ms / (1000 * 60 * 60));
        const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((ms % (1000 * 60)) / 1000);
        // const milliseconds = ms % 1000;

        return {
            hrs: hours.toString().padStart(2, '0'),
            min: minutes.toString().padStart(2, '0'),
            sec: seconds.toString().padStart(2, '0'),
            // milliseconds: milliseconds.toString().padStart(3, '0')
        };
    };

    // toggle menu
    function handleMenu() {
        setIsOpen(!isOpen);
    }

    // handle navigate
    const handleNavigate = (location) => {
        navigate(location);
    };

    // handle click outside effect
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    // animate effect
    useEffect(() => {
        // dropdownRef.current && autoAnimate(dropdownRef.current); // disabled side nav bottom user menu
    }, [dropdownRef]);

    // handle click outside effect
    useEffect(() => {
        function handleClickOutside(event) {
            if (sideNavRef.current && !sideNavRef.current.contains(event.target)) {
                setShowSideNav(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [sideNavRef]);

    // toggle show | hide tools
    const handleShowAIVideoTools = () => {
        setShowAIVideoTools(!showAIVideoTools);
    };

    // toggle show | hide gallery
    const handleShowAIGallery = () => {
        setShowAIGallery(!showAIGallery);
    };

    // toggle show | hide settings
    const handleShowSettings = () => {
        setShowSettings(!showSettings);
    };

    // toggle show | hide side nav
    const handleShowSideNav = () => {
        setShowSideNav(!showSideNav);
    };

    return (
        <>
            <button
                onClick={handleShowSideNav}
                type="button"
                className="inline-flex items-center p-2 my-1 ml-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
                <span className="sr-only">Open sidebar</span>
                <i className="fa-solid fa-bars-staggered" />
            </button>

            <aside ref={sideNavRef} id="sidebar" aria-label="Sidebar" className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${!showSideNav && "-translate-x-full"} sm:translate-x-0`}>
                <div className="shadow-lg shadow-gray-400 dark:shadow-lg bg-gray-100 dark:bg-gray-800 sm:fixed inset-y-0 w-[260px] flex-col fixed md:inset-y-0 md:flex md:w-[260px] md:flex-col">
                    <div className="flex h-full min-h-0 flex-col">
                        <div className="flex pr-3 py-6 backgroundCustom items-center bg-gray-700/30 transition-colors duration-200 text-gray-800 dark:text-white mb-2 flex-shrink-0 justify-center">
                            <img decoding="async" data-nimg="intrinsic" src={shinefy_img} className="w-14 h-auto mr-2" alt="Image" />
                            <h1 className="font-bold text-3xl">Shinefy</h1>
                        </div>

                        <div className="scrollbar-trigger flex h-full w-full flex-1 items-start border-gray-800 dark:border-white/20">
                            <nav className="flex h-full flex-1 flex-col space-y-1 p-2">

                                <div className="scrollbar-thumb-slate-400 flex-col flex-1 overflow-y-auto border-b border-gray-400 dark:border-white/20 scrollbar-thin scrollbar-track-gray-transparent scrollbar-thumb-rounded-md">
                                    <ul className="space-y-1">
                                        <SideNavMenuLink isLoading={isLoading} path={pathname} icon="fa-solid fa-gauge" label="Dashboard" location="/menu" />
                                        <SideNavMenuLink isLoading={isLoading} path={pathname} icon="fa-solid fa-message" label="AI Chat" location="/ai_chat" />
                                        {/* <SideNavMenuLink isLoading={isLoading} path={pathname} icon="fa-solid fa-magnifying-glass-chart" label="Viral Topic Finder" location="/viral_topic" /> */}
                                        <SideNavMenuDropDown
                                            isLoading={isLoading}
                                            path={pathname}
                                            isHiddenFunction={handleShowAIVideoTools}
                                            isHidden={showAIVideoTools}
                                            setIsHidden={setShowAIVideoTools}
                                            icon="fa-solid fa-circle-play"
                                            label="AI Tools"
                                            dropDown={[
                                                { label: "Image2Video", location: "/general-purpose" },
                                                { label: "Idea2Video", location: "/video-gen" },
                                                { label: "Transcriber", location: "/transcribe" },
                                            ]}
                                        />
                                        <SideNavMenuDropDown
                                            isLoading={isLoading}
                                            path={pathname}
                                            isHiddenFunction={handleShowAIGallery}
                                            isHidden={showAIGallery}
                                            setIsHidden={setShowAIGallery}
                                            icon="fa-solid fa-photo-film"
                                            label="AI Gallery"
                                            dropDown={[
                                                { label: "Videos", location: "/videos" },
                                                { label: "Images", location: "/images" },
                                            ]}
                                        />
                                        {/* <li>
                                            <a href="#" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                                <svg aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-gray-400 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path><path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path></svg>
                                                <span className="flex-1 ml-3 whitespace-nowrap">Messages</span>
                                                <span className="inline-flex justify-center items-center w-5 h-5 text-xs font-semibold rounded-full text-blue-800 bg-blue-100 dark:bg-blue-200 dark:text-blue-800">
                                                    6
                                                </span>
                                            </a>
                                        </li> */}
                                        {/* <SideNavMenuLink isLoading={isLoading} path={pathname} icon="fa-solid fa-gauge" label="Shine Ranker" location={process.env.REACT_APP_SHINERANKER_APP} kind="image" type="link" /> */}
                                    </ul>
                                    <ul className="pt-3 mt-3 space-y-2 border-t border-gray-200 dark:border-gray-700">

                                        <SideNavNotifications
                                            isLoading={isLoading}
                                            path={pathname}
                                            icon="fa-solid fa-bell"
                                            label="Notifications"
                                            location="/notif"
                                            notif={notifData}
                                        />

                                        <SideNavMenuDropDown
                                            isLoading={isLoading}
                                            path={pathname}
                                            isHiddenFunction={handleShowSettings}
                                            isHidden={showSettings}
                                            setIsHidden={setShowSettings}
                                            icon="fa-solid fa-gear"
                                            label="Settings"
                                            dropDown={[
                                                { label: "Account", location: "/account" }
                                            ]}
                                        />
                                    </ul>
                                </div>

                                <ul className="space-y-1">

                                    {userData?.UserInfo?.role === 9999 || userData?.UserInfo?.role === 5150 && (
                                        <SideNavMenuLink
                                            isLoading={isLoading}
                                            path={pathname}
                                            icon="fa-solid fa-user-secret"
                                            label="Admin"
                                            location="/admin"
                                        />
                                    )}

                                    <div className={`${userData?.UserInfo?.role === 9999 || userData?.UserInfo?.role === 5150 && "border-t border-gray-400 dark:border-white/20"} pt-2`}>

                                        <SideNavMenuCedits
                                            isLoading={isLoading}
                                            path={pathname}
                                            subscriptionData={subscriptionData}
                                            icon="fa-solid fa-coins"
                                            label="Credits"
                                            location="/my_plan"
                                        />

                                        <div ref={dropdownRef} className="group relative mt-1" data-headlessui-state="open">
                                            {isLoading ? (<LoadingEffect2 />) : (
                                                <div
                                                    onClick={handleMenu}
                                                    title={userData?.UserInfo?.email}
                                                    className={`${isOpen && "bg-gray-300 dark:bg-gray-700"} flex items-center cursor-pointer p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700 group`}
                                                >
                                                    <span className="flex w-full flex-row justify-between">

                                                        {/* <span className="gold-new-button flex items-center w-[1.4rem] mr-3 font-semibold image-parent rounded-full">
                                                            <AvatarImage userData={{ discordData }} classData="fa-solid fa-credit-card rounded-xl text-xl text-gray-400 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                                                        </span> */}

                                                        <Avatar
                                                            // size="sm"
                                                            isBordered
                                                            src={userAvatar}
                                                            className="w-6 h-6 text-tiny mr-3"
                                                        />

                                                        <div className="capitalize grow overflow-hidden text-ellipsis whitespace-nowrap text-left truncate w-0">
                                                            <span className="font-semibold">
                                                                {checkName(userData?.UserInfo?.first_name, userData?.UserInfo?.last_name)}
                                                            </span>
                                                        </div>
                                                    </span>
                                                    <i className="fa-solid fa-ellipsis" />
                                                </div>
                                            )}

                                            {isOpen && (
                                                <div className="absolute left-0 bottom-full z-20 mb-1 w-full overflow-hidden rounded-md bg-gray-400 dark:bg-gray-700 py-1.5 outline-none opacity-100 translate-y-0" aria-labelledby="headlessui-menu-button-:r1c:" id="headlessui-menu-items-:r45:" role="menu" tabIndex="0" data-headlessui-state="open">
                                                    <nav role="none">
                                                        <div
                                                            // onClick={() => handleNavigate("/contact_us")}
                                                            target="_blank"
                                                            id="headlessui-menu-item-:r46:"
                                                            role="menuitem"
                                                            tabIndex="-1"
                                                            data-headlessui-state=""
                                                            // className={`${pathname === "/contact_us" && "bg-gray-600"} flex py-3 px-3 items-center gap-3 transition-colors duration-200 text-white cursor-pointer text-sm hover:bg-gray-600`}
                                                            className="flex justify-center items-center transition-colors duration-200 text-white text-sm"
                                                        >
                                                            {/* <i className="fa-solid fa-paper-plane" />
                                                            Contact Us */}
                                                            {/* {formatTime(remainingTime)} */}
                                                            <CountDownTimer
                                                                formatTime={formatTime}
                                                                remainingTime={remainingTime}
                                                            />

                                                        </div>
                                                        <div className="my-1.5 h-px bg-white/20" role="none" />

                                                        {/* my palan dashboard */}
                                                        <button
                                                            onClick={() => handleNavigate("/my_plan")}
                                                            type="button"
                                                            className={`${pathname === "/my_plan" && "bg-gray-600"} flex w-full items-center gap-2.5 py-3 px-3 text-sm transition-colors duration-200 text-white hover:bg-gray-600`}
                                                        >
                                                            <div className="-ml-0.5 w-5 flex-shrink-0">
                                                                <div className="relative flex">
                                                                    <span className="image-parent rounded-full">
                                                                        <i className="fa-solid fa-user-tie" />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="capitalize grow overflow-hidden text-ellipsis whitespace-nowrap text-left">
                                                                My Plan
                                                            </div>
                                                            <code className={`text-xs font-semibold ${getStyle(subscriptionData?.message)} p-[2.5px] rounded-md`}>
                                                                {getStatus(subscriptionData?.message)}
                                                            </code>
                                                        </button>

                                                        {/* admin dashboard */}
                                                        {userData?.UserInfo?.role === 9999 && (
                                                            <button
                                                                // onClick={() => handleNavigate("/admin")}
                                                                onClick={() => window.location.href = "/admin"}
                                                                type="button"
                                                                className={`${pathname === "/admin" && "bg-gray-600"} flex w-full items-center gap-2.5 py-3 px-3 text-sm transition-colors duration-200 text-white hover:bg-gray-600`}
                                                            >
                                                                <div className="-ml-0.5 w-5 flex-shrink-0">
                                                                    <div className="relative flex">
                                                                        <span className="image-parent rounded-full">
                                                                            <i className="fa-solid fa-user-shield" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="capitalize grow overflow-hidden text-ellipsis whitespace-nowrap text-left">
                                                                    Dashboard
                                                                </div>
                                                                <code className="text-xs font-semibold bg-secondary p-[2.5px] rounded-md">
                                                                    Admin
                                                                </code>
                                                            </button>
                                                        )}

                                                        <div onClick={handleTheme} className="flex py-3 px-3 items-center gap-3 transition-colors duration-200 text-white cursor-pointer text-sm hover:bg-gray-600" id="headlessui-menu-item-:r48:" role="menuitem" tabIndex="-1" data-headlessui-state="">
                                                            {currentTheme === "light" ? (
                                                                <i className="fa-regular fa-moon" />
                                                            ) : (
                                                                <i className="fa-solid fa-sun" />
                                                            )}
                                                            {currentTheme === "light" ? "Dark mode" : "Light mode"}
                                                        </div>

                                                        <div className="my-1.5 h-px bg-white/20" role="none"></div>
                                                        <div onClick={logout} className="flex py-3 px-3 items-center gap-3 transition-colors duration-200 text-white cursor-pointer text-sm hover:bg-gray-600" id="headlessui-menu-item-:r49:" role="menuitem" tabIndex="-1" data-headlessui-state="">
                                                            <i className="fa-solid fa-right-from-bracket" />
                                                            Log out
                                                        </div>
                                                    </nav>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    );
};

export default SideNav;