// libraries
import React, { useState } from "react";
import { RadioGroup, Radio, cn, Select, SelectItem } from "@nextui-org/react";

// custom components
import { captionPosition, googleFontsData } from "../Utils/data";
import consoleLog from "../../../../../functions/consoleLog";
import { cachedConfig } from "../../../../../functions/general";

const FontsTab = (props) => {

    // props
    const {
        selectedFont,
        setSelectedFont,
        setSelectedFontColor,
        selectedFontColor,
        setSelectedCaptionPosition,
        selectedCaptionPosition,
    } = props;

    // filter and to get the font style
    const fontStyle = googleFontsData?.find((data) => data?.fontName === selectedFont);

    // console log activity
    // consoleLog("selectedFontColor", selectedFontColor);
    // consoleLog("selectedFontPosition", selectedFontPosition);

    // const handle selected font
    const handleSelectedFont = async (font) => {

        // set selected font
        setSelectedFont(font);

        // cached selected voice model
        await cachedConfig("captionFont", font);

        // console log activity
        // consoleLog("font", font);
    };

    // handle selected color
    const handleSelectedColor = async (e) => {

        // extracted value
        const color = e.target.value;

        // set selected color
        setSelectedFontColor(color);

        // cached selected color
        await cachedConfig("fontColor", color);

        // console log activity
        // consoleLog("color", color);
    };

    // handle selected position
    const handleSelectedPosition = async (e) => {

        // extracted value
        const position = e.target.value;

        // set selected position
        setSelectedCaptionPosition(position);

        // cached selected position
        await cachedConfig("fontPosition", position);

        // console log activity
        consoleLog("position", position);
    };

    return (
        <div className="lg:grid lg:grid-cols-1 gap-2 w-[21rem] lg:w-[36.5rem]">

            <div className="lg:grid lg:grid-cols-2 gap-2">

                {/* left side */}
                <div className="h-auto mb-3 lg:mb-0 space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:p-6">

                    <p className="text-xl font-semibold text-gray-900 dark:text-white">Selected Font</p>

                    <div className="flex items-center justify-between gap-4">
                        <dt className="text-lg font-semibold text-gray-500 dark:text-gray-400">
                            Font
                        </dt>
                        <dd style={{ color: selectedFontColor }} className={`${fontStyle?.customName} text-2xl font-medium text-gray-900 dark:text-white`}>
                            {selectedFont}
                        </dd>
                    </div>

                </div>

                {/* right side */}
                <div className="h-auto mb-3 lg:mb-0 space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:p-6">
                    <p className="text-xl font-semibold text-gray-900 dark:text-white">Caption Option</p>
                    <div className="flex justify-between items-center gap-2">

                        <Select
                            size="sm"
                            variant="bordered"
                            label="Position"
                            className="w-full"
                            selectedKeys={[selectedCaptionPosition]}
                            onChange={handleSelectedPosition}

                        >
                            {captionPosition.map((item) => (
                                <SelectItem key={item.value}>
                                    {item.label}
                                </SelectItem>
                            ))}
                        </Select>

                        <input
                            type="color"
                            value={selectedFontColor}
                            onChange={handleSelectedColor}
                            className="p-1 h-11 w-[5rem] bg-white border dark:bg-neutral-800 border-gray-200 cursor-pointer rounded-lg dark:border-neutral-700"
                        />

                    </div>
                </div>
            </div>

            {/* right side card */}
            <div className="space-y-3 overflow-y-auto max-h-[40rem]">
                <RadioGroup
                    // label="Plans"
                    // description="Selected plan can be changed at any time."
                    value={selectedFont}
                    onValueChange={(font) => handleSelectedFont(font)}
                >

                    {googleFontsData?.map((data, idx) => {
                        return (
                            <div key={idx}>
                                <Radio
                                    value={data?.fontName}
                                    size="lg"
                                    classNames={{
                                        base: cn(
                                            "inline-flex m-0 bg-gray-100 hover:bg-gray-100/80 dark:bg-gray-900 dark:hover:bg-gray-900/80 items-center justify-between",
                                            "flex-row cursor-pointer rounded-lg gap-4 p-4 border-2 border-transparent",
                                            "data-[selected=true]:border-primary"
                                        ),
                                    }}
                                >
                                    <p className="font-sans font-semibold">
                                        {data?.fontName}
                                    </p>
                                    <p style={{ color: selectedFontColor }} className={`${data?.customName} text-2xl truncate w-[14.5rem] lg:w-[30rem] text-gray-400`}>
                                        {data?.sampleText}
                                    </p>
                                </Radio>
                            </div>
                        );
                    })}

                </RadioGroup>
            </div>

        </div>
    );
};

export default FontsTab;