// libraries
import React, { useContext, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

// css
import "react-tooltip/dist/react-tooltip.css";

// images
import ErrorImage from "../../Images/ErrorImage.png";

// custom components
import { getToolsConfig } from "../../../../../API/account";
import { getToolsData, updateContentData } from "../../API/toolsAPI";
import ModalEditorLeftSide from "./ModalEditorLeftSide";
import consoleLog from "../../../../../functions/consoleLog";
import { cachedToolsConfiguration } from "../../../../../functions/general";
import UserContext from "../../../../../components/UserContext";

const TranscriberModalView = (proops) => {

    // props
    const {
        functionRefundCredits,
        userConfig,
        isMobile,
        modalIdx,
        email,
        modalData,
        handleView,
        updateTaskIdAtIndex,
        elevanLabsAPIKey,
        subscriptionData,
        isOldUser,
        modalTranscriptionView,
        setShowViewModal,
    } = proops;

    // context method
    const {

        // debounce saveConfig function
        debouncedSaveConfig,

    } = useContext(UserContext);

    // variables
    const modalId = modalData?.id;

    // react components
    const navigate = useNavigate();

    const [content, setContent] = useState(modalData?.content_url);
    const [requiredValue, setRequiredValue] = useState("");
    const [isProcessing, setIsProcessing] = useState("");
    const [newdata, setNewData] = useState({});
    const [debouncedValue, setDebouncedValue] = useState(newdata.value);
    const [valueRatio, setValueRatio] = useState("default");
    const [selectedVoice, setSelectedVoice] = useState("");
    const [toolsData, setToolsData] = useState([]);
    const [audioFileName, setAudioFileName] = useState("");
    const [modalPreviewVideo, showModalPreviewVideo] = useState(false);
    const [voiceModelList, setVoiceModelList] = useState([]);
    const [voiceId, setVoiceId] = useState("");
    const [voicePreview, setVoicePreview] = useState("");

    // Audio source and Filtered data 
    const audioSrc = `${process.env.REACT_APP_SHINEFY_SERVER}/file/data/${email}/${modalData?.id}/${audioFileName}`;
    const filteredToolsData = toolsData?.filter(data => data.kind === "image");
    const finalImages = filteredToolsData.sort((a, b) => a.number - b.number);

    const images = finalImages.map(image => image.data === "error" ? {
        image: ErrorImage,
        duration: image.duration || 3
    } : {
        image: `${process.env.REACT_APP_SHINEFY_SERVER}/file/data/${email}/${modalId}/${image.data}`,
        duration: image.duration
    });

    // filter tools data on reload
    useEffect(() => {
        const getFileData = () => {
            const filteredToolsData = toolsData?.filter(data => data?.kind === "audio" && data?.id === modalData?.id);
            setAudioFileName(filteredToolsData[0]?.data);
        };
        getFileData();
    }, [toolsData]);

    // Function to fetch data
    const fetchData = async () => {

        try {

            // Get tools config
            const toolsConfig = await getToolsConfig(email, modalId);

            // Get tools data
            const toolsData = await getToolsData(email, modalId);

            // Set state with fetched data
            setValueRatio(toolsConfig?.data?.toolsConfig?.aspectRatio || "default");
            setSelectedVoice(toolsConfig?.data?.toolsConfig?.voice || process.env.REACT_APP_OPENAI_VOICE_NAME);
            setVoiceId(toolsConfig?.data?.toolsConfig?.voiceID || process.env.REACT_APP_ELEVENLAB_VOICE_ID);
            setVoicePreview(toolsConfig?.data?.toolsConfig?.voicePreview || process.env.REACT_APP_ELEVENLAB_VOICE_PREVIEW);
            setToolsData(toolsData?.data?.toolsData);

            // Store data in local storage
            localStorage.setItem(`toolsConfig-${modalId}`, JSON.stringify(toolsConfig?.data?.toolsConfig));
            localStorage.setItem(`toolsData-${modalId}`, JSON.stringify(toolsData?.data?.toolsData));

            // console log activity
            // consoleLog("components > Layout.js > toolsConfig", toolsConfig?.data?.toolsConfig);
            // consoleLog("components > Layout.js > toolsData", toolsData?.data?.toolsData);

        } catch (error) {
            consoleLog("error > TranscriberModalView > #fetchData", error);
        }
    };

    // get tools config
    useEffect(() => {
        if (!email || !modalData?.id) { return; }

        // Check local storage first
        const storedToolsConfig = localStorage.getItem(`toolsConfig-${modalId}`);
        const storedToolsData = localStorage.getItem(`toolsData-${modalId}`);

        if (storedToolsConfig && storedToolsData) {
            // Data exists in local storage, use it
            setValueRatio(JSON.parse(storedToolsConfig)?.aspectRatio || "default");
            setSelectedVoice(JSON.parse(storedToolsConfig)?.voice || process.env.REACT_APP_OPENAI_VOICE_NAME);
            setVoiceId(JSON.parse(storedToolsConfig)?.voiceID || process.env.REACT_APP_ELEVENLAB_VOICE_ID);
            setVoicePreview(JSON.parse(storedToolsConfig)?.voicePreview || process.env.REACT_APP_ELEVENLAB_VOICE_PREVIEW);
            setToolsData(JSON.parse(storedToolsData));
        } else {
            // No data in local storage, fetch it
            fetchData();
        }
    }, []); // Empty dependency array to run only on component mount

    // handle new value
    const handleNewValue = (event) => {
        setIsProcessing("content");
        const newValue = event.target.value;
        setContent(newValue);
        setRequiredValue(newValue);
        if (newValue && newValue.length > 0) {
            debounceFunction({ value: newValue, kind: "content" });
        }
    };

    // handle new title
    const handleNewTitle = (event) => {
        setIsProcessing("title");
        const newValue = event.target.value;
        setRequiredValue(newValue);
        if (newValue && newValue.length > 0) {
            debounceFunction({ value: newValue, kind: "title" });
        }
    };

    // debounce function
    const debounceFunction = (data) => {
        setNewData(data);
    };

    // Update debouncedValue after 1000ms the user has stopped typing
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(newdata.value);
            setIsProcessing("");
        }, 2000);

        return () => {
            clearTimeout(timer);
        };
    }, [newdata]);

    // Call handleMidjourneyAPIKey when debouncedValue changes
    useEffect(() => {
        handleDebounceFunction(debouncedValue);
    }, [debouncedValue]);

    // save debounced value
    const handleDebounceFunction = async (value) => {

        if (!value) {
            return;
        }

        const data = {
            data_id: modalData?._id,
            user_auth: process.env.REACT_APP_AUTH,
            kind: newdata?.kind === "content" ? "content" : "title",
            value: value,
        };

        await updateContentData(data)
            .then(() => {
                updateTaskIdAtIndex(modalIdx, value, newdata?.kind);
            });
    };

    // handle save content
    const handleSaveContent = async () => {
        handleDebounceFunction(requiredValue);
        setShowViewModal(false);
    };

    // handle create custom video
    const handleCreateCustomVideo = async () => {

        // variables
        const path = "/general-purpose";
        const kind_script = "script";
        const kind_prompts = "scriptPrompt";
        const kind_content = "userScriptContent";

        // save configs to script tab
        debouncedSaveConfig(kind_script, email, path, kind_script, debouncedValue || modalData?.content_url);
        debouncedSaveConfig(kind_prompts, email, path, kind_prompts, kind_script);

        // cached and set tab to script
        cachedToolsConfiguration(kind_content, debouncedValue || modalData?.content_url);
        cachedToolsConfiguration(kind_prompts, kind_script);

        // navigate page to image2video generator
        navigate(path);
    };

    return (
        <div className="absolute inset-0 z-50">
            <div data-state="open" className="fixed inset-0 bg-gray-300/70 dark:bg-gray-600/70">
                <div className="grid-cols-[10px_1fr_10px] grid h-full w-full grid-rows-[minmax(10px,_1fr)_auto_minmax(10px,_1fr)] md:grid-rows-[minmax(20px,_1fr)_auto_minmax(20px,_1fr)] overflow-y-auto">
                    <div role="dialog" id="radix-:r35:" aria-describedby="radix-:r37:" aria-labelledby="radix-:r36:" data-state="open" className="relative col-auto col-start-2 row-auto row-start-2 w-full rounded-lg text-left shadow-xl transition-all left-1/2 -translate-x-1/2 bg-white dark:bg-gray-900 focus-none !bg-transparent outline-none md:w-[672px] lg:w-[896px] xl:w-[1024px]" tabIndex="-1">
                        <div className="">
                            <div className="focus-none flex h-full flex-col items-center justify-start outline-none">
                                <div className="relative">
                                    <div ref={modalTranscriptionView} className="flex grow justify-center bg-white dark:bg-gray-800 rounded-md flex-col items-start overflow-hidden border shadow-md dark:border-gray-700">

                                        <div className="flex w-full flex-row items-center justify-between border-b px-4 py-3 dark:border-gray-700">
                                            <span className="text-base font-semibold sm:text-base dark:text-gray-300">Transcription View</span>

                                            <button
                                                onClick={handleView}
                                                className="text-gray-700 opacity-50 transition hover:opacity-75 dark:text-white"
                                            >
                                                <i className="fa-solid fa-x" />
                                            </button>

                                        </div>

                                        <div className="grid w-[60rem]">
                                            <div className="relative order-1 col-span-1 sm:order-2">
                                                <div className="p-4 flex flex-col gap-3 bg-white z-20 relative dark:bg-gray-800">

                                                    {/* {viewValue(selectedValue)} */}

                                                    {/* <button
                                                        className="opacity-50 relative btn-primary border-none bg-gray-300 py-3 font-semibold hover:bg-gray-200"
                                                        as="button"
                                                    >
                                                        <div className="flex w-full gap-2 items-center justify-center"><span
                                                            className="inline-block text-gray-700">Buy Credits</span>
                                                        </div>
                                                    </button> */}

                                                    {/* <button
                                                        // disabled={selectedValue ? false : true}
                                                        // onClick={handleClick}
                                                        className={`"opacity-50 relative btn-primary border-none font-semibold ${true ? "bg-blue-300 py-3 hover:bg-blue-400" : "bg-gray-400 py-3"}`}
                                                        as="button"
                                                    >
                                                        <div className="flex w-full gap-2 items-center justify-center"><span
                                                            className="inline-block text-gray-700">Buy Credits</span>
                                                        </div>
                                                    </button> */}

                                                    {/* <div className="mx-auto max-w-full bg-white shadow-xl p-4 text-slate-500 text-sm leading-6 sm:text-base sm:leading-7 dark:bg-slate-800 dark:text-slate-400">
                                                        <div className="flow-root bg-stripes-purple mb-0.5">
                                                            <div className="my-4 bg-white dark:bg-slate-800">
                                                                Well, let me tell you something, funny boy. Y'know that little stamp, the one that says "New York Public Library"? Well that may not mean anything to you, but that means a lot to me. One whole hell of a lot.
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    {/* <div className="relative">
                                                        <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                                            <i className="fa-solid fa-pen-to-square w-4 h-4 text-gray-500 dark:text-gray-400" />
                                                        </div>
                                                        <input
                                                            onChange={handleNewTitle}
                                                            type="text"
                                                            id="input-group-1"
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder={modalData?.task_id}
                                                        />
                                                    </div> */}

                                                    <div className="w-full mb-0 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">

                                                        <div className="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800">
                                                            {/* Flex container with spacing */}
                                                            <div className="flex space-x-4">
                                                                {/* Flex column container for left side */}
                                                                <div className="flex-1 flex flex-col shadow-md">
                                                                    <ModalEditorLeftSide
                                                                        functionRefundCredits={functionRefundCredits}
                                                                        userConfig={userConfig}
                                                                        email={email}
                                                                        isMobile={isMobile}
                                                                        modalData={modalData}
                                                                        content={content}
                                                                        handleNewTitle={handleNewTitle}
                                                                        handleNewValue={handleNewValue}
                                                                        setSelectedVoice={setSelectedVoice}
                                                                        selectedVoice={selectedVoice}
                                                                        voiceId={voiceId}
                                                                        setVoiceId={setVoiceId}
                                                                        voicePreview={voicePreview}
                                                                        setVoicePreview={setVoicePreview}
                                                                        toolsData={toolsData}
                                                                        fetchData={fetchData}
                                                                        audioFileName={audioFileName}
                                                                        modalPreviewVideo={modalPreviewVideo}
                                                                        showModalPreviewVideo={showModalPreviewVideo}
                                                                        voiceModelList={voiceModelList}
                                                                        elevanLabsAPIKey={elevanLabsAPIKey}
                                                                        subscriptionData={subscriptionData}
                                                                        isOldUser={isOldUser}
                                                                        images={images}
                                                                        audioSrc={audioSrc}
                                                                    />
                                                                </div>
                                                                {/* Right side text block */}
                                                                {/* <div className="flex-none w-1/2 bg-white shadow-md p-4 text-slate-500 text-sm leading-6 sm:text-base sm:leading-7 dark:bg-slate-800 dark:text-slate-400">
                                                                    <ModalEditorRightSide
                                                                        functionRefundCreditsImage={functionRefundCreditsImage}
                                                                        userConfig={userConfig}
                                                                        email={email}
                                                                        isMobile={isMobile}
                                                                        setTranscriberOption={setTranscriberOption}
                                                                        transcriberOption={transcriberOption}
                                                                        modalData={modalData}
                                                                        content={content}
                                                                        valueRatio={valueRatio}
                                                                        setValueRatio={setValueRatio}
                                                                        toolsData={toolsData}
                                                                        setToolsData={setToolsData}
                                                                        fetchData={fetchData}
                                                                        audioFileName={audioFileName}
                                                                        subscriptionData={subscriptionData}
                                                                        isOldUser={isOldUser}
                                                                    />
                                                                </div> */}
                                                            </div>
                                                        </div>

                                                        <div className="flex items-center justify-between px-3 py-2 border-t dark:border-gray-600">
                                                            <div className="space-x-1">
                                                                {/* <button
                                                                    disabled={isProcessing ? true : false}
                                                                    onClick={handleGenerateVideo}
                                                                    data-tooltip-id="tooltip-transcriber-generateUsingScript"
                                                                    data-tooltip-content="Generate a video using this script"
                                                                    type="submit"
                                                                    className={`${isProcessing && "cursor-not-allowed"} inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800`}
                                                                >
                                                                    <Tooltip id="tooltip-transcriber-generateUsingScript" />
                                                                    Use script
                                                                </button>
                                                                <button
                                                                    disabled={isProcessing ? true : false}
                                                                    onClick={handleGenerateVideo}
                                                                    data-tooltip-id="tooltip-transcriber-generateUsingImages"
                                                                    data-tooltip-content="Generate a video using this images"
                                                                    type="submit"
                                                                    className={`${isProcessing && "cursor-not-allowed"} inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800`}
                                                                >
                                                                    <Tooltip id="tooltip-transcriber-generateUsingImages" />
                                                                    Use images
                                                                </button> */}
                                                                <button
                                                                    // disabled={(isGenerating || foundObject?.current) ? true : false}
                                                                    // onClick={handleGenerateVideo}
                                                                    disabled={isProcessing ? true : false}
                                                                    onClick={handleCreateCustomVideo}
                                                                    data-tooltip-id="tooltip-transcriber-generate"
                                                                    data-tooltip-content="Create video using this script"
                                                                    type="submit"
                                                                    className={`${isProcessing && "cursor-not-allowed"} inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-white ${isProcessing ? "bg-gray-600" : "bg-blue-700 hover:bg-blue-800"} rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900`}
                                                                >
                                                                    {/* <i className={`fa-solid fa-arrows-rotate mr-1 ${isGenerating && "fa-spin"}`} /> */}
                                                                    <Tooltip id="tooltip-transcriber-generate" />
                                                                    Create Video
                                                                </button>
                                                                {/* <div
                                                                    data-tooltip-id="tooltip-transcriber-generateCost"
                                                                    data-tooltip-content="Video Generate cost"
                                                                    type="submit"
                                                                    className="inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-white bg-gray-500 rounded-lg focus:ring-4"
                                                                >
                                                                    <Tooltip id="tooltip-transcriber-generateCost" />
                                                                    <i className="fa-solid fa-coins mr-1" />
                                                                    {(creditVideoCalculation).toFixed(2)}
                                                                </div> */}
                                                            </div>

                                                            {/* {foundObject?.current && (
                                                                <div className="w-[70%] bg-gray-200 rounded-full dark:bg-gray-600">
                                                                    <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{ width: `${percentageCalculator(foundObject?.total, foundObject?.current)}%` }}>
                                                                        <code>{Number(percentageCalculator(foundObject?.total, foundObject?.current)).toFixed(0)}% / {foundObject?.status}</code>
                                                                    </div>
                                                                </div>
                                                            )} */}

                                                            {/* {foundObject?.current && (
                                                                <div className="w-[70%] bg-gray-200 rounded-full dark:bg-gray-600 relative">
                                                                    <div className="bg-blue-600 text-base font-medium text-blue-100 text-center p-0.5 leading-none rounded-full h-[1.2rem]" style={{ width: `${percentageCalculator(foundObject?.total, foundObject?.current)}%` }}>
                                                                        <code style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                                                                            {Number(percentageCalculator(foundObject?.total, foundObject?.current)).toFixed(0)}% / {foundObject?.status}
                                                                        </code>
                                                                    </div>
                                                                </div>
                                                            )} */}

                                                            <div className="flex pl-0 space-x-1 sm:pl-2">
                                                                {/* <button type="button" className="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                                                                    <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 20">
                                                                        <path stroke="currentColor" strokeLinejoin="round" strokeWidth="2" d="M1 6v8a5 5 0 1 0 10 0V4.5a3.5 3.5 0 1 0-7 0V13a2 2 0 0 0 4 0V6" />
                                                                    </svg>
                                                                    <span className="sr-only">Attach file</span>
                                                                </button>
                                                                <button type="button" className="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                                                                    <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
                                                                        <path d="M8 0a7.992 7.992 0 0 0-6.583 12.535 1 1 0 0 0 .12.183l.12.146c.112.145.227.285.326.4l5.245 6.374a1 1 0 0 0 1.545-.003l5.092-6.205c.206-.222.4-.455.578-.7l.127-.155a.934.934 0 0 0 .122-.192A8.001 8.001 0 0 0 8 0Zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                                                                    </svg>
                                                                    <span className="sr-only">Set location</span>
                                                                </button> */}
                                                                {isProcessing && (
                                                                    <div
                                                                        data-tooltip-id="tooltip-transcriber-updating"
                                                                        data-tooltip-content="Updating content data"
                                                                        className="text-blue-500 cursor-help mr-1"
                                                                    >
                                                                        <Tooltip id="tooltip-transcriber-updating" />
                                                                        <i className="fa-solid fa-circle-notch fa-spin text-2xl" />
                                                                    </div>
                                                                )}

                                                                <button
                                                                    onClick={handleSaveContent}
                                                                    type="button"
                                                                    data-tooltip-id="tooltip-transcriber-save"
                                                                    data-tooltip-content="Save modification"
                                                                    className="inline-flex items-center py-1 px-4 text-sm font-medium text-center text-white bg-blue-700 hover:bg-blue-800 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900"
                                                                >
                                                                    <Tooltip id="tooltip-transcriber-save" />
                                                                    {/* <i className="fa-solid fa-film text-3xl" /> */}
                                                                    <i className="fa-solid fa-floppy-disk text-2xl" />
                                                                </button>
                                                                {/* <button
                                                                    onClick={handlePreviewVideo}
                                                                    type="button"
                                                                    data-tooltip-id="tooltip-transcriber-preview"
                                                                    data-tooltip-content="Preview video"
                                                                    className="inline-flex justify-center items-center p-0 text-gray-500 rounded cursor-pointer hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
                                                                >
                                                                    <Tooltip id="tooltip-transcriber-preview" />
                                                                    <i className="fa-solid fa-film text-3xl" />
                                                                </button> */}
                                                            </div>
                                                        </div>
                                                    </div>


                                                    {/* <h3 className="mb-5 text-lg font-medium text-gray-900 dark:text-white">
                                                        How much do you expect to use each month?
                                                    </h3> */}

                                                    {/* <ul className="grid w-full gap-6 md:grid-cols-1">
                                                        <li>
                                                            <input
                                                                // onChange={handleSelection}
                                                                type="radio"
                                                                id="hosting-small"
                                                                name="hosting"
                                                                value="testID_123"
                                                                className="hidden peer"
                                                                required
                                                            />
                                                            <label htmlhtmlFor="hosting-small" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                                                <div className="block">
                                                                    <div className="w-full text-lg font-semibold">USD $20</div>
                                                                    <div className="w-full">100 Shinefy credits</div>
                                                                </div>
                                                                <i className="fa-solid fa-arrow-right text-xl ml-3" />
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <input
                                                                // onChange={handleSelection}
                                                                type="radio"
                                                                id="hosting-big"
                                                                name="hosting"
                                                                value="testID_456"
                                                                className="hidden peer"
                                                            />
                                                            <label htmlhtmlFor="hosting-big" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                                                <div className="block">
                                                                    <div className="w-full text-lg font-semibold">USD $12</div>
                                                                    <div className="w-full">50 Shinefy credits</div>
                                                                </div>
                                                                <i className="fa-solid fa-arrow-right text-xl ml-3" />
                                                            </label>
                                                        </li>
                                                    </ul> */}

                                                    {/* <div className="gap-2 flex flex-row justify-start text-sm items-start sm:pb-1">
                                                        <button className="flex flex-row items-center space-x-1 underline">
                                                            Manage my subscription
                                                        </button>
                                                    </div>
                                                    <a
                                                        target="_blank" href="https://help.openai.com/en/collections/3943089-billing">
                                                        <div className="gap-2 flex flex-row justify-start text-sm items-start sm:pb-1">
                                                            <div className="flex flex-row items-center space-x-1 underline">
                                                                I need help with a billing issue
                                                            </div>
                                                        </div>
                                                    </a> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TranscriberModalView;