import React from "react";

// components
import BreadCrumbs from "../../components/Profile/BreadCrumbs";
import LanguageTime from "../../components/Profile/LanguageTime";
import OtherAccounts from "../../components/Profile/OtherAccounts";
import Information from "../../components/Profile/Information";
import Session from "../../components/Profile/Session";
import Password from "../../components/Profile/Password";
import Social from "../../components/Profile/Social";
import Avatar from "../../components/Profile/Avatar";
import Options from "../../components/Profile/Options";
import AdvanceSettings from "../../components/Profile/AdvanceSettings";

const Profile = (props) => {

    const { logout } = props;

    return (
        <div className="grid grid-cols-1 px-6 pt-16 xl:grid-cols-3 xl:gap-4">
            {/* <BreadCrumbs /> */}
            <div className="col-span-full xl:col-auto">
                <Avatar />
                {/* <LanguageTime /> */}
                <Social />
                <AdvanceSettings />
                {/* <Options /> */}
                {/* <OtherAccounts /> */}
            </div>
            <div className="col-span-2">
                <Information />
                <Password logout={logout} />
                {/* <Session /> */}
            </div>
        </div>
    );
};

export default Profile;