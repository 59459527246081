// libraries
import React from "react";
import { Radio, RadioGroup, cn } from "@nextui-org/react";

// custom components
import consoleLog from "../../../../../functions/consoleLog";
import { TTSModels } from "../Utils/data";
import { cachedConfig } from "../../../../../functions/general";
import AudioUI from "./AudioTab/AudioUI";

const VoiceTab = (props) => {

    // props
    const {
        selectedModel,
        setSelectedModel,
    } = props;

    // validity checker
    const isInvalid = !TTSModels?.some(model => model.model === selectedModel);

    // console log activity
    // consoleLog("selectedModel", selectedModel);
    // consoleLog("fetchSelectedModelData", fetchSelectedModelData);

    // hadnle selected voice model
    const handleSelectedVoiceModel = async (voiceModel) => {

        // set selected voice model name
        setSelectedModel(voiceModel);

        // cached selected voice model
        await cachedConfig("voiceModel", voiceModel);
    };

    return (
        <div className="lg:grid lg:grid-cols-1 gap-2">

            {/* left side card */}
            <div className="h-fit mb-3 lg:mb-0 space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:p-6">
                <p className="text-xl font-semibold text-gray-900 dark:text-white">Selected Voice Model</p>

                <div className="space-y-4">
                    <div className="space-y-2">

                        <dl className="flex items-center justify-between gap-4">
                            <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                                Voice
                            </dt>
                            <dd className="text-base font-medium text-gray-900 dark:text-white">
                                {selectedModel}
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>

            {/* right side card */}
            <div className="space-y-3 overflow-y-auto max-h-[40rem]">
                <RadioGroup
                    // label="Plans"
                    // description="Selected plan can be changed at any time."
                    value={selectedModel}
                    isInvalid={isInvalid}
                    onValueChange={(voiceModel) => handleSelectedVoiceModel(voiceModel)}
                >

                    {TTSModels?.map((data, idx) => {

                        return (
                            <div
                                key={idx}
                                className="p-2"
                            >
                                <Radio
                                    value={data?.model}
                                    classNames={{
                                        base: cn(
                                            "inline-flex max-w-md bg-gray-100 dark:bg-gray-800",
                                            "hover:bg-content2 items-center justify-center",
                                            "cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent",
                                            "data-[selected=true]:border-primary",
                                        ),
                                        label: "w-full",
                                    }}
                                >
                                    <AudioUI
                                        data={data}
                                        idx={idx}
                                    />
                                </Radio>

                            </div>
                        );
                    })}
                </RadioGroup>
            </div>

        </div>
    );
};

export default VoiceTab;