// libraries
import { useEffect } from "react";
import axios from "axios";
import { Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";

// google captcha imports
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// custom components
import Register from "./pages/Auth/Register";
import SignIn from "./pages/Auth/Signin";
import ForgotPassword from "./pages/Auth/Forgotpassword";
import Resetpassword from "./pages/Auth/Resetpassword";
import NotFound from "./pages/shinefy/NotFound";
import Success from "./pages/shinefy/Success";
import Menu from "./pages/shinefy/Menu";
import Layout from "./components/Layout";
import UserSubscription from "./pages/shinefy/UserSubscription";
import GeneralPurpose from "./pages/shinefy/Tools/VideoGenerator";
import Profile from "./pages/shinefy/Profile";
import ChatAI from "./pages/shinefy/ChatAI";
import VideoTranscriber from "./pages/shinefy/Tools/VideoTranscriber";
import AdminLayout from "./components/Admin/AdminLayout";
import MainPage from "./pages/Admin/MainPage";
import LoadingPage from "./pages/shinefy/LoadingPage";
import MaintenancePage from "./pages/shinefy/MaintenancePage";
import VideoGallery from "./pages/shinefy/Gallery/VideoGallery";
import ImageGallery from "./pages/shinefy/Gallery/ImageGallery";
import useApiStatus from "./pages/shinefy/Tools/functions/checktoolsAPIStatus";
import NotifPage from "./components/Notification/NotifPage";
import SSOAuth from "./pages/Auth/SSOAuth";
import VideoEnhancer from "./pages/shinefy/Tools/VideoEnhancer";
import NotAvailable from "./pages/shinefy/NotAvailable";
import TrialProcess from "./pages/shinefy/TrialProcess";
import Subscription from "./pages/Auth/Subscription";
import NotificationView from "./pages/Admin/NotificationView";
import TestPage from "./pages/Admin/TestPage";
import Idea2Video from "./pages/shinefy/Tools/Idea2Video/Idea2Video";
import DiscordPage from "./pages/Admin/DiscordPage";
import AccountsData from "./pages/Admin/AccountsData";

export default function App() {

    // react hook that will fetch and check is Tools Server is alive
    const isToolsOnline = useApiStatus();

    // logout function
    const logout = () => {

        // Retrieve the values of the keys you want to keep
        const theme = localStorage.getItem("theme");

        // Clear all localStorage
        localStorage.clear();

        // Restore the kept values
        if (theme !== null) {
            localStorage.setItem("theme", theme);
        }

        // Redirect to the homepage
        window.location.href = "/";
    };

    // set theme dark or light
    useEffect(() => {
        const storedTheme = localStorage.getItem('theme');
        if (storedTheme === 'dark') {
            document.documentElement.classList.add("dark");
        } else if (storedTheme === 'light') {
            document.documentElement.classList.remove("dark");
        } else {
            if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
                document.documentElement.classList.add("dark");
            } else {
                document.documentElement.classList.remove("dark");
            }
        }
    }, []);

    return (
        <>
            <Toaster position="bottom-right" reverseOrder={false} />
            <Routes>

                {/* conditional function that checks if server is online | offline */}
                {isToolsOnline === "online" ? (
                    <>
                        {/* main page the sign in page if not authenticated */}
                        <Route path="/" element={
                            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_SITE_KEY}>
                                <SignIn />
                            </GoogleReCaptchaProvider>
                        } />

                        {/* SSO authentication (disabled) */}
                        {/* <Route path="/sso-auth" element={
                            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_SITE_KEY}>
                                <SSOAuth />
                            </GoogleReCaptchaProvider>
                        } /> */}

                        {/* Admin Dashboard (disabled for now) */}
                        <Route path="/" element={<AdminLayout logout={logout} />}>
                            <Route path="/admin" element={<MainPage />} />
                            <Route path="/admin-notif" element={<NotificationView />} />
                            <Route path="/discord" element={<DiscordPage />} />
                            <Route path="/user-accounts" element={<AccountsData />} />
                            <Route path="/test" element={<TestPage />} />
                        </Route>

                        {/* Main Menu and sub pages inside main menu */}
                        <Route path="/" element={<Layout logout={logout} />}>
                            <Route path="/menu" element={<Menu />} />
                            <Route path="/auth" element={<Menu />} />
                            <Route path="/ai_chat" element={<ChatAI />} />
                            <Route path="/general-purpose" element={<GeneralPurpose />} />
                            <Route path="/video-gen" element={<Idea2Video />} />
                            <Route path="/transcribe" element={<VideoTranscriber />} />
                            <Route path="/account" element={<Profile logout={logout} />} />
                            <Route path="/my_plan" element={<UserSubscription />} />
                            <Route path="/notif" element={<NotifPage />} />
                            {/* <Route path="/enhancer" element={<VideoEnhancer />} /> */}
                            {/* <Route path="/viral_topic" element={<NotFound />} /> */}
                            {/* <Route path="/purchase" element={<NotFound />} /> */}
                            {/* <Route path="/contact_us" element={<NotFound />} /> */}
                            {/* <Route path="/admin" element={<NotFound />} /> */}

                            {/* Gallery tab */}
                            <Route path="/videos" element={<VideoGallery />} />
                            <Route path="/images" element={<ImageGallery />} />

                        </Route>

                        {/* trial form (disabled) */}
                        {/* <Route path="/start-trial" element={
                            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_SITE_KEY}>
                                <TrialProcess />
                            </GoogleReCaptchaProvider>
                        } /> */}

                        {/* register form */}
                        <Route path="/register" element={
                            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_SITE_KEY}>
                                <Register />
                            </GoogleReCaptchaProvider>
                        } />

                        {/* forgot password form */}
                        <Route path="/forgot_password" element={
                            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_SITE_KEY}>
                                <ForgotPassword />
                            </GoogleReCaptchaProvider>
                        } />

                        {/* reset password form */}
                        <Route path="/reset_password" element={
                            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_SITE_KEY}>
                                <Resetpassword />
                            </GoogleReCaptchaProvider>
                        } />

                        {/* subscription form (disabled) */}
                        {/* <Route path="/subscription" element={
                            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_SITE_KEY}>
                                <Subscription />
                            </GoogleReCaptchaProvider>
                        } /> */}

                        {/* success and default page */}
                        <Route path="/success" element={<Success />} />
                        <Route path="*" element={<NotFound />} />
                    </>
                    // for maintenance and loading options
                ) : isToolsOnline === "maintenance" ? (<Route path="*" element={<MaintenancePage />} />) : (<Route path="*" element={<LoadingPage />} />)}
            </Routes>
        </>
    );
}
