// libraries
import React, { useContext, useState } from "react";
import { Button, Input, Select, SelectItem, Switch, Tooltip } from "@nextui-org/react";

// custom components
import consoleLog from "../../../../../../functions/consoleLog";
import { reCraftVideoContent } from "../../../API/toolsAPI";
import { payVideo, recordCredits } from "../../../../../../API/account";
import UserContext from "../../../../../../components/UserContext";
import { adjustCreditsView } from "../../../../../../functions/itemWithExpiry";
import { ToastEffect } from "../../../../../../functions/ToastEffect";

const HeadMenu = (props) => {

    // props
    const {
        handleSearchKeyword,
        selectedOrientation,
        handleSelectedOrientation,
        videoOrientation,
        artisanSession,
        selectedFont,
        selectedFontColor,
        selectedCaptionPosition,
        pricingData,
        setProcessedData,
        subscriptionData,
        isOldUser,
    } = props;

    // context method
    const {

        // user data
        userData,

    } = useContext(UserContext);

    // extracted values
    const email = userData?.UserInfo?.email;
    const videoGeneratePrice = pricingData[0]?.videoReGenerate;

    // local storage key
    const localStorageKey = 'processProgressStatus';

    // react useState
    const [isReCrafting, setIsReCrafting] = useState(false);

    // console log activity
    // consoleLog("artisanSession", artisanSession);

    // handle re-craft video
    const handleRecraftVideo = async () => {

        // check if email exist
        if (!email) { return; }

        // check if price exist
        if (!videoGeneratePrice) { return; }

        // set re-crafting to true
        setIsReCrafting(true);

        // // make payment
        // const paymentResult = await paySLD({ // pay SLD
        //     email: artisanSession?.email, // user account email
        //     amount: pricingData?.message?.videoReGenerate, // payment amount
        //     serviceKind: "regenerate_video"
        // });

        // // check payment response
        // if (paymentResult?.data?.response === "no_balance") {

        //     // set alert data
        //     setAlertContentData({
        //         title: "This is not possible!",
        //         // content: paymentResult?.data?.message,
        //         content: "Insufficient SLD balance.",
        //     });

        //     // open alert modal
        //     onOpenAlert();

        //     // trigger is generating
        //     setIsReCrafting(false);

        //     return;
        // }

        // // refetch subscription data
        // setRefetchSLDBalance((oldVal) => oldVal + 1);

        // prepare required data
        const requiredData = {
            email: artisanSession?.email,
            craft_data: artisanSession,
            caption_font: selectedFont,
            aspect_ratio: artisanSession?.video?.aspectRatio,
            session_id: artisanSession?._id,
            font_color: selectedFontColor,
            caption_position: selectedCaptionPosition,
            client_auth: process.env.REACT_APP_AUTH,
        };

        // call the function that will send data to the server
        await reCraftVideoContent(requiredData)
            .then(async (response) => {

                if (response?.data?.detail === "request_limit") {
                    ToastEffect("error", "You can only process once.");
                } else {
                    // clean progress data
                    setProcessedData([]);
                    localStorage.removeItem(localStorageKey);
                }

                // process payment
                if (subscriptionData?.message === "freeAccess") {
                    let paymentKind = "freeAccess";

                    if (subscriptionData?.message === "subscribed" && isOldUser) {
                        paymentKind = "OldUser";
                    }

                    await recordCredits(email, response?.data?.task_id, "Artisan", paymentKind, videoGeneratePrice, 0, Number(0));
                } else {
                    await payVideo(email, videoGeneratePrice, "Artisan"); // pay video
                    await recordCredits(email, response?.data?.task_id, "Artisan", "payment", videoGeneratePrice, 0, Number(0)); // record credit cost
                    adjustCreditsView("tokenBalance", "pay", videoGeneratePrice); // adjust credit UI display
                }

                // console log activity
                consoleLog("reCraftVideoContent", response?.data);
            });

        // disable re-crafting status
        setIsReCrafting(true);

    };

    // console log activity
    // consoleLog("uploadProgress", uploadProgress);

    // handle own media switch
    // const handleOwnMediaSwitch = (value) => {

    //     // set value
    //     setIsOwnMedia(value);

    //     // console log activity
    //     // consoleLog("value", value);
    // };

    return (
        <div className="relative bg-white shadow-md dark:bg-gray-900 rounded-lg w-full">
            <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">

                {/* <div className="lg:flex justify-between items-start space-y-8 lg:space-y-0 space-x-2 w-[21rem]"> */}
                <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">

                    {/* {isOwnMedia ? (
                        <> */}

                    {/* <input
                        type="file"
                        accept="video/*"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        className="hidden"
                    />

                    <Button
                        // color="primary"
                        size="lg"
                        variant="bordered"
                        className="font-semibold w-full lg:w-auto"
                        onClick={handleButtonClick}
                    >
                        Add Own Media
                    </Button> */}

                    {/* </>
                    ) : (
                        <> */}

                    {/* search input */}
                    <Input
                        size="lg"
                        radius="lg"
                        type="text"
                        variant="bordered"
                        placeholder="Search for content"
                        startContent={
                            <span className="text-2xl text-default-400 pointer-events-none flex-shrink-0">
                                <i className="fa-solid fa-magnifying-glass" />
                            </span>
                        }
                        // className="max-w-[13rem] h-5"
                        onChange={handleSearchKeyword}
                    />

                    {/* orientation selection */}
                    <Select
                        size="sm"
                        radius="lg"
                        variant="bordered"
                        label="Orientation"
                        className="lg:max-w-[8rem]"
                        selectedKeys={[selectedOrientation]}
                        onChange={handleSelectedOrientation}
                    >
                        {videoOrientation.map((orientation) => (
                            <SelectItem key={orientation.value}>
                                {orientation.value}
                            </SelectItem>
                        ))}
                    </Select>

                    {/* </>
                    )} */}

                </div>

                {/* <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3"> */}
                {/* <div className="grid grid-cols-2 gap-4 content-start">
                    <Switch
                        // isDisabled={!video ? true : false}
                        isSelected={isOwnMedia}
                        onValueChange={handleOwnMediaSwitch}
                        classNames={{
                            base: cn(
                                "inline-flex flex-row-reverse min-w-full bg-gray-100 hover:bg-gray-100/80 dark:bg-gray-900 dark:hover:bg-gray-900/80 items-center",
                                "justify-between cursor-pointer rounded-lg gap-2 p-2 border-2 border-transparent",
                                "data-[selected=true]:border-primary",
                            ),
                            wrapper: "p-0 h-4 overflow-visible",
                            thumb: cn("w-6 h-6 border-2 shadow-lg",
                                "group-data-[hover=true]:border-primary",
                                //selected
                                "group-data-[selected=true]:ml-6",
                                // pressed
                                "group-data-[pressed=true]:w-7",
                                "group-data-[selected]:group-data-[pressed]:ml-4",
                            ),
                        }}
                    >
                        <div className="flex flex-col gap-1">
                            <p className="text-medium">Own Media</p>
                        </div>
                    </Switch>
                   
                </div> */}

                {/* re-craft button */}
                <Tooltip
                    showArrow={true}
                    color="secondary"
                    content={
                        <div className="px-1 py-1">
                            <div className="text-small font-bold">Re-Craft video</div>
                            <div className="text-tiny">This will cost <code className="text-sky-300 font-semibold">{videoGeneratePrice} SLD</code></div>
                        </div>
                    }
                >
                    <Button
                        isLoading={isReCrafting ? true : false}
                        color="primary"
                        className="font-semibold w-full lg:w-auto"
                        // onPress={onClose}
                        onClick={handleRecraftVideo}
                    >
                        Re-Craft Video
                    </Button>
                </Tooltip>

            </div>
        </div>
    );
};

export default HeadMenu;