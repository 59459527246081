// libraries
import React from "react";

// custom components
import consoleLog from "../functions/consoleLog";
import { planAvailedData } from "../Configs/planData";
import { unitAmountConversions } from "../pages/shinefy/Tools/functions/general";

export const MyPlanRecord = (props) => {

    // props
    const {
        subscriptionData,
        filteredProducts,
    } = props;

    // can be modified variable values
    let planCredits = 0;
    let creditsLabel = "";

    // subscriptionData extracted variable values
    const subscriptionStatus = subscriptionData?.status?.status;

    // console log activity
    // consoleLog("planAvailedData", planAvailedData);

    if (subscriptionStatus === "trialing") {
        planCredits = filteredProducts[0]?.productData?.metadata?.trial_credits;
        creditsLabel = "rial credits";
    } else {
        planCredits = filteredProducts[0]?.productData?.metadata?.credits;
        creditsLabel = "credits per month";
    }

    return (
        <div className="w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700 flex flex-col">
            <h5 className="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">
                {subscriptionData?.status?.item_name || "Current Plan"}
            </h5>
            <div className="flex items-baseline text-gray-900 dark:text-white">
                <span className="text-3xl font-semibold">$</span>
                <span className="text-5xl font-extrabold tracking-tight">
                    {subscriptionStatus === "trialing" ? "0" : unitAmountConversions(subscriptionData?.status?.amount) || "0"}
                </span>
                <span className="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">
                    /{subscriptionStatus === "trialing" ? "trial" : subscriptionData?.status?.frequency || "Frequency"}
                </span>
            </div>
            <ul role="list" className="space-y-5 my-7">
                <li className="flex space-x-3">
                    <i className="fa-solid fa-circle-check flex-shrink-0 text-blue-600 dark:text-blue-500" />
                    <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">{(planCredits || 0).toLocaleString(0, 2)} {creditsLabel}</span>
                </li>
                {planAvailedData?.map((item, idx) => {
                    return (
                        <li key={idx} className="flex space-x-3">
                            <i className="fa-solid fa-circle-check flex-shrink-0 text-blue-600 dark:text-blue-500" />
                            <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">{item?.data}</span>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};