// libraries
import React, { useContext, useEffect, useState } from "react";
import { Tabs, Tab, Card, CardBody, Switch, useDisclosure } from "@nextui-org/react";

// custom components
import consoleLog from "../../../../functions/consoleLog";
import { addMarkerToData, cachedConfig, getConfigValue, getCurrentTimestamp, removeMarkedData } from "../../../../functions/general";
import UserContext from "../../../../components/UserContext";
import ScriptTab from "./Components/ScriptTab";
import { TTSModels, aspectRatio, googleFontsData } from "./Utils/data";
import VoiceTab from "./Components/VoiceTab";
import FontsTab from "./Components/FontsTab";
import CraftTab from "./Components/CraftTab";
import MediaView from "../../../../components/Modals/MediaView";
import handleDownload from "../functions/download";
import { ToastEffect } from "../../../../functions/ToastEffect";
import Edit from "./Components/Editor/Edit";

const Idea2Video = () => {

    const {

        // Others
        userData,
        appConfigData,
        subscriptionData,
        configData,

        // content artisan
        artisanSession,
        setArtisanSession,
        artisanSessions,
        setArtisanSessions,
        isFetchingArtisanSessions,
        setRefetchArtisanSessions,

        // app process progress status
        appProcessStatus,

    } = useContext(UserContext);

    // extracted values
    const email = userData?.UserInfo?.email;
    const isOldUser = userData?.UserInfo?.is_oldSubs;
    const videoSource = artisanSession?.video?.videoLink || artisanSession?.video?.rawVideoLink;
    const noCaptionVideo = artisanSession?.video?.rawVideoLink;

    // const scriptGeneratePrice = appConfigData[0]?.scriptGenerate;
    // const videoGeneratePrice = appConfigData[0]?.videoGenerate;
    // const videReGeneratePrice = appConfigData[0]?.videoReGenerate;

    // local storage key
    const localStorageKey = 'processProgressStatus';

    // nextUI components
    const { isOpen: isOpenMV, onOpen: onOpenMV, onClose: onCloseMV } = useDisclosure(); // MV - (Media View)
    const { isOpen: isOpenEM, onOpen: onOpenEM, onClose: onCloseEM } = useDisclosure(); // EM - (Editor Modal)

    // react useState - Custom
    const [processedData, setProcessedData] = useState(() => {
        // Retrieve existing data from local storage on initial render
        return JSON.parse(localStorage.getItem(localStorageKey)) || [];
    });
    const [selectedArtisanTab, setSelectedArtisanTab] = useState(
        getConfigValue(configData, "selectedArtisanTab") || ""
    );
    const [script, setScript] = useState(
        getConfigValue(configData, "scriptTopic") || ""
    );
    const [selectedModel, setSelectedModel] = useState(
        getConfigValue(configData, "voiceModel") || TTSModels[0]?.model
    );
    const [selectedFont, setSelectedFont] = useState(
        getConfigValue(configData, "captionFont") || googleFontsData[0]?.fontName
    );
    const [selectedFontColor, setSelectedFontColor] = useState(
        getConfigValue(configData, "fontColor") || "#FFD700"
    );
    const [selectedCaptionPosition, setSelectedCaptionPosition] = useState(
        getConfigValue(configData, "fontPosition") || ""
    );
    const [selectedDimension, setSelectedDimension] = useState(
        getConfigValue(configData, "videoDimension") || aspectRatio[0]?.ratio
    );

    // react useState
    const [sessionID, setSessionID] = useState("");
    const [incomingScript, setIncomingScript] = useState("");
    const [isDownloading, setIsDownloading] = useState({});
    const [videoData, setVideoData] = useState({});

    // console log activity
    // consoleLog("email", email);

    // always set sessionID value
    useEffect(() => {
        setSessionID(artisanSession?._id);
    }, [artisanSession]);

    // handle selected tab
    const handleSelectedTab = async (tab) => {

        // cache selected tab save to local storage
        await cachedConfig("selectedArtisanTab", tab);
        setSelectedArtisanTab(tab); // set selected tab

        // console log activity
        // consoleLog("tab", tab);
    };

    // handle download media file
    const handleDownloadMediaFile = async (mediaURL, mediaName, kind, dataID) => {

        // check if videoData exist
        if (!videoData) { return; }

        // new name
        const newName = `${mediaName}_${getCurrentTimestamp()}`;

        // call marker function and insert data
        await addMarkerToData(setIsDownloading, dataID);

        // execute download process
        await handleDownload(mediaURL, newName, kind)
            .then(async () => {
                // call marker to remove marked data
                await removeMarkedData(setIsDownloading, dataID);
            }).catch(async (error) => {
                // call marker to remove marked data
                await removeMarkedData(setIsDownloading, dataID);
                ToastEffect("error", "Error downloading media #handleDownloadMediaFile");
                consoleLog("Error downloading media", error);
            });
    };

    return (
        <div className="grid grid-cols-1 px-4 pt-2 xl:grid-cols-3 xl:gap-4 w-full relative">
            <div className="py-6 sm:ml-64 h-fit w-fit">
                <div className="border-gray-200 mt-14">
                    <div className="flex flex-col px-4">
                        {/* <Switch className="mb-4" isSelected={isVertical} onValueChange={setIsVertical}>
                        Vertical
                    </Switch> */}
                        <div className="flex w-full flex-col">
                            {/* <Tabs color="primary" aria-label="Options" isVertical={isVertical}> */}
                            <Tabs
                                aria-label="Options"
                                color="primary"
                                variant="solid"
                                // isVertical={isVertical}
                                selectedKey={selectedArtisanTab}
                                onSelectionChange={(tab) => handleSelectedTab(tab)}
                            >
                                <Tab
                                    key="script"
                                    title={
                                        <div className="flex items-center space-x-2">
                                            <i className="fa-solid fa-scroll text-lg" />
                                            <span>Script</span>
                                        </div>
                                    }
                                >
                                    <Card className="dark:bg-gray-800">
                                        <CardBody>
                                            <ScriptTab
                                                myEmail={email}
                                                artisanSession={artisanSession}
                                                isFetchingArtisanSessions={isFetchingArtisanSessions}
                                                pricingData={appConfigData}
                                                script={script}
                                                setScript={setScript}
                                                setSessionID={setSessionID}
                                                incomingScript={incomingScript}
                                                setIncomingScript={setIncomingScript}
                                                setRefetchArtisanSessions={setRefetchArtisanSessions}
                                                subscriptionData={subscriptionData}
                                                isOldUser={isOldUser}
                                            />
                                        </CardBody>
                                    </Card>
                                </Tab>
                                <Tab
                                    key="voice"
                                    title={
                                        <div className="flex items-center space-x-2">
                                            <i className="fa-solid fa-microphone-lines text-lg" />
                                            <span>Voice</span>
                                        </div>
                                    }
                                >
                                    <Card className="dark:bg-gray-800">
                                        <CardBody>
                                            <VoiceTab
                                                selectedModel={selectedModel}
                                                setSelectedModel={setSelectedModel}
                                            />
                                        </CardBody>
                                    </Card>
                                </Tab>
                                <Tab
                                    key="font"
                                    title={
                                        <div className="flex items-center space-x-2">
                                            <i className="fa-solid fa-font text-lg" />
                                            <span>Fonts</span>
                                        </div>
                                    }
                                >
                                    <Card className="dark:bg-gray-800">
                                        <CardBody>
                                            <FontsTab
                                                selectedFont={selectedFont}
                                                setSelectedFont={setSelectedFont}
                                                setSelectedFontColor={setSelectedFontColor}
                                                selectedFontColor={selectedFontColor}
                                                setSelectedCaptionPosition={setSelectedCaptionPosition}
                                                selectedCaptionPosition={selectedCaptionPosition}
                                            />
                                        </CardBody>
                                    </Card>
                                </Tab>
                                <Tab
                                    key="craft"
                                    title={
                                        <div className="flex items-center space-x-2">
                                            <i className="fa-solid fa-clapperboard text-lg" />
                                            <span>Craft</span>
                                        </div>
                                    }
                                >
                                    <Card className={`dark:bg-gray-800 w-auto`}>
                                        <CardBody>
                                            <CraftTab
                                                selectedModel={selectedModel}
                                                selectedFont={selectedFont}
                                                selectedDimension={selectedDimension}
                                                setSelectedDimension={setSelectedDimension}
                                                incomingScript={incomingScript}
                                                artisanSession={artisanSession}
                                                sessionID={sessionID}
                                                appProcessStatus={appProcessStatus}
                                                setRefetchArtisanSessions={setRefetchArtisanSessions}
                                                videoSource={videoSource}
                                                noCaptionVideo={noCaptionVideo}
                                                selectedFontColor={selectedFontColor}
                                                selectedCaptionPosition={selectedCaptionPosition}
                                                onOpenEM={onOpenEM}
                                                pricingData={appConfigData}
                                                processedData={processedData}
                                                setProcessedData={setProcessedData}
                                                subscriptionData={subscriptionData}
                                                isOldUser={isOldUser}
                                                setVideoData={setVideoData}
                                                onOpenMV={onOpenMV}
                                            />
                                        </CardBody>
                                    </Card>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>

                    {/* video media play */}
                    <MediaView
                        isOpen={isOpenMV}
                        onClose={onCloseMV}
                        isDownloading={isDownloading}
                        handleDownloadMediaFile={handleDownloadMediaFile}
                        modalData={videoData}
                    />

                    {/* editor modal */}
                    <Edit
                        isOpen={isOpenEM}
                        onClose={onCloseEM}
                        artisanSession={artisanSession}
                        setArtisanSession={setArtisanSession}
                        setRefetchArtisanSessions={setRefetchArtisanSessions}
                        selectedFont={selectedFont}
                        selectedFontColor={selectedFontColor}
                        selectedCaptionPosition={selectedCaptionPosition}
                        pricingData={appConfigData}
                        processedData={processedData}
                        setProcessedData={setProcessedData}
                        subscriptionData={subscriptionData}
                        isOldUser={isOldUser}
                    />

                </div>
            </div>
        </div>
    );
};

export default Idea2Video;