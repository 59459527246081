import toast from "react-hot-toast";
import Swal from "sweetalert2";

export const ToastEffect = (kind, message) => {

    let color = "";

    if (localStorage.theme === "dark") {
        color = "#1F2937";
    }

    Swal.fire({
        background: color,
        icon: kind === "error" ? "error" : "success",
        // title: `<strong class="text-gray-700 dark:text-gray-200">${getDisplayTitle(kind)}</strong>`,
        html: `<p class="text-gray-700 dark:text-gray-300">${message}</p>`,
        // footer: '<a href="#">Why do I have this issue?</a>',
        showConfirmButton: true,
    });
};

export const SmallToastEffectBottomRight = (kind, message, toastHandler = toast) => {
    if (kind === "error") {
        toastHandler.error(message, { style: { background: '#3a62e4', color: '#fff' } });
    } else {
        toastHandler.success(message, { style: { background: '#3a62e4', color: '#fff' } });
    }
};