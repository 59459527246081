// libraries
import React, { useEffect, useRef, useState } from "react";

// custom components
import Spectrum from "./Spectrum";

const AudioUI = (props) => {

    // props
    const {
        data,
        idx,
    } = props;

    // react useRef
    const audioRef = useRef(null);

    // react useState
    const [isPlaying, setIsPlaying] = useState(false);

    // reload if audio source changes
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.load();
            setIsPlaying(false);
        }
    }, [data]);

    // toggle play pause
    const handlePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <div className="flex justify-between w-full gap-1">
            <div className="flex text-sm font-semibold items-center justify-center h-[4rem] w-[4rem] rounded-full bg-indigo-500 flex-shrink-0">
                {data?.model}
            </div>
            <div className="relative text-sm dark:bg-gray-700 py-2 px-4 shadow rounded-xl">
                <div className="flex flex-row items-center">
                    <button
                        onClick={handlePlayPause}
                        className="flex items-center justify-center bg-indigo-600 hover:bg-indigo-800 rounded-full h-10 w-10"
                    >
                        <span className="text-xl">
                            <i className={`fa-solid ${isPlaying ? 'fa-pause' : 'fa-play'}`} />
                        </span>
                    </button>
                    {/* spectrum effect */}
                    <Spectrum uniqueKey={idx} />
                </div>
            </div>
            {/* audio source */}
            <audio
                ref={audioRef}
                src={data?.voice}
            />
        </div>
    );
};

export default AudioUI;