// libraries
import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Card, CardBody, CardFooter, Image, Textarea, Input, Tooltip, Spinner, CardHeader, Select, SelectItem, Switch, cn } from "@nextui-org/react";

// custom components
import consoleLog from "../../../../../../functions/consoleLog";
import { fetchVideosFromPexel } from "../../../../../../API/server";
import Sequence from "./Sequence";
import HeadMenu from "./HeadMenu";
import { videoOrientation } from "../../Utils/data";
import Player from "./Player";
import Media from "./Media";
import { handleHighlightCaption } from "../../functions/artisan";
// import Player from "./Player";
// import Media from "./Media";
// import { videoOrientation } from "../../utils/data";
// import HeadMenu from "./HeadMenu";

const Edit = (props) => {

    // props
    const {
        isOpen,
        onClose,
        artisanSession,
        setArtisanSession,
        setRefetchArtisanSessions,
        selectedFont,
        selectedFontColor,
        selectedCaptionPosition,
        pricingData,
        processedData,
        setProcessedData,
        subscriptionData,
        isOldUser,
    } = props;

    // default avatar
    const defaultAvatar = "assets/images/default_avatar.png";

    // react useRef
    const scrollContainerRef = useRef(null);

    // react useState
    const [selectedSequence, setSelectedSequence] = useState("");
    const [searchKeywords, setSearchKeywords] = useState("");
    const [searchedVideos, setSearchedVideos] = useState(null);
    const [playVideo, setPlayVideo] = useState(false);
    const [videoData, setVideoData] = useState(null);
    const [selectedOrientation, setSelectedOrientation] = useState("portrait");
    const [isOwnMedia, setIsOwnMedia] = useState(false);
    // const [captionData, setCaptionData] = useState(artisanSession?.video?.videoCaption);

    // console log activity
    // consoleLog("searchedVideos", searchedVideos);
    // consoleLog("artisanSession", artisanSession);

    // handle button scroll left
    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: -350, behavior: 'smooth' });
        }
    };

    // handle button scroll right
    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: 350, behavior: 'smooth' });
        }
    };

    // scroll horizontal function
    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;

        const handleWheel = (event) => {
            if (scrollContainer) {
                event.preventDefault();
                scrollContainer.scrollLeft += event.deltaY;
            }
        };

        if (scrollContainer) {
            scrollContainer.addEventListener('wheel', handleWheel);
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('wheel', handleWheel);
            }
        };
    }, []);

    // handles edited script
    const handleClickedSequence = (data) => {

        // set selected sequence
        setSelectedSequence(data);

        // set updated caption array
        // const updatedCaptionArray = handleHighlightCaption(data?.keyword, captionData);
        // setCaptionData(updatedCaptionArray);

        // console log activity
        consoleLog("selectedSequence", data);

    };

    // call again function if orientation changes
    useEffect(() => {

        // call function to execute search content
        searchContent();

    }, [selectedOrientation, searchKeywords]);

    // function call execute search
    const searchContent = async () => {

        // conditional checking
        if (!searchKeywords) { return; }
        if (!selectedOrientation) { return; }

        // console log activity
        // consoleLog("searchKeywords", searchKeywords);
        // consoleLog("selectedOrientation", selectedOrientation);

        // prepare required data
        const requiredData = {
            keywords: searchKeywords,
            orientation: selectedOrientation,
            perPage: 50
        };

        // call the API to fetch the keyword
        await fetchVideosFromPexel(requiredData)
            .then((response) => {

                // set fetched Videos
                setSearchedVideos(response?.data);

                // console log activity
                // consoleLog("fetchVideosFromPexel", response?.data);
            });

    };

    // handle search keyword
    const handleSearchKeyword = (e) => {

        // extracted search key word
        const searchKeyWord = e.target.value;

        // check if keyword is empty
        if (searchKeyWord.trim().length <= 0) { return; }

        // set search keyword
        setSearchKeywords(searchKeyWord);

        // console log activity
        // consoleLog("searchKeyWord", searchKeyWord);
    };

    // handle play video
    const handlePlayVideo = (data) => {

        // trigger play video popup
        setPlayVideo(true);

        // set video data
        setVideoData(data);

        // console log activity
        consoleLog("videoData", data);

    };

    // handle selected orientation
    const handleSelectedOrientation = (e) => {

        // selected orientation
        const orientation = e.target.value;

        // set selected orientation value
        setSelectedOrientation(orientation);

    };

    return (
        <Modal
            size="5xl"
            isOpen={isOpen}
            onClose={onClose}
            // scrollBehavior={searchedVideos && "outside"}
            scrollBehavior="outside"
            className="dark:bg-gray-800"
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">Edit Content</ModalHeader>
                        <ModalBody>
                            <div className="relative">

                                {/* video sequence */}
                                <Sequence
                                    scrollLeft={scrollLeft}
                                    scrollRight={scrollRight}
                                    scrollContainerRef={scrollContainerRef}
                                    artisanSession={artisanSession}
                                    selectedSequence={selectedSequence}
                                    handleClickedSequence={handleClickedSequence}
                                    setArtisanSession={setArtisanSession}
                                    // captionData={captionData}
                                />

                            </div>
                        </ModalBody>
                        <ModalFooter className="justify-between items-center">

                            {/* editor head menu */}
                            <HeadMenu
                                handleSearchKeyword={handleSearchKeyword}
                                selectedOrientation={selectedOrientation}
                                handleSelectedOrientation={handleSelectedOrientation}
                                videoOrientation={videoOrientation}
                                artisanSession={artisanSession}
                                selectedFont={selectedFont}
                                selectedFontColor={selectedFontColor}
                                selectedCaptionPosition={selectedCaptionPosition}
                                pricingData={pricingData}
                                setProcessedData={setProcessedData}
                                subscriptionData={subscriptionData}
                                isOldUser={isOldUser}
                            />

                        </ModalFooter>

                        {/* video play */}
                        {playVideo && (
                            <Player
                                videoData={videoData}
                                defaultAvatar={defaultAvatar}
                                setPlayVideo={setPlayVideo}
                            />
                        )}

                        {/* display searched media */}
                        <Media
                            searchedVideos={searchedVideos}
                            handlePlayVideo={handlePlayVideo}
                            artisanSession={artisanSession}
                            selectedSequence={selectedSequence}
                            setArtisanSession={setArtisanSession}
                        />

                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default Edit;