import { Tooltip } from "react-tooltip";

const ToolTipDisplay = (props) => {
    return (
        <div
            data-tooltip-id={`tooltip-display-${props?.id}`}
            data-tooltip-html={props?.label}
            // data-tooltip-place="right-start"
            data-tooltip-place={props?.position}
            // className="z-50"
            className={props?.zIndex}
        >
            <Tooltip id={`tooltip-display-${props?.id}`} />
            {/* <i className="fa-regular fa-circle-question" /> */}
            <i className={`${props.icon} text-yellow-200 cursor-help`} />
        </div>
    );
};

export default ToolTipDisplay;
